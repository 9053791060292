export class Patterns {
  //Issues caused because of improper password validation, fixed -  
  static get pwdpattern() {
    return '^(?=.*[0-9])(?=.*[@#$%^&])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9@#$%^&]{8,16}$';
  }
  //End
  static get emailPattern() {
    return '^[a-zA-Z0-9]+([\\._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([\\.-]?[a-zA-Z0-9]+)*\\.[a-zA-Z]{2,20}$';
  }

  static get numberPattern() {
    return '[0-9]*';
  }

  static get phoneNumberPattern() {
    return '^[0-9]{3}\-[0-9]{3}\-[0-9]{4}$';
  }

  static get zipCodePattern() {
    return '[0-9]{5}';
  }
}

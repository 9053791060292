<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <div class="header-banner">
      <div *ngIf="!isLoggedIn">
        <div class="container d-flex align-items-start pt-4">
          <div class="home-logo">
            <img src="assets/images/060118_HillsToHomeLogo.png" alt="Hills To Home Logo" (click)="gotoLogin()">
          </div>
          <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
            <span class="w700">Sign In</span>
            <i class="fa fa-user ps-2 fa-sm"></i>
          </button>
          <!-- <button class="visit-btn mt-3" (click)="openUrl()">
            <span class="w700">VISIT HILLSPET.COM</span>
            <i class="fa fa-external-link-square-alt ps-2"></i>
          </button> -->
        </div>
      </div>
    <div class="bg-white p-3">
      <div class="container pt-2">
        <div class="d-flex h2 mb-5 support-main-title text-center">
            Hello! What can we help you with?  
        </div>
        <div class="d-flex align-items-center mb-2">
          <h4 class="fw-normal m-0">Featured Topics:</h4>
        </div>
        <div class="d-flex flex-column whats-new p-3 mb-2 mt-3" *ngIf="isLoggedIn">
          <div class="h5">What's New</div>
          <!-- WhatsNew Support -   -->
          <a class="support-text" (click)="goToSelectedFaq('whats-new')">Learn what's new in Hills to Home.</a>
          <!-- End -->
        </div>
      </div>
      <div class="container pt-2">
        <div class="d-flex flex-column flex-wrap justify-content-between align-items-stretch">
          <div class="d-flex card p-3 support-item" *ngFor="let support of supportList" (click)="goToSelectedFaq(support.code)">
            <div class="h5">{{support.name}}</div>
            <a class="support-text" href="javascript:void(0)" *ngFor="let item of support.subList">{{item.name}}</a>
          </div>
        </div>
      </div>
      <div class="container mb-5">
        <div class="d-flex h2 pb-2">
            Still Need Help?  
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-sty btn-primary w-50" routerLink="/contact-us">
            Contact us
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="header-banner">
    <div *ngIf="!isLoggedIn">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo" (click)="gotoLogin()">
          <img src="assets/images/060118_HillsToHomeLogo.png" alt="Hills To Home Logo">
        </div>
          <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
            <span class="w700">Sign in</span>
            <i class="fa fa-user ps-2 fa-sm"></i>
          </button>
        </div>
      </div>
    <div class="bg-white">
      <div class="container pt-5">
        <div class="d-flex h2 pb-5">
            Hello! What can we help you with?  
        </div>
        <div class="d-flex align-items-center mb-2">
          <h4 class="fw-normal m-0">Featured Topics:</h4>
        </div>
        <div class="d-flex flex-column whats-new p-3 mb-2 mt-3" *ngIf="isLoggedIn">
          <div class="h5">What's New</div>
          <!-- WhatsNew Support -   -->
          <a class="support-text" (click)="goToSelectedFaq('whats-new')">Learn what's new in Hills to Home.</a>
          <!-- End -->
        </div>
      </div>
      <div class="container pt-2">
        <div class="d-flex flex-wrap justify-content-between align-items-stretch">
          <div class="d-flex card p-3 support-item" *ngFor="let support of supportList" (click)="goToSelectedFaq(support.code)">
            <div class="h5">{{support.name}}</div>
            <a class="support-text" href="javascript:void(0)" *ngFor="let item of support.subList">{{item.name}}</a>
          </div>
        </div>
      </div>
      <div class="container pb-5">
        <div class="d-flex h2 pb-2">
            Still Need Help?  
        </div>
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-sty btn-primary btn-sm" routerLink="/contact-us">
            Contact us
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pet } from '../../../api';
import { PetTypes } from '../../../shared/constants/pet';
import { checkWhiteSpaceValidator } from './custom-edit-pet-validator';

@Component({
  selector: 'app-edit-pet',
  templateUrl: './edit-pet.component.html',
  styleUrls: ['./edit-pet.component.scss']
})
export class EditPetComponent implements OnInit, OnChanges {

  petForm: UntypedFormGroup;

  PetTypes = PetTypes;

  @Input() isMobile: boolean | false;

  @Input() pending: boolean | false;

  @Input() isHome = false;

  @Input() pet: Pet | null;

  @Output() submitted = new EventEmitter<Pet>();

  @Output() succeed = new EventEmitter<any>();

  @Output() navigatedBack = new EventEmitter();

  @ViewChild('successModal', { static: true })
  successModal: any;

  constructor(private fb: UntypedFormBuilder,
    private modalService: NgbModal) {
    this.createForm();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const petChange: SimpleChange = changes['pet'];
    if (petChange) {
      this.resetForm();
    }
    const pendingChange: SimpleChange = changes['pending'];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.petForm.disable();
      } else {
        this.petForm.enable();
        this.success();
      }
    }
  }
  //   start
  createForm() {
    this.petForm = this.fb.group({
      name: ['', [Validators.required, checkWhiteSpaceValidator,Validators.maxLength(255)]],
      petType: ['', Validators.required]
    });
  }
  //   end
  resetForm() {
    if (this.pet) {
      this.petForm.reset({
        name: this.pet.petName,
        petType: this.pet.petType,
      });
    }
  }

  save() {
    if (this.petForm.valid) {
      const model = this.petForm.value;
      const pet: Pet = {
        ...this.pet,
        petName: model.name,
        petType: model.petType
      };
      this.submitted.emit(pet);
    }
  }

  success() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      this.modalService.open(this.successModal).result.then(
        () => {
          this.succeed.emit();
        },
        () => {
          this.succeed.emit();
        }
      );
    });
  }

  goBack() {
    this.navigatedBack.emit();
    // this.router.navigate([this.back]);
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, tap, take, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  AuthActionTypes,
  Login,
  LoginFailure, LoginRedirect,
  LoginSuccess,
  Refresh,
  Register, RegisterFailure, RegisterSuccess,
  ForgotPassword, SetLogin, ResetPassword, ResetPasswordFailure, ForgotPasswordSuccess, ForgotPasswordFailure, CheckTokenExpiry,
  RegisterPetParent, RegisterPetParentFailure, RegisterPetParentSuccess, Logout, LogoutSuccess, LogoutFailure, GetCSRFToken, GetCSRFTokenSuccess, GetCSRFTokenFailure, GetSiteVerifyCaptcha, GetSiteVerifyCaptchaSuccess, TriggerSprigEvent
} from '../actions/auth.actions';
import * as fromAuth from '../reducers';
import { ReportError } from '../../core/actions/error.actions';
import { Noop } from '../../core/actions/core.actions';
import { Authenticate } from '../models/authenticate';
import { Registration } from '../models/registration';
import { environment } from '../../../environments/environment';
import { PetParentDetail } from '../models/pet-parent-detail';
import {
  Session,
  Configuration,
  LoginError,
  AuthService, ServerError, RegisterPetParentError
} from '../../api';
import { LogoutError } from 'src/app/api/model/logoutError';
import { UpdatePasswordSuccess } from 'src/app/profile/actions/profile.actions';

@Injectable()
export class AuthEffects {

  getAuthenticate = ((): Observable<Authenticate> =>
    this.store.pipe(
      select(fromAuth.getAuthenticate),
      take(1)
    ));

  
  registerPetParent$ = createEffect(() => this.actions$
    .pipe(
      ofType<RegisterPetParent>(AuthActionTypes.RegisterPetParent),
      map((action: RegisterPetParent) => {
        return action.petParentRegistration;
      }),
      exhaustMap((auth: PetParentDetail) =>
        this.authService
          .registerPetParent(auth)
          .pipe(
            map(() => new RegisterPetParentSuccess()),
            catchError((payload: { error: { errors: Array<RegisterPetParentError> } }) => {
              let errorMsg = '';
              if (payload && payload.error && payload.error.errors && payload.error.errors.length) {
                if (payload.error.errors[0].type == 'NullPointerError')
                  return of(new RegisterPetParentFailure("Server error. Please try later!"));

                if (payload.error.errors[0].message.includes("User already exist with email")) {
                  errorMsg = "Username already registered";
                }
                else if (payload.error.errors[0].message.includes("Self Registration is disabled")) {
                  errorMsg = "Pet parent self-registration has been disabled for this business. Please contact the provider of the link so they can register you. If you have any questions, reach out to our Hill's to Home support team.";
                } else {
                  errorMsg = payload.error.errors[0].message;
                }
              }
              if (errorMsg) {
                return of(new RegisterPetParentFailure(errorMsg));
              }
              return of(new ReportError(payload));
            })
          )
      )
    ));

  
  registerPetParentSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<RegisterPetParentSuccess>(AuthActionTypes.RegisterPetParentSuccess),
      tap(() => {
        this.router.navigate(['/register-pet-success']);
      })
    ), { dispatch: false });

  login$ = createEffect(() => this.actions$
    .pipe(
      ofType<Login>(AuthActionTypes.Login),
      map((action: Login) => action.authenticate),
      exhaustMap((auth: Authenticate) =>
        this.authService
          .login(
            environment.grant_type,
            environment.client_id,
            environment.client_secret,
            environment.grant_type === 'password' ? auth.username : undefined,
            environment.grant_type === 'password' ? auth.password : undefined)
          .pipe(
            switchMap((session: Session) => {
              // Set the configuration access token from the api
              this.configuration.apiKeys['access_token'] = session['access_token'];

              // Now make the getuserpk call using the username
              return this.authService.getuserpk(auth.username).pipe(
                map(userPkResponse => {
                  const pk = userPkResponse.pk;

                  // Store the pk in sessionStorage
                  sessionStorage.setItem('pk', pk);

                  // Create an updated session object that includes the pk
                  const updatedSession = {
                    ...session,
                    pk: pk, // Include the pk
                    username: auth.username // Include the username
                  };

                  // Dispatch the LoginSuccess action with the updated session
                  return new LoginSuccess(updatedSession);
                }),
                catchError(error => {
                  // If getuserpk fails, dispatch LoginFailure
                  return of(new LoginFailure(error));
                })
              );
            }),
            catchError(error => {
              // If login fails, dispatch LoginFailure
              return of(new LoginFailure(error));
            })
          )
      )
    )
  );
  
  loginFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType<LoginFailure>(AuthActionTypes.LoginFailure),
      map((action: LoginFailure) => action.payload),
      map((payload: { error: LoginError }) => {
        if (payload.error.error !== 'invalid_grant') {
          return new ReportError(payload);
        }
        return new Noop();
      })
    ));

  
  loginRedirect$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.CheckTokenExpiry),
      tap(() => {
        this.router.navigate(['/login']);
      })
    ), { dispatch: false });

  
  loggedRedirect$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActionTypes.LoggedRedirect),
      tap(() => {
        this.router.navigate(['/']);
      })
    ), { dispatch: false });


  
  logout$ = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActionTypes.Logout),
      exhaustMap(() =>
        this.getAuthenticate()
          .pipe(
            exhaustMap((authenticate: Authenticate) =>
              this.authService.userLogout(authenticate.username)
                .pipe(
                  map(() => new LogoutSuccess()),
                  catchError((payload: { errors: LogoutError }) => {
                    return of(new LogoutFailure(payload));
                  })
                )
            )
          )
      )
    ));

  
  logoutSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<LogoutSuccess>(AuthActionTypes.LogoutSuccess),
      tap(() => {
        this.router.navigate(['/login']);
        this.configuration.apiKeys['access_token'] = '';
      })
    ), { dispatch: false });

  
  logoutFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType<LogoutFailure>(AuthActionTypes.LogoutFailure),
      tap(() => {
        this.router.navigate(['/login']);
        this.configuration.apiKeys['access_token'] = '';
      })
    ), { dispatch: false });

  
  getCSRFToken$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCSRFToken>(AuthActionTypes.GetCSRFToken),
      switchMap((action: GetCSRFToken) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
            this.authService.getCSRFToken()
            .pipe(
              map((session: Session) => new GetCSRFTokenSuccess(
                {
                  csrfToken: session['token']
                })),
              catchError(payload => of(new GetCSRFTokenFailure(payload)))
            )
            )
          )
      )
    ));

  
  getCSRFTokenSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCSRFTokenSuccess>(AuthActionTypes.GetCSRFTokenSuccess),
      map((action: GetCSRFTokenSuccess) => action.session),
      tap((session: Session) => {
        // Set the configuration csrf token from the api
        this.configuration.apiKeys['csrf_token'] = session.csrfToken;
      })
    ), { dispatch: false });

  
  getCSRFTokenFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetCSRFTokenFailure>(AuthActionTypes.GetCSRFTokenFailure),
      tap(() => {
        //this.router.navigate(['/login']);
        this.configuration.apiKeys['csrf_token'] = '';
      })
    ), { dispatch: false });
    

  
  refresh$ = createEffect(() => this.actions$
    .pipe(
      ofType<Refresh>(AuthActionTypes.Refresh),
      map((action: Refresh) => action.session),
      exhaustMap((session: Session) =>
        this.authService
          .login(
            'refresh_token',
            environment.client_id,
            environment.client_secret,
            undefined,
            undefined,
            session.refreshToken)
          .pipe(
            map((newSession: Session) => new LoginSuccess(
              {
                accessToken: newSession['access_token'],
                tokenType: newSession['token_type'],
                refreshToken: newSession['refresn_token'],
                expiresIn: newSession['expires_in'],
                scope: newSession['scope']
              })),
            catchError(error => of(new LoginFailure(error)))
          )
      )
    ));

  
  register$ = createEffect(() => this.actions$
    .pipe(
      ofType<Register>(AuthActionTypes.Register),
      map((action: Register) => action.registration),
      exhaustMap((registration: Registration) =>
        this.authService
          .setPassword(
            registration.token,
            registration.newPassword
          )
          .pipe(
            map(() => new RegisterSuccess()),
            catchError((payload: { error: { errors: Array<ServerError> } }) => {
              let errorMsg = '';
              if (payload && payload.error && payload.error.errors && payload.error.errors.length) {
                switch (payload.error.errors[0].type) {
                  case 'UnknownIdentifierError':
                    errorMsg = 'Specified email is not registered';
                    break;
                  case 'TokenInvalidatedError':
                    errorMsg = 'link-expired';
                    break;
                  default:
                    errorMsg = 'Username already registered';
                    break;
                }
              }
              if (errorMsg) {
                return of(new RegisterFailure(errorMsg));
              }
              return of(new ReportError(payload));
            })
          )
      )
    ));

  
  registerSuccess$ = createEffect(() => this.actions$
    .pipe(
      ofType<RegisterSuccess>(AuthActionTypes.RegisterSuccess),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            map((auth: Authenticate) => new Login(auth)),
          )
      )
    ));

  // @Effect({ dispatch: false })
  // registerFailure$ = this.actions$
  //   .pipe(
  //     ofType<RegisterFailure>(AuthActionTypes.RegisterFailure),
  //     map((action: RegisterFailure) => action.registerError),
  //     map((registerError: { error: any }) => {
  //       // Todo: verify errors for user already created
  //       debugger;
  //       if (registerError) {
  //         new RegisterFailure("Invalid Token");
  //       }
  //       return new Noop();
  //     })
  //   );

  
  forgotPassword$ = createEffect(() => this.actions$
    .pipe(
      ofType<ForgotPassword>(AuthActionTypes.ForgotPassword),
      exhaustMap((action: ForgotPassword) =>
        this.authService
          .resetPassword(action.email)
          .pipe(
            map(() => new ForgotPasswordSuccess()),
            catchError((payload: { error: { errors: Array<ServerError> } }) => {
              let errorMsg = '';
              if (payload && payload.error && payload.error.errors && payload.error.errors.length) {
                switch (payload.error.errors[0].type) {
                  case 'UnknownIdentifierError':
                    errorMsg = 'Specified email is not registered';
                    break;
                }
              }
              if (errorMsg) {
                return of(new ForgotPasswordFailure(errorMsg));
              }
              return of(new ReportError(payload));
            })
          )
      )
    ));

  
  resetPassword$ = createEffect(() => this.actions$
    .pipe(
      ofType<ResetPassword>(AuthActionTypes.ResetPassword),
      exhaustMap((action: ResetPassword) =>
        this.authService
          .setPassword(
            action.resetPasswordChange.token,
            action.resetPasswordChange.newPassword
          )
          .pipe(
            map(() => new UpdatePasswordSuccess()),
            catchError((payload: { error: { errors: Array<ServerError> } }) => {
              let errorMsg = '';
              if (payload && payload.error && payload.error.errors && payload.error.errors.length) {
                switch (payload.error.errors[0].type) {
                  case 'ValidationError':
                    errorMsg = (!!payload.error.errors[0].message) ? payload.error.errors[0].message : 'Password has already been used before.';
                    break;
                }
              }
              if (errorMsg) {
                return of(new ResetPasswordFailure(errorMsg));
              }
              return of(new ReportError(payload));
            })
          )
      )
    ));

  
  setLogin$ = createEffect(() => this.actions$
    .pipe(
      ofType<SetLogin>(AuthActionTypes.SetLogin),
      switchMap(() =>
        this.getAuthenticate()
          .pipe(
            map((auth: Authenticate) => new Login(auth)),
          )
      )
    ));

    // Invisible V3 captcha for login -  
    
    GetSiteVerifyCaptcha$ = createEffect(() => this.actions$
      .pipe(
        ofType<GetSiteVerifyCaptcha>(AuthActionTypes.GetSiteVerifyCaptcha),
        switchMap((action: GetSiteVerifyCaptcha) =>{
            return this.authService.GetSiteVerifyCaptcha(action.token)
            .pipe(
              map((response: any)=>{
                let payload = JSON.parse(response);
                if(payload.success){
                  return new GetSiteVerifyCaptchaSuccess();
                } 
                else
                {
                  throw new Error(JSON.stringify(payload));
                }
              }),
              catchError((error: any) => {
                return of(new Noop());
              })
            );
          }

        )
     ));
    // End

    triggerSprigEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TriggerSprigEvent>(AuthActionTypes.TriggerSprigEvent),
      map((action: TriggerSprigEvent) => action.payload),
      tap(( payload ) => {
        console.log(payload)
        if (typeof (window as any).Sprig === 'function') {
          (window as any).Sprig("track", payload.eventIdentifier);
        } else {
          console.log('Sprig not loaded, but would have triggered "Trigger_Survey"');
        }

      })
    ),
    { dispatch: false }
  );
  constructor(private store: Store<fromAuth.State>,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private configuration: Configuration) {
  }
}

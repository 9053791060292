<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg">
    <div class="product-detail-head custom-font pt-4 px-3">
      <!-- <div class="mb-3">
        <a  class="back-arrow fa fa-arrow-left"></a>
      </div> -->

      <a class="back-arrow" href="javascript:void(0)" (click)="goBack()" aria-label="Back Button">
        <i class="fa fa-arrow-left"></i>
      </a>
      <!-- <span class="mt-n1">Hill's Prescription</span> -->
      <h4 class="product-detail-title w700 my-1">
        {{product?.name}}
      </h4>
      <!-- <span>2.9oz, 24-pack</span> -->
    </div>

    <!-- <div class="d-flex rate-like align-items-center pb-4 custom-font mt-3 px-4">
      <span class="stars pe-1">
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
        <i class="fa fa-star" aria-hidden="true"></i>
      </span>
      <small>(540)</small>
      <span class="like text-right d-flex align-items-center justify-content-end">
        <i class="fa fa-heart"></i>
      </span>
    </div> -->

    <div class="d-flex flex-column align-items-center bg-white mx-3 my-3 py-3 border-radius">
      <div id="imgContainer" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'inStock'">
        <img src="{{product.images | appImg:'lrg'}}" (click)="open()" class="app-cart-img" alt="{{product.name}}"/>
      </div>
      <div class="app-cart-img text-center" *ngIf="!product?.images">
        <i class="fas fa-image fa-10x"></i>
      </div>
      <!--Out of Stock Product Start-->
      <div class="productImageHolder" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'outOfStock'">
        <div id="imgContainer">
          <img src="{{product.images | appImg:'lrg'}}" class="app-cart-img" alt="{{product.name}}"/>
        </div>
          <span class="small out-of-stock px-1 py-1 text-center">
            Temporarily Unavailable
          </span>         
      </div>
      <!--Out of Stock Product End-->
      <div class="detail-sku mt-3">
        SKU {{product?.code}}
      </div>
    </div>

    <!-- <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="!changeProduct ? addToCart(): selectProduct()">
      <div class="d-flex mt-5">
        <div class="flex-grow-1">
          <h5 class="fw-normal">
            Sizes
          </h5>
          <div class="d-flex flex-wrap mt-3">
            <a [routerLink]="[!changeProduct ? '/np-product': '/np-product-change', clinicId, option.code]" [replaceUrl]="true"
                *ngFor="let option of product?.baseOptions[0]?.options;"
                class="border me-2 p-2"
                [class.border-primary]="option.code === product.code">
              {{option.size}}
            </a>
          </div>
        </div>
        <div *ngIf="!changeProduct">
          <h5 class="fw-normal">
            Quantity
          </h5>
          <div class="form-group mt-3">
            <select id="quantity"
                    name="quantity"
                    class="form-control custom-select"
                    formControlName="quantity">
              <option *ngFor="let quantity of quantities" [value]="quantity">{{quantity}}</option>
            </select>
          </div>
        </div>
      </div>
      <hr class="mt-3"/>
      <div class="out-of-stock"
            *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'">
        <h5 class="fw-bold text-white">
          Product temporarily unavailable.
        </h5>
        <h5 class="fw-normal text-white">
          Please choose another product from the product line or contact customer service.
        </h5>
      </div>

      <div class="d-flex mt-4 align-items-center">
        <div class="h4 m-0 flex-grow-1">
          <span class="text-muted fw-normal">USD </span>
          <span class="text-success">{{product?.baseOptions[0]?.selected.priceData.formattedValue}}</span>
        </div>
        <div>
          <button class="btn btn-primary btn-lg font-weight-light"
                  *ngIf="product?.stock?.stockLevelStatus === 'inStock' && !changeProduct">
            Add to Cart
          </button>
          <button class="btn btn-primary btn-lg font-weight-light"
                  *ngIf="changeProduct">
            Select
          </button>
        </div>
      </div>
    </form> -->

    <div class="px-3 mt-4">
      <div class="detail-price-card custom-font py-4 px-3 edit-form">
        <!-- <div class="d-flex align-items-center">
          <div class="h4 m-0 flex-grow-1">
            <span class="text-muted fw-normal">USD </span>
            <span class="text-success">{{product?.baseOptions[0]?.selected.priceData.formattedValue}}</span>
          </div>
        </div> -->
        <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="!changeProduct ? addToCart(): selectProduct()">
          <div class="row">
            <div class="col-6 pe-2">
              <label for="size">Size</label>
              <div class="form-group d-flex align-items-center">
                <select class="form-control" id="size" (change)="fetchProductDetailOnSizeChange()"
                  formControlName="size" >
                  <option *ngFor="let option of product?.baseOptions[0]?.options;" [ngValue]="option.code" [disabled]="option?.stock?.stockLevelStatus === 'outOfStock' ? true : null">
                    {{option.size}}</option>
                </select>
              </div>
            </div>
            <div class="col-6 ps-2" *ngIf="!changeProduct">
              <label for="quantity">Quantity</label>
              <div class="form-group" *ngIf="product?.stock?.stockLevelStatus === 'inStock'">
                <div class="field-quantity d-flex align-items-center justify-content-center">
                  <span class="cursor-pointer" (click)="onQuantityRemoved()">
                    <i class="minus">-</i>
                  </span>
                  <span class="flex-fill"><input type="text" readonly id="quantity" formControlName="quantity" /></span>
                  <span class="cursor-pointer" (click)="onQuantityAdded()">
                    <i>+</i>
                  </span>
                </div>
              </div>
              <div class="form-group out-of-stock-quantity" *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'">
                <div class="field-quantity d-flex align-items-center justify-content-center">
                  <span class="cursor-pointer">
                    <i class="minus">-</i>
                  </span>
                  <span class="flex-fill"><input type="text" id="quantity" readonly formControlName="quantity" /></span>
                  <span class="cursor-pointer">
                    <i>+</i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6 pe-2" *ngIf="!changeProduct">
              <label [for]="'frequency'+product?.code">Frequency</label>
              <div class="form-group d-flex align-items-center">
                <select class="form-control" [id]="'frequency'+product?.code" formControlName="frequency">
                  <option
                    *ngFor="let frequency of product?.subscriptionUnit?.availableDeliveryFrequencies | toDeliveryFrequency"
                    [ngValue]="frequency.code">
                    {{frequency.name}}
                  </option>

                </select>
              </div>
            </div>
            <!-- <div class="col-6 ps-2" *ngIf="(shopForm.get('frequency').value !== DeliveryFrequencies?.OneTime) && !changeProduct">
              <label for="duration">Length</label>
              <div class="form-group d-flex align-items-center">
                <select class="form-control" id="duration" formControlName="duration">
                  <option *ngFor="let duration of product?.subscriptionUnit?.availableDuration | toDeliveryDuration"
                    [value]="duration.code">
                    {{ duration.name}}
                  </option>
                </select>
              </div>
            </div> -->
          </div>

          <div *ngIf="shopForm.get('frequency').value !== DeliveryFrequencies?.OneTime"
            class="row price-total mt-2 mb-1">
            <div class="col-12">
              <em class="pt-1">${{totalPrice | number:'.2-2'}}</em>
              <b>${{totalDiscountedPrice | number: '.2-2'}}</b>
            </div>
          </div>

          <div *ngIf="shopForm.get('frequency').value === DeliveryFrequencies?.OneTime"
            class="row price-total mt-2 mb-1">
            <div class="col-12">

              <b>${{totalPrice | number:'.2-2'}}</b>
            </div>
          </div>
          <div class="row mt-2" *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'">
            <div class="col-12">
              <div class="out-of-stock-text px-2">
                <h5 class="fw-bold text-white">
                  Product temporarily unavailable.
                </h5>
                <h5 class="fw-normal text-white">
                  Please choose another product from the product line or contact customer service.
                </h5>
              </div>
            </div>
          </div>
          <div class="row mt-2 mb-1">
            
            <div class="col-12">
              <app-autoship [product]="product"></app-autoship>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
           
              <button type="submit" class="btn btn-primary mt-3 py-3" *ngIf="product?.stock?.stockLevelStatus === 'inStock' && !changeProduct">
                Add to Cart
              </button>
              <button class="btn btn-primary mt-3 py-3" *ngIf="product?.stock?.stockLevelStatus === 'inStock' && changeProduct">
                Select
              </button>
            </div>
            <div class="col-12 mt-3">
              <button class="btn btn-outline py-3"  *ngIf="!changeProduct" (click)="goBack()">Continue Shopping</button>
            </div>
            <div class="mt-3 ps-2">
              <label>*Save up to {{product?.hthPromo}} on all Hill's products.</label>
            </div>
          </div>

          <!-- <div class="d-flex mt-5">
            <div class="w-50">
              <h5 class="fw-normal">
                Sizes
              </h5>
              <div class="d-flex flex-wrap mt-3">
                <a [routerLink]="[!changeProduct ? '/np-product': '/np-product-change', clinicId, option.code]" [replaceUrl]="true"
                    *ngFor="let option of product?.baseOptions[0]?.options;"
                    class="border me-2 p-2"
                    [class.border-primary]="option.code === product.code">
                  {{option.size}}
                </a>
              </div>
            </div>
            <div *ngIf="!changeProduct">
              <h5 class="fw-normal">
                Quantity
              </h5>
              <div class="form-group mt-3">
                <select id="quantity"
                        name="quantity"
                        class="form-control custom-select"
                        formControlName="quantity">
                  <option *ngFor="let quantity of quantities" [value]="quantity">{{quantity}}</option>
                </select>
              </div>
            </div>
          </div> -->
        </form>
      </div>
    </div>

    <div class="detail-accordion-card mt-4 mb-2 px-3">
      <div ngbAccordion [closeOthers]="false" class="accordion-borderless" 
     (panelChange)="(selectedPanels[$event.panelId] = $event.nextState);">
  
    <div ngbAccordionItem [collapsed]="selectedPanels['description'] ? false : true">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['description']=!selectedPanels['description']"> 
              <h5>Description</h5>
              <i *ngIf="selectedPanels['description']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['description']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.description" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['description']}"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="keyBenefitsExist" ngbAccordionItem [collapsed]="selectedPanels['keyBenefits'] ? false : true">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['keyBenefits']=!selectedPanels['keyBenefits']"> 
              <h5>Key Benefits</h5>
              <i *ngIf="selectedPanels['keyBenefits']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['keyBenefits']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.mainUSPClaims" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['keyBenefits']}"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="product?.helpProcess" ngbAccordionItem [collapsed]="selectedPanels['howItHelps'] ? false : true">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton (click)="selectedPanels['howItHelps']=!selectedPanels['howItHelps']"> 
            <h5>How it Helps</h5>
            <i *ngIf="selectedPanels['howItHelps']" class="fa fa-minus"></i>
            <i *ngIf="!selectedPanels['howItHelps']" class="fa fa-plus"></i>
          </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.helpProcess" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['howItHelps']}"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="product?.workProcess" ngbAccordionItem [collapsed]="selectedPanels['howItWorks'] ? false : true">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton (click)="selectedPanels['howItWorks']=!selectedPanels['howItWorks']"> 
            <h5>How it Works</h5>
            <i *ngIf="selectedPanels['howItWorks']" class="fa fa-minus"></i>
            <i *ngIf="!selectedPanels['howItWorks']" class="fa fa-plus"></i>
          </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.workProcess" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['howItWorks']}"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="product?.feedingGuide" ngbAccordionItem [collapsed]="selectedPanels['feedingGuide'] ? false : true">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton (click)="selectedPanels['feedingGuide']=!selectedPanels['feedingGuide']"> 
            <h5>Feeding Guide</h5>
            <i *ngIf="selectedPanels['feedingGuide']" class="fa fa-minus"></i>
            <i *ngIf="!selectedPanels['feedingGuide']" class="fa fa-plus"></i>
          </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.feedingGuide" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['feedingGuide']}"></div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="product?.ingredients" ngbAccordionItem [collapsed]="selectedPanels['activeIngredients'] ? false : true">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton (click)="selectedPanels['activeIngredients']=!selectedPanels['activeIngredients']"> 
            <h5>Ingredients</h5>
            <i *ngIf="selectedPanels['activeIngredients']" class="fa fa-minus"></i>
            <i *ngIf="!selectedPanels['activeIngredients']" class="fa fa-plus"></i>
          </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.ingredients" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['activeIngredients']}"></div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
    </div>

    <!-- ------------ More Products ----------- -->
    <h3 *ngIf="referenceProducts?.length && !changeProduct" class="w800 pt-5 txt-black px-3">More Products</h3>

    <div *ngIf="referenceProducts?.length && !changeProduct" class="row px-4 more-products">
      <a [routerLink]="['/np-product',clinicId,refProduct.target.code,nutritionPlan?.pet?.petCode]"
        class="col-6 col-sm-6 col-md-4 col-lg-3 mt-2 mb-4 px-2"
        
        *ngFor="let refProduct of referenceProducts; let i=index" (click)="selectItem(refProduct.target)">
        
        <div class="product-item-card">
          <div class="d-flex flex-column">
            <!-- <span class="pb-2">Hill's Prescription</span> -->
            <h5>{{refProduct.target.name}}</h5>
            <!-- <span class="pt-1">2.9 OZ, 24 Pack</span> -->
            <figure *ngIf="refProduct?.target?.images && refProduct?.target?.stock?.stockLevelStatus === 'inStock'">
              <img src="{{refProduct.target.images | appImg:'lrg'}}" class="product-item-img" alt="{{refProduct.name}}"
                *ngIf="refProduct.target.images" />
            </figure>

            <div class="text-center" *ngIf="!refProduct.target.images">
              <i class="fas fa-image fa-8x"></i>
            </div>
            <!--Out of Stock Product Start-->
            <div class="productImageHolder" *ngIf="refProduct?.target.images && refProduct?.target?.stock?.stockLevelStatus === 'outOfStock'">
              <figure>              
                <img src="{{refProduct.target.images | appImg:'lrg'}}" class="product-item-img" alt="{{refProduct.name}}"
                *ngIf="refProduct.target.images" />
              </figure>
              <span class="small out-of-stock px-1 py-1 text-center">
                Temporarily Unavailable
              </span>         
            </div>
            <!--Out of Stock Product End-->
          </div>


          <div class="d-flex mt-2 align-items-start flex-column">
            <div class="d-flex flex-row align-items-center">
              <div class="old-price pe-2">
                {{refProduct.target.price.formattedValue}}
              </div>
              <!-- <span class="icon-info">
                <i class="fa fa-info-circle"></i>
              </span> -->
            </div>
            <div class="price pe-2">
              <span>${{refProduct.target.discountedPrice | number:'.2-2'}}</span>
            </div>

          </div>
        </div>
      </a>
    </div>

    <div class="d-flex align-items-center justify-content-center mt-2 pb-4"
      *ngIf="(product?.productReferences?.length > totalProductToShow) && !changeProduct">
      <button class="custom-font btn-white btn px-5" type="button" (click)="loadMoreProducts()">
        LOAD MORE
      </button>
    </div>

    <!-- ------------ End More Products ----------- -->


  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg px-1">
    <div class="container pt-4 pb-5">
      <div class="d-flex align-items-center">
        <div class="custom-font w500 detail-breadcrumb pt-1" *ngIf="nutritionPlan">
          <a [routerLink]="['/pets/shop', nutritionPlan.pet.petCode]">{{nutritionPlan.planName}}</a> /
          {{product?.name}}
        </div>
      </div>
      <div class="product-detail-head custom-font pt-4">
        <!-- <span>Hill's Prescription</span> -->
        <h2 class="product-detail-title w800 my-1">
          {{product?.name}}
        </h2>
        <!-- <span>2.9oz, 24-pack</span> -->
      </div>
      <div class="row pt-5 pb-3">
        <div class="col-sm-3 d-flex flex-column align-items-center">
          <div class="detail-img-card py-4 w-100">
            <div id="imgContainer" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'inStock'">
              <img src="{{product.images | appImg:'lrg'}}"  (click)="open()" class="app-cart-img" alt="{{product.name}}" />
            </div>
            <div class="app-cart-img text-center" *ngIf="!product?.images">
              <i class="fas fa-image fa-10x"></i>
            </div>
            <!--Out of Stock Product Start-->
            <div class="productImageHolder" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'outOfStock'">
              <img src="{{product.images | appImg:'lrg'}}" class="app-cart-img" alt="{{product.name}}"/>
                <span class="small out-of-stock px-1 py-1 text-center">
                  Temporarily Unavailable
                </span>         
            </div>
            <!--Out of Stock Product End-->
            <span class="sku-tag small mt-4 custom-font">
              <em class="py-1 px-3 w500">SKU {{product?.code}}</em>
            </span>
          </div>
        </div>

        <div class="col-sm-5 custom-font">
          <div class="description-card p-4">
            <!-- <div class="d-flex rate-like align-items-center pb-4">
                <span class="stars pe-1">
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                  <i class="fa fa-star" aria-hidden="true"></i>
                </span>
                <small>305 Reviews</small>
                <span class="like text-right d-flex align-items-center justify-content-end">
                  <i class="fa fa-heart"></i> <em>Favourite</em>
                </span>
              </div> -->
            <h5 class="pb-3 mb-1">Description</h5>
            <div [innerHTML]="product?.description"></div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="detail-price-card custom-font py-4 px-3 edit-form">
            <!-- <div class="d-flex align-items-center">
              <div class="h4 m-0 flex-grow-1">
                <span class="text-muted fw-normal">USD </span>
                <span class="text-success">{{product?.baseOptions[0]?.selected.priceData.formattedValue}}</span>
              </div>
            </div> -->
            <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="!changeProduct ? addToCart(): selectProduct()">
              <div class="row">
                <div class="col-12 col-md-6 pe-md-2">
                  <label for="size">Size</label>
                  <div class="form-group d-flex align-items-center">
                    <select class="form-control" id="size" (change)="fetchProductDetailOnSizeChange()"
                      formControlName="size" [value]="product?.baseOptions[0]?.selected.code">
                      <option *ngFor="let option of product?.baseOptions[0]?.options;" [ngValue]="option.code" [selected]="product?.baseOptions[0]?.selected.code === option.code? true : false" [disabled]="option?.stock?.stockLevelStatus === 'outOfStock' ? true : null">
                        {{option.size}}<span *ngIf="option?.stock?.stockLevelStatus === 'outOfStock'"> - Temp Unavailable</span>
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-6 ps-md-2" *ngIf="!changeProduct">
                  <label for="quantity">Quantity</label>
                  <div class="form-group" *ngIf="product?.stock?.stockLevelStatus === 'inStock'">
                    <div class="field-quantity d-flex align-items-center justify-content-center">
                      <span class="cursor-pointer" (click)="onQuantityRemoved()">
                        <i class="minus">-</i>
                      </span>
                      <span class="flex-fill"><input type="text" id="quantity" readonly formControlName="quantity" /></span>
                      <span class="cursor-pointer" (click)="onQuantityAdded()">
                        <i>+</i>
                      </span>
                    </div>
                  </div>
                  <!--Out of Stock Product Start-->
                  <div class="form-group out-of-stock-quantity" *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'">
                    <div class="field-quantity d-flex align-items-center justify-content-center">
                      <span class="cursor-pointer">
                        <i class="minus">-</i>
                      </span>
                      <span class="flex-fill"><input type="text" id="quantity" readonly formControlName="quantity" /></span>
                      <span class="cursor-pointer">
                        <i>+</i>
                      </span>
                    </div>
                  </div>
                  <!--Out of Stock Product End-->
                </div>
                  <!-- Frequency and Total Price -->
                  <div class="col-12 col-md-6 pe-md-2" *ngIf="!changeProduct">
                    <label for="frequency">Frequency</label>
                    <div class="form-group d-flex align-items-center">
                      <select class="form-control" id="frequency" formControlName="frequency">
                        <option
                          *ngFor="let frequency of product?.subscriptionUnit?.availableDeliveryFrequencies | toDeliveryFrequency"
                          [ngValue]="frequency.code">
                          {{frequency.name}}
                        </option>
  
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 ps-md-2 d-none d-md-block" >
                    <div class="row price-total mt-2 mb-1" [ngClass]="{'oneTimeShip': shopForm.get('frequency').value === DeliveryFrequencies?.OneTime}">
                      <div class="col-5 d-flex align-items-end">
                        <span>Total</span>
                      </div>
  
                      <div *ngIf="shopForm.get('frequency').value !== DeliveryFrequencies?.OneTime"
                        class="col-7 d-flex align-items-end justify-content-end flex-wrap">
                        <em>${{totalPrice| number:'.2-2'}}</em>
                        <b class="ps-2">${{totalDiscountedPrice | number: '.2-2'}}</b>
                      </div>
  
                      <div *ngIf="shopForm.get('frequency').value === DeliveryFrequencies?.OneTime"
                        class="col-7 d-flex align-items-end justify-content-end flex-wrap">
                        <b class="ps-2">${{totalPrice| number:'.2-2'}}</b>
                      </div>
                    
                    </div>
                  </div>
                </div>
  
                <div class="row price-total mt-2 mb-1 d-block d-md-none justify-content-center">
                  <div class="col-12 d-flex align-items-end justify-content-center">
                    <span>Total</span>
                  </div>
  
                  <div *ngIf="shopForm.get('frequency').value !== DeliveryFrequencies?.OneTime"
                    class="col-12 d-flex align-items-end justify-content-center flex-wrap">
                    <em>${{totalPrice| number:'.2-2'}}</em>
                    <b class="ps-2">${{totalDiscountedPrice | number: '.2-2'}}</b>
                  </div>
  
                  <div *ngIf="shopForm.get('frequency').value === DeliveryFrequencies?.OneTime"
                    class="col-12 d-flex align-items-end justify-content-center flex-wrap">
                    <b class="ps-2">${{totalPrice| number:'.2-2'}}</b>
                  </div>
                </div>
  
             
              <div class="row mt-2 mb-1">
                <div class="col-12">
                <app-autoship  [product]="product"></app-autoship>
              </div>
              </div>

              <div class="row">
                <div class="col-12">
                 
                  <button type="submit" class="btn btn-primary mt-3" *ngIf="!changeProduct" [disabled]="product?.stock?.stockLevelStatus === 'outOfStock' ? true : null">
                    Add to Cart
                  </button>
                  <button class="btn btn-primary mt-3" *ngIf="changeProduct" [disabled]="product?.stock?.stockLevelStatus === 'outOfStock' ? true : null">
                    Select
                  </button>
                </div>
                <div class="col-12 mt-3">
                  <button class="btn btn-outline"  *ngIf="!changeProduct" (click)="goBack()">Continue Shopping</button>
                </div>
                <div class="mt-3 ps-2">
                  <label>*Save up to {{product?.hthPromo}} on all Hill's products.</label>
                </div>
              </div>

              <!-- <div class="d-flex mt-5">
                <div class="w-50">
                  <h5 class="fw-normal">
                    Sizes
                  </h5>
                  <div class="d-flex flex-wrap mt-3">
                    <a [routerLink]="[!changeProduct ? '/np-product': '/np-product-change', clinicId, option.code]" [replaceUrl]="true"
                        *ngFor="let option of product?.baseOptions[0]?.options;"
                        class="border me-2 p-2"
                        [class.border-primary]="option.code === product.code">
                      {{option.size}}
                    </a>
                  </div>
                </div>
                <div *ngIf="!changeProduct">
                  <h5 class="fw-normal">
                    Quantity
                  </h5>
                  <div class="form-group mt-3">
                    <select id="quantity"
                            name="quantity"
                            class="form-control custom-select"
                            formControlName="quantity">
                      <option *ngFor="let quantity of quantities" [value]="quantity">{{quantity}}</option>
                    </select>
                  </div>
                </div>
              </div> -->
            </form>
          </div>
        </div>
      </div>
      <!--Out of Stock Product Start-->
      <div class="d-flex" *ngIf="product?.stock?.stockLevelStatus === 'outOfStock'">
        <div class="out-of-stock-text pt-2 px-2 pb-1 text-start">
          <h6 class="text-white">
            <span class="fw-bold">Product temporarily unavailable.</span>
            <span class="fw-normal"> Please choose another product from the product line or contact customer service.</span>
          </h6>
        </div>
      </div>
      <!--Out of Stock Product End-->
      <div class="detail-accordion-card mt-4 mb-2">
        <div ngbAccordion [closeOthers]="false" 
     (panelChange)="(selectedPanels[$event.panelId] = $event.nextState);" class="accordion-borderless">
  
    <div *ngIf="keyBenefitsExist" ngbAccordionItem [collapsed]="!selectedPanels['keyBenefits']">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['keyBenefits']=!selectedPanels['keyBenefits']"> 
              <h5>Key Benefits</h5>
              <i *ngIf="selectedPanels['keyBenefits']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['keyBenefits']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.mainUSPClaims" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['keyBenefits']}"></div>
                </ng-template>
            </div>
        </div>
    </div>
  
    <div *ngIf="product?.helpProcess" ngbAccordionItem [collapsed]="!selectedPanels['howItHelps']">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['howItHelps']=!selectedPanels['howItHelps']"> 
              <h5>How it Helps</h5>
              <i *ngIf="selectedPanels['howItHelps']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['howItHelps']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.helpProcess" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['howItHelps']}"></div>
                </ng-template>
            </div>
        </div>
    </div>
  
    <div *ngIf="product?.workProcess" ngbAccordionItem [collapsed]="!selectedPanels['howItWorks']">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['howItWorks']=!selectedPanels['howItWorks']"> 
              <h5>How it Works</h5>
              <i *ngIf="selectedPanels['howItWorks']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['howItWorks']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.workProcess" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['howItWorks']}"></div>
                </ng-template>
            </div>
        </div>
    </div>
  
    <div *ngIf="product?.feedingGuide" ngbAccordionItem [collapsed]="!selectedPanels['feedingGuide']">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['feedingGuide']=!selectedPanels['feedingGuide']"> 
              <h5>Feeding Guide</h5>
              <i *ngIf="selectedPanels['feedingGuide']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['feedingGuide']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.feedingGuide" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['feedingGuide']}"></div>
                </ng-template>
            </div>
        </div>
    </div>
  
    <div *ngIf="product?.ingredients" ngbAccordionItem [collapsed]="!selectedPanels['activeIngredients']">
        <h2 ngbAccordionHeader>
            <button ngbAccordionButton (click)="selectedPanels['activeIngredients']=!selectedPanels['activeIngredients']"> 
              <h5>Ingredients</h5>
              <i *ngIf="selectedPanels['activeIngredients']" class="fa fa-minus"></i>
              <i *ngIf="!selectedPanels['activeIngredients']" class="fa fa-plus"></i>
            </button>
        </h2>
        <div ngbAccordionCollapse>
            <div ngbAccordionBody>
                <ng-template>
                    <div [innerHTML]="product?.ingredients" class="app-table-container pt-0 pb-3 ps-4 pe-4 small"
                        [ngClass]="{'bg-white': selectedPanels['activeIngredients']}"></div>
                </ng-template>
            </div>
        </div>
    </div>
  
</div>
      </div>

      <!-- ------------ More Products ----------- -->
      <h3 *ngIf="referenceProducts?.length && !changeProduct" class="w800 pt-5 txt-black pb-2">More Products</h3>
      <div *ngIf="referenceProducts?.length && !changeProduct" class="row more-products">
        <a [routerLink]="['/np-product',clinicId,refProduct.target.code,nutritionPlan?.pet?.petCode]"
          class="col-6 col-sm-6 col-md-4 col-lg-3 mt-2 mb-4"
          *ngFor="let refProduct of product?.productReferences; let i=index" (click)="selectItem(refProduct.target)">

          <div class="product-item-card">
            <div class="d-flex flex-column">
              <!-- <span class="pb-2">Hill's Prescription</span> -->
              <h5>{{refProduct.target.name}}</h5>
              <!-- <span class="pt-1">2.9 OZ, 24 Pack</span> -->
              <figure *ngIf="refProduct?.target?.images && refProduct?.target?.stock?.stockLevelStatus === 'inStock'">
                <img src="{{refProduct.target.images | appImg:'lrg'}}" class="product-item-img" alt="{{refProduct.name}}"
                  *ngIf="refProduct.target.images" />
              </figure>
              <div class="text-center" *ngIf="!refProduct.target.images">
                <i class="fas fa-image fa-10x"></i>
              </div>
              <!--Out of Stock Product Start-->
              <div class="productImageHolder" *ngIf="refProduct?.target.images && refProduct?.target?.stock?.stockLevelStatus === 'outOfStock'">
                <figure>              
                  <img src="{{refProduct.target.images | appImg:'lrg'}}" class="product-item-img" alt="{{refProduct.name}}"
                  *ngIf="refProduct.target.images" />
                </figure>
                <span class="small out-of-stock px-1 py-1 text-center">
                  Temporarily Unavailable
                </span>         
              </div>
              <!--Out of Stock Product End-->
            </div>


            <div class="d-flex mt-2 align-items-center">
              <div class="old-price pe-2">
                {{refProduct.target.price.formattedValue}}
              </div>
              <!-- <span class="icon-info">
                <i class="fa fa-info-circle"></i>
              </span> -->
              <div class="price ps-0">
                <!-- <span *ngIf="refProduct.clinicPrice">${{product.clinicPrice | number: '.2-2'}}</span>
                <span *ngIf="!refProduct.clinicPrice">${{product.msrpPrice | number: '.2-2'}}</span> -->

                <span>${{refProduct.target.discountedPrice | number:'.2-2'}}</span>
              </div>

            </div>
          </div>
        </a>
      </div>
      <!-- ------------ End More Products ----------- -->

    </div><!-- end container-->
  </div><!-- end full-layout-->
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <button (click)="dismissSuccess();" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>
    <div class="app-mobile-layout" *ngIf="isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{product.images | appImg:'lrg'}}" class="app-cart-img" *ngIf="product?.images" alt="{{product.name}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="mt-4 h4 text-center">
        {{product?.name}} has been added to your cart
      </p>
    </div>
    <div class="app-full-layout" *ngIf="!isMobile">
      <div class="d-flex flex-column align-items-center pt-3">
        <img src="{{product.images | appImg:'lrg'}}" class="app-cart-img" *ngIf="product?.images" alt="{{product.name}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="mt-4 h4 text-center">
        {{product?.name}} has been added to your cart
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ms-2" (click)="closeSuccess()">
      View Cart
    </button>
    <button type="button" class="btn btn-secondary ms-2" (click)="dismissSuccess();goBack();">
      Continue Shopping
    </button>
  </div>
</ng-template>

<ng-template #productChangedModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <div class="app-mobile-layout" *ngIf="isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{product.images | appImg:'lrg'}}" class="app-cart-img" *ngIf="product?.images" alt="{{product.name}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="m-4 h4 text-center">
        Your subscription has been changed to {{product?.name}}
      </p>
    </div>
    <div class="app-full-layout" *ngIf="!isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{product.images | appImg:'lrg'}}" class="app-cart-img" *ngIf="product?.images" alt="{{product.name}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="m-4 h4 text-center">
        Your subscription has been changed to {{product?.name}}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
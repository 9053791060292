import { Action, createAction, props } from '@ngrx/store';
import { Session } from '../../api';
import { Authenticate } from '../models/authenticate';
import { Registration } from '../models/registration';
import { EmailChange } from '../../profile/models/emailChange';
import { ResetPasswordChange } from '../models/resetPasswordChange';
import { PetParentDetail } from '../models/pet-parent-detail';
import { GetUserPk } from 'src/app/api/model/getuserpk';

// Invisible V3 captcha for login -  
export enum AuthActionTypes {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  Refresh = '[Auth] Refresh',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
  LoggedRedirect = '[Auth] Logged Redirect',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFailure = '[Auth] Logout Failure',
  Register = '[Auth] Register',
  RegisterSuccess = '[Auth] Register Success',
  RegisterFailure = '[Auth] Register Failure',
  ForgotPassword = '[Auth] Forgot Password',
  ForgotPasswordSuccess = '[Auth] Forgot Password Success',
  ForgotPasswordFailure = '[Auth] Forgot Password Failure',
  ResetPassword = '[Auth] Reset Password',
  ResetPasswordFailure = '[Auth] Reset Passwaord Failure',
  CheckTokenExpiry = '[Auth] Check Token Expiry',
  SetLogin = '[Auth] Set Login',
  RegisterPetParent = '[Auth] Register Pet Parent',
  RegisterPetParentSuccess = '[Auth] Register Pet Parent Success',
  RegisterPetParentFailure = '[Auth] Register Pet Parent Failure',
  GetCSRFToken = '[Auth] Get CSRF Token',
  GetCSRFTokenSuccess = '[Auth] Get CSRF Token Success',
  GetCSRFTokenFailure = '[Auth] Get CSRF Token Failure',
  GetSiteVerifyCaptcha = '[Auth] Get Site Verify Captcha',
  GetSiteVerifyCaptchaSuccess = '[Auth] Get Site Verify Captcha Success',
  FetchPk = '[Auth] Fetch Pk',
  FetchPkSuccess = '[Auth] Fetch Pk Success',
  FetchPkFailure = '[Auth] Fetch Pk Failure',
  TriggerSprigEvent = '[Sprig] Trigger Event',
}
//End

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public authenticate: Authenticate) {
  }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public session: Session) {
    localStorage.removeItem("whatsNewSessionFlag");     //whats new popup is shown only once per session
  }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {
  }
}

export class FetchPk implements Action {
readonly type = AuthActionTypes.FetchPk;

constructor(public payload: GetUserPk) {}
}

export class FetchPkSuccess implements Action {
readonly type = AuthActionTypes.FetchPkSuccess;
pk: string;

constructor(public payload: { pk: string }) {}
}

export class FetchPkFailure implements Action {
readonly type = AuthActionTypes.FetchPkFailure;

constructor(public payload: { error: any }) {}
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;

  constructor() {
  }
}

export class LogoutFailure implements Action {
  readonly type = AuthActionTypes.LogoutFailure;

  constructor(public payload: any) {
  }
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class LoggedRedirect implements Action {
  readonly type = AuthActionTypes.LoggedRedirect;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
  constructor() {
  }
}

export class Refresh implements Action {
  readonly type = AuthActionTypes.Refresh;

  constructor(public session: Session) {
  }
}

export class Register implements Action {
  readonly type = AuthActionTypes.Register;

  constructor(public registration: Registration) {
  }
}

export class RegisterSuccess implements Action {
  readonly type = AuthActionTypes.RegisterSuccess;

  constructor() {
  }
}

export class RegisterFailure implements Action {
  readonly type = AuthActionTypes.RegisterFailure;

  constructor(public registerError: any) {
  }
}

export class ForgotPassword implements Action {
  readonly type = AuthActionTypes.ForgotPassword;

  constructor(public email: string) {
  }
}

export class ForgotPasswordSuccess implements Action {
  readonly type = AuthActionTypes.ForgotPasswordSuccess;

  constructor() {
  }
}

export class ForgotPasswordFailure implements Action {
  readonly type = AuthActionTypes.ForgotPasswordFailure;

  constructor(public forgotPasswordError: string) {
  }
}

export class ResetPassword implements Action {
  readonly type = AuthActionTypes.ResetPassword;

  constructor(public resetPasswordChange: ResetPasswordChange) {
  }
}

export class ResetPasswordFailure implements Action {
  readonly type = AuthActionTypes.ResetPasswordFailure;

  constructor(public resetPasswordError: string) {
  }
}
export class CheckTokenExpiry implements Action {
  readonly type = AuthActionTypes.CheckTokenExpiry;

  constructor(public isTokenExpired: boolean) {
  }
}

export class SetLogin implements Action {
  readonly type = AuthActionTypes.SetLogin;

  constructor(public emailChange: EmailChange) {
  }
}

export class RegisterPetParent implements Action {
  readonly type = AuthActionTypes.RegisterPetParent;

  constructor(public petParentRegistration: PetParentDetail) {
  }
}

export class RegisterPetParentSuccess implements Action {
  readonly type = AuthActionTypes.RegisterPetParentSuccess;

  constructor() {
  }
}

export class RegisterPetParentFailure implements Action {
  readonly type = AuthActionTypes.RegisterPetParentFailure;

  constructor(public registerPetParentError: any) {
  }
}

export class GetCSRFToken implements Action {
  readonly type = AuthActionTypes.GetCSRFToken;

  constructor() {
  }
}

export class GetCSRFTokenSuccess implements Action {
  readonly type = AuthActionTypes.GetCSRFTokenSuccess;

  constructor(public session: Session) {
  }
}

export class GetCSRFTokenFailure implements Action {
  readonly type = AuthActionTypes.GetCSRFTokenFailure;

  constructor(public payload: any) {
  }
}

// Invisible V3 captcha for login -  
export class GetSiteVerifyCaptcha implements Action {
  readonly type = AuthActionTypes.GetSiteVerifyCaptcha;

  constructor(public token: string) {
  }
}

export class GetSiteVerifyCaptchaSuccess implements Action {
  readonly type = AuthActionTypes.GetSiteVerifyCaptchaSuccess;

  constructor() {
  }
}

export class TriggerSprigEvent implements Action {
  readonly type = AuthActionTypes.TriggerSprigEvent;

  constructor(public payload: any) {
  }
}

export type AuthActionsUnion =
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | LoggedRedirect
  | Logout
  | LogoutSuccess
  | LogoutFailure
  | Refresh
  | Register
  | RegisterSuccess
  | RegisterFailure
  | ForgotPassword
  | ForgotPasswordSuccess
  | ForgotPasswordFailure
  | ResetPassword
  | ResetPasswordFailure
  | CheckTokenExpiry
  | SetLogin
  | RegisterPetParent
  | RegisterPetParentSuccess
  | RegisterPetParentFailure
  | GetCSRFToken
  | GetCSRFTokenSuccess
  | GetCSRFTokenFailure
  | GetSiteVerifyCaptcha
  | GetSiteVerifyCaptchaSuccess
  | FetchPk
  | FetchPkSuccess
  | FetchPkFailure
  ;
// End
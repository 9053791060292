import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  SimpleChange,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AutoShipTemplate, EditingSubscription } from '../../../api';
import { DeliveryFrequencies, OrderStatuses, LabelTypes } from '../../../shared/constants/payment';
import { DateUtil } from '../../../shared/utils/dateUtil';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DateValidation } from 'src/app/shared/validation/dateValidation';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnChanges {

  DeliveryFrequencies = DeliveryFrequencies;

  OrderStatuses = OrderStatuses;

  nextDeliveryDateSelectionform: UntypedFormGroup;

  showRadioError: boolean = false;

  nextDeliveryDateSelectionModalRef: NgbModalRef;

  skipSubscriptionModalRef: NgbModalRef;

  shipNowModalRef: NgbModalRef;

  LabelTypes = LabelTypes;

  dtr: string;

  minDate: string;

  maxDate: string;

  @Input() isMobile: boolean | false;

  @Input() subscription: AutoShipTemplate | null;

  @Input() subscriptionChangePending: boolean | false;

  @Output() submitted = new EventEmitter<EditingSubscription>();

  @Output() subscriptionSelected = new EventEmitter<AutoShipTemplate>();

  cancelSubscriptionModalRef: NgbModalRef;

  @ViewChild('confirmCancelModal', { static: true })
  confirmCancelModal: any;
  subscriptionCancelForm: UntypedFormGroup;
  reason  = '';

  @ViewChild('confirmHoldModal', { static: true })
  confirmHoldModal: any;

  @ViewChild('confirmReactivatelModal', { static: true })
  confirmReactivatelModal: any;

  @ViewChild('skipSubscriptionModal', { static: true })
  skipSubscriptionModal: any;

  @ViewChild('shipNowModal', { static: true })
  shipNowModal: any;

  constructor(private router: Router,
              private modalService: NgbModal, private fb: UntypedFormBuilder) {
                this.createNextDeliveryDateSelectionForm();
                this.subscriptionCancelFormInit();
  }

  subscriptionCancelFormInit() {
    this.subscriptionCancelForm = this.fb.group({
      subscriptionCancelReasonRadio: ['', Validators.required],
      cancelReason: ['']
    });
  }

  resetForm() {
    this.subscriptionCancelForm.reset();
    this.showRadioError = false;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.nextDeliveryDateSelectionModalRef) {
      this.nextDeliveryDateSelectionModalRef.close();
    }

    const subChange: SimpleChange = changes['subscription'];       // To track which subscription got edited, and get its subscription ID from editing subscription, for refreshing the subscription
    if (subChange && !subChange.firstChange &&
      subChange.currentValue) {
      this.refreshSubscription();
    }
  }
  
  refreshSubscription() {
    this.subscriptionSelected.emit(this.subscription);            // To refresh subscription with new values, from searchedsubscription state
  }

  cancelSubscription() {
    const model = this.subscriptionCancelForm.value;
    this.reason = model.subscriptionCancelReasonRadio.valueOf();
    if (this.reason === 'OTHER') {
      if (model.cancelReason != null) {
        this.reason = model.cancelReason.valueOf();
      } else {
        this.reason = 'OTHER';
      }
    }
    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(this.subscription),
      subscriptionOrderStatus: OrderStatuses.Cancelled,
      subscriptionCancelReason: this.reason
    };
    this.submitted.emit(editingSubscription);
    this.closeCancelSubscription();
    // 'You have successfully updated your subscription' hint is displayed -   
    this.router.navigate(['subscriptions']);
    // End
  }

  checkCancelSubscription() {
    if (this.subscriptionCancelForm.valid) {
      this.showRadioError = false;
      this.cancelSubscription();
    } else {
      this.showRadioError = true;
    }
  }

  openCancelSubscription() {
    this.resetForm();
    setTimeout(() => {
      this.cancelSubscriptionModalRef = this.modalService.open(this.confirmCancelModal, {size: 'lg'});
    }, 100);
  }

  changeRadioStatus() {
    if (this.subscriptionCancelForm.valueChanges) {
      this.showRadioError = false;
    }
  }

  closeCancelSubscription() {
    this.cancelSubscriptionModalRef.close();
    this.resetForm();
  }

  pauseSubscription() {
    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(this.subscription),
      subscriptionOrderStatus: OrderStatuses.Hold
    };
    this.submitted.emit(editingSubscription);
  }

  confirmPauseSubscription() {
    this.modalService.open(this.confirmHoldModal).result.then(
      () => {
        this.pauseSubscription();
      },
      () => {
      }
    );
  }

  openSkipSubscription(subscription: AutoShipTemplate) {

    setTimeout(() => {
      this.skipSubscriptionModalRef = this.modalService.open(this.skipSubscriptionModal);
    }, 100);
  }

  confirmSkipSubscription(subscription: AutoShipTemplate){

    const nextSkipDeliveryDate = DateUtil.getDateStr(subscription.nextSkipDeliveryDate, 'MM/dd/yyyy');

    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(subscription),
      subscriptionOrderStatus: OrderStatuses.SkipNextDelivery,
      nextDeliveryDate: nextSkipDeliveryDate

    };

    this.submitted.emit(editingSubscription);

    this.skipSubscriptionModalRef ? this.skipSubscriptionModalRef.close() : this.showRadioError = true;
    this.router.navigate(['subscriptions']);

  }

  createNextDeliveryDateSelectionForm() {
    /*For Calendar */
    const now = new Date();
    const minDate = DateUtil.addWeekDay(now, 6);
    this.minDate = DateUtil.getDateStr(minDate, 'M/d/y');

    let dtToday = new Date();
    let dt = DateUtil.addWeekDay(dtToday, 6);
    this.maxDate = this.dateConvertion(dt);
    /*End For Calendar */
    this.nextDeliveryDateSelectionform = this.fb.group({
      NextDeliveryDateSelectionRadio: ['', Validators.required],
      deliveryDate: ['', {
        validators: [Validators.required, DateValidation.onlyWeekDays(), DateValidation.minDate(this.maxDate)],
      }],
    });
  }

  //To set the Min Date for selection
  dateConvertion(date){
    let dtToday = date;
    let month = dtToday.getMonth() + 1;
    let day = dtToday.getDate();
    let year = dtToday.getFullYear();
    let currentMon : string | number = month,
      today : string | number = day;
    if(month < 10){
      currentMon = '0' + month.toString();
    }
    if(day < 10) {
      today = '0' + day.toString();
    }
    return year + '-' + currentMon + '-' + today;
  }
  showNextSubscriptionDateModal(subscription: AutoShipTemplate){
    const next = subscription.nextScheduledDeliveryDate ?
    DateUtil.add(DateUtil.getDateStr(subscription.nextScheduledDeliveryDate), 0) :
    DateUtil.addWeekDay(new Date(), 6);
    this.dtr = DateUtil.getDateStr(next);
    this.nextDeliveryDateSelectionform.reset();
    this.checkValue();
    this.nextDeliveryDateSelectionform.get('deliveryDate').reset({value: this.dtr, disabled: true});
    setTimeout(() => {
      this.nextDeliveryDateSelectionModalRef = this.modalService.open(this.confirmReactivatelModal);
      this.nextDeliveryDateSelectionModalRef.result.then((result) => {
      }, (reason) => {
      });
    }, 100);
}
checkValue() {
  this.nextDeliveryDateSelectionform.get('NextDeliveryDateSelectionRadio').valueChanges.subscribe(val => {
    this.showRadioError=false;
    if(val === 'WEEK_n') {
      this.nextDeliveryDateSelectionform.get('deliveryDate').reset({value: this.dtr, disabled: false});
    }
    else {
      this.nextDeliveryDateSelectionform.get('deliveryDate').reset({value: this.dtr, disabled: true});
    }
  })
}

  reactivateSubscription() {
    const model = this.nextDeliveryDateSelectionform.value;
    let temp_date: string;
    if(this.nextDeliveryDateSelectionform.value.NextDeliveryDateSelectionRadio == 'WEEK_n'){
      temp_date = DateUtil.getDateStr(model.deliveryDate, 'MM/dd/y');
    }else{
      temp_date = "";
    }

    if (this.nextDeliveryDateSelectionform.valid) 
      {
        const editingSubscription: EditingSubscription = {
          ...this.getEditingSubscription(this.subscription),
          subscriptionOrderStatus: OrderStatuses.Active,
          nextDeliveryDate:"",
          nextCustomDeliveryMode: this.nextDeliveryDateSelectionform.value.NextDeliveryDateSelectionRadio,
          nextCustomDeliveryDate: temp_date

        };
        if(this.nextDeliveryDateSelectionModalRef){
          this.nextDeliveryDateSelectionModalRef.close;
        }
        this.submitted.emit(editingSubscription);
      }else
      {
        this.showRadioError=true;
      }
  }

  getEditingSubscription(subscription: AutoShipTemplate): EditingSubscription {

    const entry = subscription && subscription.cart.entries.length ?
      subscription.cart.entries[0] : null;

    const editingSubscription: EditingSubscription = {
      autoShipCode: subscription.autoShipCode,
      subscriptionOrderStatus: subscription.recurringOrderStatus,
      product: {
        code: entry.product.code
      },
      nutritionPlanId: entry.subscriptionUnit.nutritionPlanId,
      nextDeliveryDate: DateUtil.getDateStr(subscription.nextScheduledDeliveryDate, 'MM/dd/yyyy'),
      quantity: '' + entry.quantity,
      deliveryFrequency: entry.subscriptionUnit.deliveryFrequency,
      duration: entry.subscriptionUnit.duration,
    };

    return editingSubscription;
  }

  // To get the Orders
  getOrders() {
    this.router.navigate(['/orders']);
  }

  openShipNowPopup() {
    setTimeout(() => {
      this.shipNowModalRef = this.modalService.open(this.shipNowModal);
    }, 100);
  }

  confirmShipNowSubscription(subscription: AutoShipTemplate) {
    const editingSubscription: EditingSubscription = {
      ...this.getEditingSubscription(subscription),
      subscriptionOrderStatus: OrderStatuses.Active,
      shipNowFlag: true
    };

    this.submitted.emit(editingSubscription);
    this.closeShipNowPopup();
    this.router.navigate(['subscriptions']);
  }
  
  closeShipNowPopup() {
    if(this.shipNowModalRef) {
      this.shipNowModalRef.close();
    }
  }
  
}

<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout p-3 px-4 custom-font">
      <!-- <h5 class="app-title text-center m-4">
      My Account
    </h5> -->

    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center my-3">
        <h3 class="mb-0">{{address?.id ? 'Edit' : 'Add'}} Address</h3>
      </div>
      <form [formGroup]="profileForm" class="flex-grow-1 d-flex justify-content-end ms-3 mt-3 pb-3" novalidate *ngIf="!fromCart">
        <div class="btn-group-toggle">
          <label class="changeState btn btn-outline-primary btn-sm ps-2"
                 [class.active]="profileForm.get('defaultAddress').value" ngbButtonLabel for="defaultAddress">
            <input type="checkbox" class="btn-check" id="defaultAddress" formControlName="defaultAddress" ngbButton> Primary
          </label>
        </div>
      </form>
    </div> 

      <form [formGroup]="profileForm" class="edit-form" autocomplete="off" (ngSubmit)="save()">

        <div class="form-group">
          <label for="fullName" class="small sr-only">Full Name</label>
          <input id="fullName" name="fullName" type="text" class="form-control" [value]="fullName" readonly >
        </div>

        <div class="form-group"
          [class.is-invalid]="profileForm.controls['line1'].dirty && profileForm.controls['line1'].invalid">
          <label for="firstLine" class="small sr-only">Address</label>
          <input id="firstLine" name="firstLine" type="text" class="form-control" placeholder="Street address*"
            formControlName="line1" maxlength="35">
        </div>
        <ul
          *ngIf="profileForm.controls['line1'].errors && profileForm.controls['line1'].errors.poBox && profileForm.controls['line1'].dirty"
          class="list-unstyled app-invalid-feedback small px-2">
          <li *ngIf="profileForm.controls['line1'].errors.poBox">
            Error: Hill's to home does not ship to PO Boxes
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>
        <div class="form-group"
          [class.is-invalid]="profileForm.controls['line2'].dirty && profileForm.controls['line2'].invalid">
          <label for="secondLine" class="small sr-only">Address</label>
          <input id="secondLine" name="secondLine" type="text" class="form-control" placeholder="Apt/Suite/Other"
            formControlName="line2" maxlength="35">
        </div>
        <ul
          *ngIf="profileForm.controls['line2'].errors && profileForm.controls['line2'].errors.poBox && profileForm.controls['line2'].dirty"
          class="list-unstyled app-invalid-feedback small px-2">
          <li *ngIf="profileForm.controls['line2'].errors.poBox">
            Error: Hill's to home does not ship to PO Boxes
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>

        <div class="form-group"
          [class.is-invalid]="profileForm.controls['town'].dirty && profileForm.controls['town'].invalid">
          <label for="city" class="small sr-only">City</label>
          <input id="city" name="city" type="text" class="form-control" placeholder="City*" formControlName="town">
        </div>
        <div class="form-group"
          [class.is-invalid]="profileForm.controls['region'].dirty && profileForm.controls['region'].invalid && profileForm.controls['region'].errors && profileForm.controls['region'].errors.defaultVal">
          <!-- <label for="state" class="small">State</label> -->
          <select id="state" name="state" class="form-control custom-select" formControlName="region">
            <option *ngFor="let state of states" [value]="state.isocode">{{state.name}}</option>
          </select>
        </div>
        <div class="form-group"
          [class.is-invalid]="profileForm.controls['postalCode'].dirty && profileForm.controls['postalCode'].invalid">
          <label for=zipCode class="small sr-only">Zip Code</label>
          <input id="zipCode" name="zipCode" type="text" pattern="[0-9]*" class="form-control" placeholder="Zip Code*"
            formControlName="postalCode" maxlength="5">
        </div>
        <ul *ngIf="profileForm.controls['postalCode'].dirty &&
                 profileForm.controls['postalCode'].errors &&
                 profileForm.controls['postalCode'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small px-2">
          <li *ngIf="profileForm.controls['postalCode'].errors['pattern']">
            Zip Code not valid
          </li>
          <i class="app-bottom-arrow"></i>
        </ul>

        <!-- <div class="form-group"
          [class.is-invalid]="profileForm.controls['phoneNumber'].dirty && profileForm.controls['phoneNumber'].invalid">
          <input id="phoneNumber" name="phoneNumber" type="text" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            class="form-control" placeholder="Phone Number*" formControlName="phoneNumber" (keyup)="onKey($event)">
        </div>
        <ul *ngIf="profileForm.controls['phoneNumber'].dirty &&
                       profileForm.controls['phoneNumber'].errors &&
                       profileForm.controls['phoneNumber'].errors['pattern']"
          class="list-unstyled app-invalid-feedback small">
          <li *ngIf="profileForm.controls['phoneNumber'].errors['pattern']">
            Phone Number should be 123-123-1234 format
          </li>
          <i class="app-bottom-arrow"></i>
        </ul> -->

        <div class="mt-3">
          All fields marked with an asterisk (*) are required.
        </div>
        <div class="mt-3">
          <button type="submit" class="btn w-100 btn-blue py-3 w500"
            [disabled]="profileForm.pristine || profileForm.invalid || profileForm.disabled">
            SAVE
          </button>
        </div>
        <div class="mt-3" *ngIf="address?.id">
          <button type="button" class="btn btn-secondary app-outline w-100" (click)="profileAddresses?.addresses.length>1 ? confirm(confirmModal) : confirm(alertAddressDeleteModal)">
            DELETE
          </button>
        </div>
      </form>
    </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font" *ngIf="!profileAddressChangeError">
    <form [formGroup]="profileForm" autocomplete="off" class="mt-1 mb-n3 edit-form" (ngSubmit)="save()" (keyup.enter)="save()">

      <div class="form-group">
        <label for="fullName" class="sr-only label-small">Full Na</label>
        <input id="fullName" name="fullName" type="text" class="form-control border-1" [value]="fullName" readonly>
      </div>


      <div class="form-group"
        [class.is-invalid]="profileForm.controls['line1'].dirty && profileForm.controls['line1'].invalid">
        <label for="firstLine" class="label-small sr-only">Street Address</label>
        <input id="firstLine" name="firstLine" type="text" class="form-control border-2" placeholder="Street address*"
          formControlName="line1" maxlength="35">
      </div>
      <ul
        *ngIf="profileForm.controls['line1'].errors && profileForm.controls['line1'].errors.poBox && profileForm.controls['line1'].dirty"
        class="list-unstyled app-invalid-feedback alternate small px-2">
        <li *ngIf="profileForm.controls['line1'].errors.poBox">
          Error: Hill's to home does not ship to PO Boxes
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group"
        [class.is-invalid]="profileForm.controls['line2'].dirty && profileForm.controls['line2'].invalid">
        <!-- <label for="secondLine" class="small">Address</label> -->
        <label for="secondLine" class="small sr-only">Apt/Suite/Other</label>
        <input id="secondLine" name="secondLine" type="text" class="form-control" placeholder="Apt/Suite/Other"
          formControlName="line2" maxlength="35">
      </div>
      <ul
        *ngIf="profileForm.controls['line2'].errors && profileForm.controls['line2'].errors.poBox && profileForm.controls['line2'].dirty"
        class="list-unstyled app-invalid-feedback alternate small px-2">
        <li *ngIf="profileForm.controls['line2'].errors.poBox">
          Error: Hill's to home does not ship to PO Boxes
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="form-group"
        [class.is-invalid]="profileForm.controls['postalCode'].dirty && profileForm.controls['postalCode'].invalid">
        <label for="zipCode" class="small sr-only">Zip Code</label>
        <input id="zipCode" name="zipCode" type="text" pattern="[0-9]*" class="form-control" placeholder="Zip Code*"
          formControlName="postalCode" maxlength="5">
      </div>

      <!-- <div class="form-group"
        [class.is-invalid]="profileForm.controls['phoneNumber'].dirty && profileForm.controls['phoneNumber'].invalid">
        <input id="phoneNumber" name="phoneNumber" type="tel" class="form-control" placeholder="Phone Number*"
          formControlName="phoneNumber" (keyup)="onKey($event)">
      </div>
      <ul *ngIf="profileForm.controls['phoneNumber'].dirty &&
                           profileForm.controls['phoneNumber'].errors &&
                           profileForm.controls['phoneNumber'].errors['pattern']"
        class="list-unstyled app-invalid-feedback small">
        <li *ngIf="profileForm.controls['phoneNumber'].errors['pattern']">
          Phone Number should be 123-123-1234 format
        </li>
        <i class="app-bottom-arrow"></i>
      </ul> -->


      <ul *ngIf="profileForm.controls['postalCode'].dirty &&
                 profileForm.controls['postalCode'].errors &&
                 profileForm.controls['postalCode'].errors['pattern']"
        class="list-unstyled app-invalid-feedback alternate small px-2">
        <li *ngIf="profileForm.controls['postalCode'].errors['pattern']">
          Zip Code not valid
        </li>
        <i class="app-bottom-arrow"></i>
      </ul>
      <div class="d-flex">
        <div class="form-group w-60"
          [class.is-invalid]="profileForm.controls['town'].dirty && profileForm.controls['town'].invalid">
          <label for="city" class="small sr-only">City</label>
          <input id="city" name="city" type="text" class="form-control" placeholder="City*" formControlName="town">
        </div>
        <div class="form-group w-40 ms-3"
          [class.is-invalid]="profileForm.controls['region'].dirty && profileForm.controls['region'].invalid && profileForm.controls['region'].errors && profileForm.controls['region'].errors.defaultVal">
          <label for="state" class="small sr-only">State</label>
          <select id="state" name="state" class="form-control custom-select" formControlName="region">
            <option *ngFor="let state of states" [value]="state.isocode">{{state.name}}</option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        All fields marked with an asterisk (*) are required.
      </div>
    </form>
  </div>
  <div class="app-full-layout" *ngIf="profileAddressChangeError">
    <h5>
      Your address
    </h5>
    <p class="w500">
      {{profileForm.value.line1}},
      <span *ngIf="profileForm.value.line2 != '' && profileForm.value.line2 != null">
        {{profileForm.value.line2}},
      </span>
      {{profileForm.value.postalCode}}, {{profileForm.value.town}}, {{profileForm.value.region | appRegion}}
    </p>
    <p class="w500" *ngIf="profileCorrectedAddress?.zipcode && profileCorrectedAddress?.city && profileCorrectedAddress?.state">
      Seems to be incorrect, did you mean :
    </p>
    <form [formGroup]="addressSuggestionform" autocomplete="off" class="mt-4" (ngSubmit)="saveSuggestion()">
      <div class="custom-control custom-radio mt-3 ps-0" *ngIf="profileCorrectedAddress?.zipcode && profileCorrectedAddress?.city && profileCorrectedAddress?.state; else invalidAddressSuggestionMobile">
        <input type="radio" id="keep_corrected_address" class="custom-control-input" name="AddressSuggestionRadio"
          formControlName="AddressSuggestionRadio" value="keep_corrected_address">
        <label class="custom-control-label w500 ps-4" for="keep_corrected_address">
          {{profileForm.value.line1}},
          <span *ngIf="profileForm.value.line2 != '' && profileForm.value.line2 != null">
            {{profileForm.value.line2}},
          </span>
          {{profileCorrectedAddress?.zipcode}}, {{profileCorrectedAddress?.city | titlecase}},
          {{profileCorrectedAddress?.state | appRegion}}
        </label>
      </div>
      <ng-template #invalidAddressSuggestionMobile>
        <p class="mt-2 text-danger">
          The address entered did not match a verified address.
        </p>
      </ng-template>
      <div class="custom-control custom-radio mt-3 ps-0">
        <input type="radio" id="keep_entered_address" name="AddressSuggestionRadio" class="custom-control-input"
          formControlName="AddressSuggestionRadio" value="keep_entered_address">
        <label class="custom-control-label w500 ps-4" for="keep_entered_address">
          No, keep the address entered.
        </label>
      </div>
      <div
        *ngIf="(addressSuggestionform.controls['AddressSuggestionRadio'].errors && addressSuggestionform.controls['AddressSuggestionRadio'].dirty) || showRadioError"
        class="alert alert-danger mt-4 small text-center">
        Please select at least one address.
      </div>
    </form>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully {{address?.id? 'changed' : 'added'}} your address
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #confirmModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body d-flex justify-content-end">
    <p class="m-4 h4 text-center">
      Are you sure you want to delete this address?
    </p>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="closeConfirm()">
      I'm Sure
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg" (click)="dismissConfirm()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #alertAddressDeleteModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1">
      <h4 class="modal-title text-center">Delete Address</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissConfirm()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body d-flex justify-content-end">
    <p class="m-4 h4 text-center">
      Please add a new address before removing this address.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg" (click)="dismissConfirm()" ngbAutofocus>
      Ok
    </button>
  </div>
</ng-template>

<ng-template #confirmAddressModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <h4 class="pt-2">
      Your address
    </h4>
    <p class="w500">
      {{profileForm.value.line1}},
      <span *ngIf="profileForm.value.line2 != '' && profileForm.value.line2 != null">
        {{profileForm.value.line2}},
      </span>
      {{profileForm.value.postalCode}}, {{profileForm.value.town}}, {{profileForm.value.region | appRegion}}
    </p>
    <p class="w500" *ngIf="profileCorrectedAddress?.zipcode && profileCorrectedAddress?.city && profileCorrectedAddress?.state">
      Seems to be incorrect, did you mean :
    </p>
    <form [formGroup]="addressSuggestionform" autocomplete="off" class="mt-4" (ngSubmit)="saveSuggestion()">
      <div class="custom-control custom-radio mt-3" *ngIf="profileCorrectedAddress?.zipcode && profileCorrectedAddress?.city && profileCorrectedAddress?.state; else invalidAddressSuggestion">
        <input type="radio" id="keep_corrected_address" class="custom-control-input" name="AddressSuggestionRadio"
          formControlName="AddressSuggestionRadio" value="keep_corrected_address">
        <label class="custom-control-label w500" for="keep_corrected_address">
          {{profileForm.value.line1}},
          <span *ngIf="profileForm.value.line2 != '' && profileForm.value.line2 != null">
            {{profileForm.value.line2}},
          </span>
          {{profileCorrectedAddress?.zipcode}}, {{profileCorrectedAddress?.city | titlecase}},
          {{profileCorrectedAddress?.state | appRegion}}
        </label>
      </div>
      <ng-template #invalidAddressSuggestion>
        <p class="mt-2 text-danger">
          The address entered did not match a verified address.
        </p>
      </ng-template>
      <div class="custom-control custom-radio mt-3">
        <input type="radio" id="keep_entered_address" name="AddressSuggestionRadio" class="custom-control-input"
          formControlName="AddressSuggestionRadio" value="keep_entered_address">
        <label class="custom-control-label w500" for="keep_entered_address">
          No, keep the address entered.
        </label>
      </div>
      <div
        *ngIf="(addressSuggestionform.controls['AddressSuggestionRadio'].errors && addressSuggestionform.controls['AddressSuggestionRadio'].dirty) || showRadioError"
        class="alert alert-danger mt-4 small text-center">
        Please select at least one address.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" (click)="save()">
      Save
    </button>
    <button type="button" class="btn btn-secondary ps-5 pe-5 btn-lg ms-2" data-dismiss="modal" (click)="dismissConfirm()" [routerLink]="['/profile']" >Cancel</button>
  </div>
</ng-template>
import { getWellnessPlan } from './../reducers/pets.reducer';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, take, tap, catchError } from 'rxjs/operators';
import * as merge from 'deepmerge';

import * as fromPets from '../reducers';
import * as fromAuth from '../../auth/reducers';
import {
  AddPet, AddPetSuccess,
  GetNutritionPlanDetail, GetNutritionPlanDetailSuccess,
  GetNutritionPlans, GetNutritionPlansSuccess,
  GetPetDetail, GetPetDetailSuccess,
  GetPets,
  GetPetsSuccess, PetsActionTypes, UpdatePet, UpdatePetSuccess, SetNutritionPlansFilter, GetWellnessPlanDetail, SetWhatsNewFlag, SetWhatsNewFlagSuccess,
  GetWhatsNewContent,GetWhatsNewContentSuccess, GetWhatsNewContentFailure
} from '../actions/pets.actions';
import {
  PetService, AllPets, Pet, AllNutritionPlans, NutritionPlan
} from '../../api';
import { Authenticate } from '../../auth/models/authenticate';
import { Noop } from 'src/app/core/actions/core.actions';

@Injectable()
export class PetsEffects {

  getAuthenticate = ((): Observable<Authenticate> =>
    this.store.pipe(
      select(fromAuth.getAuthenticate),
      take(1)
    ));

  getPets = ((): Observable<Pet[]> =>
    this.store.pipe(
      select(fromPets.getPets),
      take(1)
    ));

  getNutritionPlans = ((): Observable<NutritionPlan[]> =>
    this.store.pipe(
      select(fromPets.getNutritionPlans),
      take(1)
    ));

  getWellnessPlan = ((): Observable<NutritionPlan[]> =>
    this.store.pipe(
      select(fromPets.getWellnessPlan),
      take(1)
    ));

  
  getPets$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetPets>(PetsActionTypes.GetPets),
      switchMap((action: GetPets) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.petService.getPets(authenticate.username)
                .pipe(
                  map((allPets: AllPets) => allPets.pets && allPets.pets.length ? allPets.pets : []),
                  map((pets: Pet[]) => new GetPetsSuccess(pets))
                )
            )
          )
      )
    ));

  
  getPetDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetPetDetail>(PetsActionTypes.GetPetDetail),
      switchMap((action: GetPetDetail) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.petService.getPets(authenticate.username)
                .pipe(
                  map((allPets: AllPets) => allPets.pets && allPets.pets.length ? allPets.pets : []),
                  map((pets: Pet[]) => {
                    const pet = pets.find(p => p.petCode === action.id);
                    return new GetPetDetailSuccess(pet);
                  })
                )
            )
          )
      )
    ));

  
  getNutritionPlans$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetNutritionPlans>(PetsActionTypes.GetNutritionPlans),
      switchMap((action: GetNutritionPlans) =>
        this.petService.getNutritionPlans(action.petId)
          .pipe(
            map((allNutritionPlans: AllNutritionPlans) =>
              allNutritionPlans.nutritionPlans && allNutritionPlans.nutritionPlans.length ?
                allNutritionPlans.nutritionPlans : []),
            map((nutritionPlans: NutritionPlan[]) => new GetNutritionPlansSuccess(nutritionPlans))
          )
      )
    ));

  
  getNutritionPlanDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetNutritionPlanDetail>(PetsActionTypes.GetNutritionPlanDetail),
      switchMap((action: GetNutritionPlanDetail) =>
        this.getNutritionPlans()
          .pipe(
            map((nutritionPlans: NutritionPlan[]) => {
              const np = nutritionPlans.find(
                (nutritionPlan: NutritionPlan) => nutritionPlan.id === action.id);
              return new GetNutritionPlanDetailSuccess(np);
            })
          )
      )
    ));


  
  getWellnessPlanDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetWellnessPlanDetail>(PetsActionTypes.GetWellnessPlanDetail),
      switchMap((action: GetWellnessPlanDetail) =>
        this.getWellnessPlan()
          .pipe(
            map((nutritionPlans: NutritionPlan[]) => {
              const np = nutritionPlans[0];
              return new GetNutritionPlanDetailSuccess(np);
            })
          )
      )
    ));


  
  setNutritionPlanFilters$ = createEffect(() => this.actions$
    .pipe(
      ofType<SetNutritionPlansFilter>(PetsActionTypes.SetNutritionPlansFilter),
      switchMap((action: SetNutritionPlansFilter) =>
        this.getNutritionPlans()
          .pipe(
            map((nutritionPlans: NutritionPlan[]) => {
              let mergedPlans = {};
              action.id.forEach(id => {
                let np = nutritionPlans.find(
                  (nutritionPlan: NutritionPlan) => nutritionPlan.id === id);
                mergedPlans = merge(mergedPlans, np);

              });

              return new GetNutritionPlanDetailSuccess(mergedPlans);
            })
          )
      )
    ));

  // @Effect()
  // setWellnessPlanFilters$ = this.actions$
  //   .pipe(
  //     ofType<SetWellnessPlansFilter>(PetsActionTypes.SetWellnessPlanFilters),
  //     switchMap((action: SetNutritionPlansFilter) =>
  //       this.getNutritionPlans()
  //         .pipe(
  //           map((nutritionPlans: NutritionPlan[]) => {

  //             return new GetNutritionPlanDetailSuccess(mergedPlans);
  //           })
  //         )
  //     )
  //   );

  
  addPet$ = createEffect(() => this.actions$
    .pipe(
      ofType<AddPet>(PetsActionTypes.AddPet),
      switchMap((action: AddPet) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.petService.addPet(authenticate.username, action.pet)
                .pipe(
                  map(() => new AddPetSuccess())
                )
            )
          )
      )
    ));

  
  updatePet$ = createEffect(() => this.actions$
    .pipe(
      ofType<UpdatePet>(PetsActionTypes.UpdatePet),
      switchMap((action: UpdatePet) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.petService.updatePet(authenticate.username, action.pet)
                .pipe(
                  map(() => new UpdatePetSuccess())
                )
            )
          )
      )
    ));
  // Whats-new pop up with dynamic content -  
  
  setWhatsNewFlag$ = createEffect(() => this.actions$
    .pipe(
      ofType<SetWhatsNewFlag>(PetsActionTypes.SetWhatsNewFlag),
      switchMap((action: SetWhatsNewFlag) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.petService.setWhatsNewFlag(authenticate.username)
                .pipe(
                  map(() => new SetWhatsNewFlagSuccess())
                )
            )
          )
      )
    ));

  //handled getwhatsNewContent Failure -  
  
  getWhatsNewContent$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetWhatsNewContent>(PetsActionTypes.GetWhatsNewContent),
      switchMap((action: GetWhatsNewContent) =>
        this.getAuthenticate()
          .pipe(
            switchMap((authenticate: Authenticate) =>
              this.petService.getWhatsNewContent(authenticate.username)
                .pipe(
                  map((whatsNewContent) => new GetWhatsNewContentSuccess(whatsNewContent)),
                  catchError(error => of(new GetWhatsNewContentFailure(error)))
                )
            )
          )
      )
    ));

  
  getWhatsNewContentFailure$ = createEffect(() => this.actions$
    .pipe(
      ofType<GetWhatsNewContentFailure>(PetsActionTypes.GetWhatsNewContentFailure),
      map((action: GetWhatsNewContentFailure) => action.payload),
      map((payload: any) => {
        return new Noop();
      })
    ));
  // End
  // End
  constructor(private store: Store<fromPets.State>,
    private actions$: Actions,
    private router: Router,
    private petService: PetService) {
  }
}

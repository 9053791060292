//import { PetsActionsUnion } from './pets.actions';
import { Action } from '@ngrx/store';
import {
  AllWhatsNewContent,
  NutritionPlan,
  Pet
} from '../../api';

// Whats-new pop up with dynamic content -  
//handled getwhatsNewContent Failure -  
export enum PetsActionTypes {
  GetPets = '[Pets] Get Pets',
  GetPetsSuccess = '[Pets] Get Pets Success',
  GetPetDetail = '[Pets] Get Pet Detail',
  GetPetDetailSuccess = '[Pets] Get Pet Detail Success',
  LoadNutritionPlan = '[Pets] Load Nutrition Plan',
  NutritionPlanRedirect = '[Pets] Nutrition Plan Redirect',
  GetNutritionPlans = '[Pets] Get Nutrition Plans',
  GetNutritionPlansSuccess = '[Pets] Get Nutrition Plans Success',
  GetNutritionPlanDetail = '[Pets] Get Nutrition Plan Detail',
  GetWellnessPlanDetail = '[Pets] Get Wellness Plan Detail',
  GetNutritionPlanDetailSuccess = '[Pets] Get Nutrition Plan Detail Success',
  NewPet = '[Pets] New Pet',
  AddPet = '[Pets] Add Pet',
  AddPetSuccess = '[Pets] Add Pet Success',
  UpdatePet = '[Pets] Update Pet',
  UpdatePetSuccess = '[Pets] Update Pet Success',
  SetNutritionPlansFilter = '[Pets] Set Nutrition Plans Filter',
  SetWellnessPlanFilters = '[Pets] Set Wellness Plans Filter',
  SetSelectedPlans = '[Pets] Set Selected Plans',
  SetWhatsNewFlag = '[Pets] Set Whats New Flag',
  SetWhatsNewFlagSuccess = '[Pets] Set Whats New Flag Success',
  GetWhatsNewContent = '[Pets] Get Whats New Content',
  GetWhatsNewContentSuccess = '[Pets] Get Whats New Content Success',
  GetWhatsNewContentFailure = '[Pets] Get Whats New Content Failure',
  ResetWhatsNewFlag = '[Pets] Reset Whats New Flag to Default',         //Sets the flag to true - Don't show popup
}
// End
// End

export class GetPets implements Action {
  readonly type = PetsActionTypes.GetPets;

  constructor() {
  }
}

export class GetPetsSuccess implements Action {
  readonly type = PetsActionTypes.GetPetsSuccess;

  constructor(public pets: Pet[]) {
  }
}

export class GetPetDetail implements Action {
  readonly type = PetsActionTypes.GetPetDetail;

  constructor(public id: string) {
  }
}

export class GetPetDetailSuccess implements Action {
  readonly type = PetsActionTypes.GetPetDetailSuccess;

  constructor(public pet: Pet) {
  }
}

export class LoadNutritionPlan implements Action {
  readonly type = PetsActionTypes.LoadNutritionPlan;

  constructor(public nutritionPlan: NutritionPlan) {
  }
}

export class NutritionPlanRedirect implements Action {
  readonly type = PetsActionTypes.NutritionPlanRedirect;

  constructor() {
  }
}

export class GetNutritionPlans implements Action {
  readonly type = PetsActionTypes.GetNutritionPlans;

  constructor(public petId: string) {
  }
}

export class GetNutritionPlansSuccess implements Action {
  readonly type = PetsActionTypes.GetNutritionPlansSuccess;

  constructor(public nutritionPlans: NutritionPlan[]) {
  }
}

export class GetNutritionPlanDetail implements Action {
  readonly type = PetsActionTypes.GetNutritionPlanDetail;

  constructor(public id: string) {
  }
}

export class GetWellnessPlanDetail implements Action {
  readonly type = PetsActionTypes.GetWellnessPlanDetail;

  constructor() {
  }
}

export class SetNutritionPlansFilter implements Action {
  readonly type = PetsActionTypes.SetNutritionPlansFilter;

  constructor(public id: string[]) {
  }
}

// export class SetWellnessPlansFilter implements Action {
//   readonly type = PetsActionTypes.SetWellnessPlanFilters;

//   constructor(public id: string[]) {
//   }
// }

export class GetNutritionPlanDetailSuccess implements Action {
  readonly type = PetsActionTypes.GetNutritionPlanDetailSuccess;

  constructor(public nutritionPlan: NutritionPlan) {
  }
}


export class NewPet implements Action {
  readonly type = PetsActionTypes.NewPet;

  constructor() {
  }
}

export class AddPet implements Action {
  readonly type = PetsActionTypes.AddPet;

  constructor(public pet: Pet) {
  }
}

export class AddPetSuccess implements Action {
  readonly type = PetsActionTypes.AddPetSuccess;

  constructor() {
  }
}

export class UpdatePet implements Action {
  readonly type = PetsActionTypes.UpdatePet;

  constructor(public pet: Pet) {
  }
}

export class UpdatePetSuccess implements Action {
  readonly type = PetsActionTypes.UpdatePetSuccess;

  constructor() {
  }
}

export class SetSelectedPlans implements Action {
  readonly type = PetsActionTypes.SetSelectedPlans;

  constructor(public selectedPlansId: string[]) { }
}

// Whats-new pop up with dynamic content -  
export class SetWhatsNewFlag implements Action {
  readonly type = PetsActionTypes.SetWhatsNewFlag;
  constructor() {
  }
}

export class SetWhatsNewFlagSuccess implements Action {
  readonly type = PetsActionTypes.SetWhatsNewFlagSuccess;

  constructor() {
  }
}

export class GetWhatsNewContent implements Action {
  readonly type = PetsActionTypes.GetWhatsNewContent;
  constructor() {
  }
}

export class GetWhatsNewContentSuccess implements Action {
  readonly type = PetsActionTypes.GetWhatsNewContentSuccess;

  constructor(public whatsNewContentPayload: AllWhatsNewContent) {
  }
}

//handled getwhatsNewContent Failure -  
export class GetWhatsNewContentFailure implements Action {
  readonly type = PetsActionTypes.GetWhatsNewContentFailure;

  constructor(public payload: any) {
  }
}
// End
export class ResetWhatsNewFlag implements Action {
  readonly type = PetsActionTypes.ResetWhatsNewFlag;
  constructor() {
  }
}

//handled getwhatsNewContent Failure -  
export type PetsActionsUnion =
  | GetPets
  | GetPetsSuccess
  | GetPetDetail
  | GetPetDetailSuccess
  | LoadNutritionPlan
  | NutritionPlanRedirect
  | GetNutritionPlans
  | GetNutritionPlansSuccess
  | GetNutritionPlanDetail
  | GetNutritionPlanDetailSuccess
  | NewPet
  | AddPet
  | AddPetSuccess
  | UpdatePet
  | UpdatePetSuccess
  | GetWellnessPlanDetail
  | SetSelectedPlans
  | SetWhatsNewFlag
  | SetWhatsNewFlagSuccess
  | GetWhatsNewContent
  | GetWhatsNewContentSuccess
  | GetWhatsNewContentFailure
  | ResetWhatsNewFlag
  ;
// End
// End

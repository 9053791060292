<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>
<ng-template #mobileLayout>
  <div class="app-mobile-layout flex-column">
    <div class="header-banner">
      <div *ngIf="!isLoggedIn">
        <div class="container d-flex align-items-start pt-4">
          <div class="home-logo">
            <img src="assets/images/060118_HillsToHomeLogo.png" (click)="gotoLogin()">
          </div>
          <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
            <span class="w700">Sign In</span>
            <i class="fas fa-user ps-2"></i>
          </button>
          <!-- <button class="visit-btn mt-3" (click)="openUrl()">
            <span class="w700">VISIT HILLSPET.COM</span>
            <i class="fa fa-external-link-square-alt ps-2"></i>
          </button> -->
        </div>
      </div>
      <!-- Contact Us page copy - text change -   -->
      <div class="mt-5 ms-3 ps-2">
        <h3>Contact us</h3>
        Questions, feedback - we are here for it all
      </div>
      <!-- End -->
    <form [formGroup]="contactUsForm" (ngSubmit)="submitContactUs()" autocomplete="off" class="m-4">
      <div class="pt-2 pb-3"><h5>Email Us</h5>If you would prefer to Email us, kindly fill out form below and we will reply as soon as possible</div>
      <div class="form-group" [class.is-invalid]="contactUsForm.controls['fullName'] && contactUsForm.controls['fullName'].invalid">
        <label for="fullName" class="small required">Full Name</label>
        <input id="fullName" name="fullName" type="text" class="form-control" placeholder="Full Name" formControlName="fullName">
      </div>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.fullName.errors?.required" class="errorMessage">Full Name is required</span>
      </p>
      <div class="form-group" [class.is-invalid]="contactUsForm.controls['emailAddress'].dirty && contactUsForm.controls['emailAddress'].invalid && contactUsForm.controls['emailAddress'].errors['pattern']">
        <label for="emailAddress" class="small required">Email Address</label>
        <input id="emailAddress" name="emailAddress" type="email" class="form-control" placeholder="Email" formControlName="emailAddress">
      </div>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.emailAddress.errors?.required" class="errorMessage">Email is required</span>
      </p>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.emailAddress.errors?.pattern" class="errorMessage">Email is invalid</span>
      </p>
      <div class="form-group"
            [class.is-invalid]="contactUsForm.controls['topic'].dirty && contactUsForm.controls['topic'].invalid && contactUsForm.controls['topic'].errors && contactUsForm.controls['topic'].errors.defaultVal">
        <label for="topic" class="small required">Topic</label>
        <select id="topic"
                name="topic"
                class="form-control custom-select"
                formControlName="topic" (change)="onTopicChanged($event.target.value)">
          <option *ngFor="let support of supportList" [value]="support.code">{{support.name}}</option>
        </select>
      </div>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.topic.errors?.defaultVal" class="errorMessage">Topic is required</span>
      </p>
      <div class="form-group"
            [class.is-invalid]="contactUsForm.controls['subTopic'].dirty && contactUsForm.controls['subTopic'].invalid && contactUsForm.controls['subTopic'].errors && contactUsForm.controls['subTopic'].errors.defaultVal">
        <label for="subTopic" class="small required">Sub Topic</label>
        <select id="subTopic"
                name="subTopic"
                class="form-control custom-select"
                formControlName="subTopic">
          <option *ngFor="let item of subTopicList" [value]="item.code">{{item.name}}</option>
        </select>
      </div>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.subTopic.errors?.defaultVal" class="errorMessage">Sub Topic is required</span>
      </p>
      <div class="form-group"
            [class.is-invalid]="contactUsForm.controls['question'].dirty && contactUsForm.controls['question'].invalid && contactUsForm.controls['question'].errors && contactUsForm.controls['question'].errors.defaultVal">
        <label for="question" class="small required">Questions</label>
        <span>
          <textarea formControlName="question" class="form-control rounded-0" id="comments-{{i}}" maxlength="4000" rows="2" placeholder="Add a Comment"></textarea>
        </span>
      </div>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.question.errors?.required" class="errorMessage">Question is required</span>
      </p>
      <div class="google-captcha">
        <re-captcha
          name="captcha"
          required
          siteKey="{{siteKey}}"
          formControlName="recaptchaReactive"
          (resolved)="resolved($event)"
          (captchaExpired)="handleCaptchaExpiry()">
        </re-captcha>
      </div>
      <p>
        <span *ngIf="showErrorMessage && contactUsForm.controls.recaptchaReactive.errors?.required" class="errorMessage">Captcha Verification is required</span>
      </p>
    </form>
    <div *ngIf="errorMessage" class="alert alert-danger mt-4 small text-center">
      {{errorMessage}}
    </div>
    <div class="text-center">
    <button type="button" class="btn btn-primary w-80" (click)="submitContactUs()">
      Send us a message
    </button>
  </div>
    <div class="p-4 me-4 mt-5 mb-4 form-border"><h4>Call Us</h4>If you prefer to speak to someone,<br>give us a call<br><br><a href="tel:+1-800-235-6877"
      target="_top"
      class="text-underline">
      <span class="text-underline">1-800-235-6877</span>
     </a><br>Monday-Friday 7am - 7pm CST
    </div>
    <!-- End -->
  </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout custom-font">
    <div class="header-banner">
      <div *ngIf="!isLoggedIn">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo" (click)="gotoLogin()">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
        
          <button class="visit-btn mt-3 me-4" (click)="gotoLogin()">
            <span class="w700">Sign In</span>
            <i class="fas fa-user ps-2"></i>
          </button>
          <!-- <button class="visit-btn mt-3" (click)="openUrl()">
            <span class="w700">VISIT HILLSPET.COM</span>
            <i class="fa fa-external-link-square-alt ps-2"></i>
          </button> -->
        </div>
      </div>
    <div class="container pt-3 pb-6">
      <!-- Contact Us page copy - text change -   -->
      <div class="intro-content text-nowrap font-weight-light"><h2>Contact US</h2><span>Questions, feedback - we are here for it all</span></div>
      <!-- End -->
      <div class="d-flex">
        <form [formGroup]="contactUsForm" autocomplete="off" (ngSubmit)="submitContactUs()" class="pt-2 w-40">
          <div class="pt-4 pb-3"><h4>Email Us</h4>If you would prefer to Email us, kindly fill out form below and we will reply as soon as possible</div>
          <div class="form-group" [class.is-invalid]="contactUsForm.controls['fullName'] && contactUsForm.controls['fullName'].invalid">
            <label for="fullName" class="small required">Full Name</label>
            <input id="fullName" name="fullName" type="text" class="form-control" placeholder="Full Name" formControlName="fullName">
          </div>
          <p>
            <span *ngIf="showErrorMessage && contactUsForm.controls.fullName.errors?.required" class="errorMessage">Full Name is required</span>
          </p>
          <div class="form-group" [class.is-invalid]="contactUsForm.controls['emailAddress'].dirty && contactUsForm.controls['emailAddress'].invalid && contactUsForm.controls['emailAddress'].errors['pattern']">
            <label for="emailAddress" class="small required">Email Address</label>
            <input id="emailAddress" name="emailAddress" type="email" class="form-control" placeholder="Email" formControlName="emailAddress">
          </div>
          <p>
            <span *ngIf="showErrorMessage && contactUsForm.controls.emailAddress.errors?.required" class="errorMessage">Email is required</span>
          </p>
          <p>
            <span *ngIf="showErrorMessage && contactUsForm.controls.emailAddress.errors?.pattern" class="errorMessage">Email is invalid</span>
          </p>
          <div class="form-group"
                [class.is-invalid]="contactUsForm.controls['topic'].dirty && contactUsForm.controls['topic'].invalid && contactUsForm.controls['topic'].errors && contactUsForm.controls['topic'].errors.defaultVal">
            <label for="topic" class="small required">Topic</label>
            <select id="topic"
                    name="topic"
                    class="form-control custom-select"
                    formControlName="topic" (change)="onTopicChanged($event.target.value)">
              <option *ngFor="let support of supportList" [value]="support.code">{{support.name}}</option>
            </select>
          </div>
          <p>
            <span *ngIf="showErrorMessage && contactUsForm.controls.topic.errors?.defaultVal" class="errorMessage">Topic is required</span>
          </p>
          <div class="form-group"
                [class.is-invalid]="contactUsForm.controls['subTopic'].dirty && contactUsForm.controls['subTopic'].invalid && contactUsForm.controls['subTopic'].errors && contactUsForm.controls['subTopic'].errors.defaultVal">
            <label for="subTopic" class="small required">Sub Topic</label>
            <select id="subTopic"
                    name="subTopic"
                    class="form-control custom-select"
                    formControlName="subTopic">
              <option *ngFor="let item of subTopicList" [value]="item.code">{{item.name}}</option>
            </select>
          </div>
          <p>
            <span *ngIf="showErrorMessage && contactUsForm.controls.subTopic.errors?.defaultVal" class="errorMessage">Sub Topic is required</span>
          </p>
          <div class="form-group"
                [class.is-invalid]="contactUsForm.controls['question'].dirty && contactUsForm.controls['question'].invalid && contactUsForm.controls['question'].errors && contactUsForm.controls['question'].errors.defaultVal">
            <label for="question" class="small required">Questions</label>
            <span>
              <textarea formControlName="question" class="form-control rounded-0" maxlength="4000" id="comments-{{i}}" rows="2" placeholder="Add a Comment"></textarea>
            </span>
          </div>
          <p>
            <span *ngIf="showErrorMessage && contactUsForm.controls.question.errors?.required" class="errorMessage">Question is required</span>
          </p>
          <div class="google-captcha">
            <re-captcha
            name="captcha"
            siteKey="{{siteKey}}"
            formControlName="recaptchaReactive"
            (resolved)="resolved($event)"
            (captchaExpired)="handleCaptchaExpiry()">
            </re-captcha>
          </div>
            <p>
              <span *ngIf="showErrorMessage && contactUsForm.controls.recaptchaReactive.errors?.required" class="errorMessage">Captcha Verification is required</span>
            </p>
        </form>
       <!-- Contact Us page copy - text change -   -->
       <div class="pt-4 ps-3 ms-6 pe-5 form-border"><h4>Call Us</h4>If you prefer to speak to someone,<br>give us a call<br><br><a href="tel:+1-800-235-6877"
          target="_top"
          class="text-underline">
         <span class="text-underline">1-800-235-6877</span>
         </a><br>Monday-Friday 7am - 7pm CST</div>
       </div>
       <!-- End -->
      <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg me-4 mt-2 mb-4" (click)="submitContactUs()">
          Send us a message
        </button>
    </div>
    </div>
  </div>
</ng-template>
<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout bg-white">
    <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="search(); hideFilterPopup();" class="p-3 custom-font">
      <h3 class="w700 d-flex align-items-center">
        <span>Filter <em>({{filtersCount}})</em></span>
        <a (click)="search(); hideFilterPopup();" class="fa fa-times text-decoration"></a>
      </h3>
      <div class="app-scrollable filters">
        <div class="mt-3 mb-4">
          <div ngbAccordion [closeOthers]="true">
            <div *ngFor="let facet of displayFacets; let i = index" ngbAccordionItem [collapsed]="!isPanelOpen(i)">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton (click)="togglePanel(i)">
                  <div [ngClass]="{'panel-open': isPanelOpen(i)}" class="panel-title-container">
                    <h6>{{ facet?.name === 'Food Type' ? 'Product Form' : facet?.name }}</h6>
                    <i [ngClass]="isPanelOpen(i) ? 'fa fa-minus' : 'fa fa-plus'"></i>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <div class="card-body">
                      <div [formArrayName]="formatFacet(facet)">
                        <div *ngFor="let facetValue of facet?.values; let j = index">
                          <ng-container *ngIf="facetValue">
                            <div class="d-flex">
                              <div class="filter-item">
                                <input type="checkbox" [id]="'facet_' + i + '_' + j" [value]="facetValue.selected"
                                  (click)="preventSelect($event, facet, facetValue); updateFilters($event, facet, facetValue);"
                                  [checked]="updateChecked(facet, facetValue.name, facetValue)">
                                <label [for]="'facet_' + i + '_' + j">
                                  {{ facetValue.name | facetValueNameMap }}
                                </label>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-blue w-100 mt-4">
        Apply
      </button>
    </form>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <form [formGroup]="shopForm" autocomplete="off" (ngSubmit)="search()" class="me-3 pt-3 mb-4">
      <h5 class="filter-title pb-4">Filter <em>({{filtersApplied.length}})</em></h5>
      <div class="app-scrollable filters">
        <div class="mt-3 mb-4">
          <div ngbAccordion [closeOthers]="true">
            <div *ngFor="let facet of displayFacets; let i = index" ngbAccordionItem [collapsed]="!isPanelOpen(i)"
              class="np-filter-bg">
              <h2 ngbAccordionHeader class="np-filter-bg">
                <button ngbAccordionButton (click)="togglePanel(i)">
                  <div [ngClass]="{'panel-open': isPanelOpen(i)}"
                    class="np-filter-bg panel-title-container accordion-body">
                    <h6 class="panel-title">{{ facet?.name === 'Food Type' ? 'Product Form' : facet?.name }}</h6>
                    <i [ngClass]="isPanelOpen(i) ? 'fa fa-minus' : 'fa fa-plus'"></i>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>

                  <ng-template>
                    <div [formArrayName]="formatFacet(facet)" *ngFor="let facetValue of facet?.values; let j = index">
                      <ng-container *ngIf="facetValue">
                        <div class="d-flex">
                          <div class="filter-item">
                            <input type="checkbox" [id]="'facet_' + i + '_' + j" [value]="facetValue.selected"
                              (click)="preventSelect($event, facet, facetValue); updateFilters($event, facet, facetValue); search();"
                              [checked]="updateChecked(facet, facetValue.name, facetValue)">
                            <label [for]="'facet_' + i + '_' + j" [innerHTML]="formatContent(facetValue.name)">
                              {{ facetValue.name | facetValueNameMap }}
                            </label>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as fromAuth from "../../reducers";
import * as fromCore from "../../../core/reducers";
import * as AuthActions from "../../actions/auth.actions";
import { Authenticate } from "../../models/authenticate";

// Invisible V3 captcha for login -  
@Component({
  selector: "app-login-page",
  template: `
    <app-login
      [pending]="pending$ | async"
      [errorMessage]="error$ | async"
      [isMobile]="isMobile$ | async"
      [isTokenExpired]="isTokenExpired$ | async"
      [isMaintenanceMode]="isMaintenanceMode$ | async"
      (resolvedEvent)="onResolve($event)"
      (submitted)="onSubmit($event)">
    </app-login>
  `,
  styles: []
})
// End
export class LoginPageComponent implements OnInit, OnDestroy {
  isLoggedIn$ = this.store.pipe(select(fromAuth.getIsLoggedIn));

  isTokenExpired$ = this.store.pipe(select(fromAuth.getIsTokenExpired));

  pending$ = this.store.pipe(select(fromAuth.getLoginPending));

  error$ = this.store.pipe(select(fromAuth.getLoginError));

  isMobile$ = this.store.pipe(select(fromCore.getIsMobile));

  isMaintenanceMode$ = this.store.pipe(select(fromAuth.getIsMaintenanceMode));

  subscriptions: Subscription[] = [];

  constructor(private store: Store<fromAuth.State>) {}

  ngOnInit() {
    this.store.dispatch(new AuthActions.GetCSRFToken());
    this.subscriptions.push(
      this.isLoggedIn$
        .pipe(
          tap((isLoggedIn: boolean) => {
            if (isLoggedIn) {
              this.store.dispatch(new AuthActions.LoggedRedirect());
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // Invisible V3 captcha for login -  
  onResolve(token: string){
    this.store.dispatch(new AuthActions.GetSiteVerifyCaptcha(token));
  }
  // End
  onSubmit(authenticate: Authenticate) {
    this.store.dispatch(new AuthActions.Login(authenticate));
  }
}

import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromPets from './pets.reducer';

export interface PetsState {
  status: fromPets.State;
}

export interface State extends fromRoot.State {
  pets: PetsState;
}

export const reducers: ActionReducerMap<PetsState> = {
  status: fromPets.reducer,
};

export const getPetsState = createFeatureSelector<PetsState>('pets');

// Pets Status

export const getPetsStatusState = createSelector(
  getPetsState,
  (state: PetsState) => state.status
);

export const getPets = createSelector(
  getPetsStatusState,
  fromPets.getPets
);

export const getPetDetail = createSelector(
  getPetsStatusState,
  fromPets.getPetDetail
);

export const getNutritionPlans = createSelector(
  getPetsStatusState,
  fromPets.getNutritionPlans
);

export const getInactiveNutritionPlans = createSelector(
  getPetsStatusState,
  fromPets.getInactiveNutritionPlans
);

export const getNutritionPlanDetail = createSelector(
  getPetsStatusState,
  fromPets.getNutritionPlanDetail
);

export const getPetChangePending = createSelector(
  getPetsStatusState,
  fromPets.getPetChangePending
);

export const getWellnessPlan = createSelector(
  getPetsStatusState,
  fromPets.getWellnessPlan
);

export const getSelectedPlans = createSelector(
  getPetsStatusState,
  fromPets.getSelectedPlans
);

// Whats-new pop up with dynamic content -  
export const getWhatsNewFlag = createSelector(
  getPetsStatusState,
  fromPets.getWhatsNewFlag
);

export const getWhatsNewContent = createSelector(
  getPetsStatusState,
  fromPets.getWhatsNewContent
);
// End
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Authenticate } from "../../models/authenticate";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Patterns } from "src/app/shared/validation/patterns";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnChanges {
  authForm: UntypedFormGroup;

  @Input()
  pending: boolean | false;

  @Input()
  errorMessage: string | null;

  @Input()
  isMobile: boolean | false;

  @Input()
  isTokenExpired: boolean | false;

  @Input()
  isMaintenanceMode: boolean | false;

  @Input()
  isVisible: boolean | false;

  @Output()
  submitted = new EventEmitter<Authenticate>();

  // Invisible V3 captcha for login -  
  @Output()
  resolvedEvent = new EventEmitter<String>();
  // End
  @ViewChild("resetPwdFailedModal", { static: true })
  resetPwdFailedModal: any;

  constructor(private fb: UntypedFormBuilder, private modalService: NgbModal) {
    this.createForm();
  }

  ngOnInit() {
    this.showPasswordResetDialog();
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes["pending"];
    if (pendingChange && !pendingChange.firstChange) {
      if (pendingChange.currentValue) {
        this.authForm.disable();
      } else {
        this.authForm.enable();
      }
    }
    
  }

  createForm() {
    this.authForm = this.fb.group({
      username: [
        "",
        [Validators.required, Validators.pattern(Patterns.emailPattern)],
      ],
      password: ["", Validators.required],
    });
  }
  // Invisible V3 captcha for login
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  errored() {
    console.warn(`reCAPTCHA error encountered`);
  }
  // End
  login() {
    if (this.authForm.valid) {
      const model = this.authForm.value;
      const authenticate: Authenticate = {
        username: model.username.toLowerCase(),
        password: model.password,
      };
      this.submitted.emit(authenticate);
    }
    // if (grecaptcha.render) {
    //   grecaptcha.execute();
    // }
  }

  showPasswordResetDialog() {
    // Using timeout because of issue here:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/1775
    setTimeout(() => {
      if (this.isTokenExpired) {
        this.modalService.open(this.resetPwdFailedModal, {
          centered: true,
          size: "sm",
        });
      }
    });
  }

  //Toggle Password Visibility

  toggleVisibility() {
    this.isVisible = !this.isVisible;
  }

  openUrl() {
    window.open("https://www.hillspet.com/", "_blank");
  }
}

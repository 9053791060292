<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>
 
<ng-template #mobileLayout>
 <div class="app-mobile-layout">
  <div class="header-banner">
    <div *ngIf="!isLoggedIn">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo">
          <img src="assets/images/060118_HillsToHomeLogo.png" (click)="gotoLogin()">
        </div>
        <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
          <span class="w700">Sign In</span>
          <i class="fa fa-user ps-2 fa-sm"></i>
        </button>
        <!-- <button class="visit-btn mt-3" (click)="openUrl()">
          <span class="w700">VISIT HILLSPET.COM</span>
          <i class="fa fa-external-link-square-alt ps-2"></i>
        </button> -->
      </div>
    </div>
  <div class="bg-white p-3">
    <div class="container pt-4">
      <div class="d-flex align-items-center mb-2">
        <h5 class="fw-normal m-0">
          <a class="text-sty" href="javascript:void(0)" (click)="goBack()">Featured Topics</a> > <a class="text-sty" href="javascript:void(0)">{{selectedFaqName}}</a>
        </h5>
      </div>
      <div class="d-flex h2 pt-3">
        {{selectedFaqName}}
      </div>
    </div>
    <div class="container pt-4">
        <!-- WhatsNew Support -   -->
        <app-faq-content [isMobile]="isMobile" [selectedFaq]="selectedFaq" [isLoggedIn]="isLoggedIn" [whatsNewContent]="whatsNewContent">
        </app-faq-content>
        <!-- End -->
    </div>
    <div class="container mb-5 pt-5">
      <div class="d-flex h2 pb-2">
          Still Need Help?  
      </div>
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-primary w-50" routerLink="/contact-us">
          Contact us
        </button>
      </div>
    </div>
  </div>
 </div>
 </div>
</ng-template>
 
<ng-template #fullLayout>
 <div class="app-full-layout">
  <div class="header-banner">
    <div *ngIf="!isLoggedIn">
      <div class="container d-flex align-items-start pt-4">
        <div class="home-logo" (click)="gotoLogin()">
          <img src="assets/images/060118_HillsToHomeLogo.png">
        </div>
          <button class="visit-btn mt-3 me-2" (click)="gotoLogin()">
            <span class="w700">Sign in</span>
            <i class="fa fa-user ps-2 fa-sm"></i>
          </button>
        </div>
      </div>
   <div class="bg-white">
     <div class="container pt-5">
       <div class="d-flex justify-content-between align-items-stretch">
         <div class="d-flex p-3 w-30">
         </div>
         <div class="d-flex flex-column pt-3 pb-3 pe-3 w-75">
           <h5 class="fw-normal m-0">
             <a href="javascript:void(0)" class="text-sty text-start" (click)="goBack()">Featured Topics</a> > <a href="javascript:void(0)" class="text-sty text-start">{{selectedFaqName}}</a>
           </h5>
           <div class="d-flex h2 pt-5">
             {{selectedFaqName}}
           </div>
         </div>
       </div>
       <div class="d-flex justify-content-between align-items-stretch">
         <div class="d-flex flex-column w-30 mb-6">
 
           <div class="h5 pb-1">FEATURED TOPICS:</div>
 
           <div class="d-flex" *ngFor="let support of supportList">
               <div class="h5 fw-normal pt-3 support-text" [ngClass]="{'selectedFaq' : (support.name == selectedFaqName)}"  (click)="goToSelectedFaq(support.code)">
                 {{support.name}}
               </div>
           </div>
 
           <div class="d-flex h3 pt-4 pb-2">
             Still Need Help? 
           </div>
           <div class="d-flex align-items-center">
             <button type="button" class="btn btn-primary btn-sm mb-5" routerLink="/contact-us">
               Contact us
             </button>
           </div>
         </div>
         <div class="d-flex flex-column desktop-faqs w-75 mb-6 pe-3">
          <!-- WhatsNew Support -   -->
          <app-faq-content [isMobile]="isMobile" [selectedFaq]="selectedFaq" [isLoggedIn]="isLoggedIn" [whatsNewContent]="whatsNewContent">
          </app-faq-content>
          <!-- End -->
         </div>
       </div>
     </div>
   </div>
  </div>
</div>
</ng-template>

import { PetsActionsUnion, PetsActionTypes } from '../actions/pets.actions';
import {
  NutritionPlan,
  Pet,
  WhatsNewContent,
} from '../../api';

// Whats-new pop up with dynamic content -  
export interface State {
  // pets
  pets: Pet[];
  petDetail: Pet;
  nutritionPlans: NutritionPlan[];
  inActiveNutritionPlans: NutritionPlan[];
  wellnessPlan: NutritionPlan[];
  nutritionPlanDetail: NutritionPlan;
  petChangePending: boolean;
  selectedPlans: any[];
  whatsNewFlag: boolean | true;
  whatsNewContent: WhatsNewContent[];
}
export const initialState: State = {
  pets: null,
  petDetail: null,
  nutritionPlans: null,
  inActiveNutritionPlans: null,
  nutritionPlanDetail: null,
  petChangePending: false,
  wellnessPlan: null,
  selectedPlans: null,
  whatsNewFlag : true,
  whatsNewContent: null,
};
// End

export function reducer(state = initialState, action: PetsActionsUnion): State {
  switch (action.type) {

    case PetsActionTypes.NewPet: {
      return {
        ...state,
        petDetail: null,
        nutritionPlans: null,
        petChangePending: false,
      };
    }

    case PetsActionTypes.GetPetsSuccess: {
      return {
        ...state,
        pets: action.pets,
      };
    }

    case PetsActionTypes.GetPetDetailSuccess: {
      return {
        ...state,
        petDetail: action.pet,
      };
    }

    case PetsActionTypes.LoadNutritionPlan: {
      return {
        ...state,
        nutritionPlanDetail: action.nutritionPlan,
      };
    }

    case PetsActionTypes.GetNutritionPlansSuccess: {
      return {
        ...state,
        nutritionPlans: action.nutritionPlans.filter(np => (np.status === 'ACTIVE')), // filter active nutrition plans
        inActiveNutritionPlans: action.nutritionPlans.filter(np => (np.status === 'ENDED')), // filter inactive nutrition plans
        wellnessPlan: action.nutritionPlans.filter(np => np.clinic.enableWellnessBrand)
      };
    }

    case PetsActionTypes.GetNutritionPlanDetailSuccess: {
      return {
        ...state,
        nutritionPlanDetail: action.nutritionPlan,
      };
    }

    case PetsActionTypes.AddPet:
    case PetsActionTypes.UpdatePet: {
      return {
        ...state,
        petChangePending: true,
      };
    }

    case PetsActionTypes.GetPetDetail:
    case PetsActionTypes.AddPetSuccess:
    case PetsActionTypes.UpdatePetSuccess: {
      return {
        ...state,
        petChangePending: false,
      };
    }

    case PetsActionTypes.SetSelectedPlans: {
      return {
        ...state,
        selectedPlans: action.selectedPlansId
      }
    }

    // Whats-new pop up with dynamic content -  
    case PetsActionTypes.SetWhatsNewFlagSuccess: {
      return {
        ...state,
        whatsNewFlag : true // flag here is always set to true - When Got it button is clicked
      }
    }

    case PetsActionTypes.GetWhatsNewContentSuccess: {
      return {
        ...state,
        whatsNewContent : action.whatsNewContentPayload.content,
        whatsNewFlag : action.whatsNewContentPayload.whatsNewFlagPP
      }
    }

    case PetsActionTypes.ResetWhatsNewFlag: {
      return {
        ...state,
        whatsNewFlag : true   //Reset Flag to true, after showing the popup - To avoid the issue of PreviousState on Logout
      }
    }
    
    // End
    default:
      return state;
  }
}

export const getPets = (state: State) => state.pets;
export const getPetDetail = (state: State) => state.petDetail;
export const getNutritionPlans = (state: State) => state.nutritionPlans;
export const getInactiveNutritionPlans = (state: State) => state.inActiveNutritionPlans;
export const getWellnessPlan = (state: State) => state.wellnessPlan;
export const getNutritionPlanDetail = (state: State) => state.nutritionPlanDetail;
export const getPetChangePending = (state: State) => state.petChangePending;
export const getSelectedPlans = (state: State) => state.selectedPlans;
// Whats-new pop up with dynamic content -  
export const getWhatsNewFlag = (state: State) => state.whatsNewFlag;
export const getWhatsNewContent = (state: State) => state.whatsNewContent;
// End
<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-order-summary app-mobile-layout pb-4 custom-font">

    <!-- <div class="d-flex card-promocode" *ngIf="order?.status === undefined">
          <form [formGroup]="promoForm" class="w-100">
            <div class="d-flex p-3 promo-field">
              <input class="form-control" type="text"  id="promoCode" name="promoCode" type="promoCode" placeholder="Promo Code" formControlName="promoCode"/>
              <button (click)="applyPromo()" [disabled]="!promoForm.valid">APPLY</button>
            </div>
            <div class="d-flex justify-content-between" *ngIf="order?.promoResponseCode !== '200' && order?.promoMessage">
              <div class="px-3 pb-3 w500">{{order?.promoMessage}}</div>
            </div>
            <div class="d-flex justify-content-between mt-3" *ngIf="order?.appliedVouchers !== undefined">
              <label class="form-control" style="background-color:transparent;color:white">Promo code "{{order?.appliedVouchers[0].code}}" is applied</label>
              <button class="btn font-weight-light w-100" (click)="removePromo()" style="color: white;text-decoration: underline;">Remove Promo</button>
            </div>
          </form>
        </div> -->

    <div class="d-flex card-promocode" *ngIf="order?.status === undefined">
      <form [formGroup]="promoForm" class="w-100">
        <div class="d-flex flex-column promo-field">
          <label class="w500" for="promoCode">Enter Promo Code</label>
          <div class="d-flex align-items-center">

            <input class="form-control" type="text" id="promoCode" name="promoCode" type="promoCode"
              placeholder="ILOVEPETS" formControlName="promoCode" />
            <button (click)="applyPromo()" [disabled]="!promoForm.valid">APPLY</button>
          </div>
        </div>
        <div class="d-flex justify-content-between" *ngIf="order?.promoResponseCode !== '200' && order?.promoMessage">
          <div class="pt-2 pb-0 w500 txt-critical promo-error">{{order?.promoMessage}}</div>
        </div>
        <div class="d-flex mt-3 applied-promo align-items-start" *ngIf="order?.appliedVouchers !== undefined">
          <label class="mb-0">Promo code "{{order?.appliedVouchers[0].code}}" is applied</label>
          <button class="btn btn-link" (click)="removePromo()">Remove Promo</button>
        </div>
        <!-- <div class="d-flex justify-content-between mt-3" *ngIf="order?.appliedVouchers !== undefined">
              <label class="form-control" style="background-color:transparent;color:white">Promo code "{{order?.appliedVouchers[0].code}}" is applied</label>
              <button class="btn font-weight-light w-100" (click)="removePromo()" style="color: white;text-decoration: underline;">Remove Promo</button>
            </div> -->
      </form>
    </div>

    <div class="d-flex flex-column summary-card py-4 mt-3">
      <div *ngIf="order?.sapOrderTotalValue === undefined"> 
      <h3 *ngIf="!isOrderCompletionPage" class="w800 order-summary-head px-2 mx-1 pb-3">
        Order Summary
      </h3>
      <div class="d-flex justify-content-between px-2 mx-1 w600 mb-1">
        <div>Subtotal</div>
        <div>
          ${{(order?.subTotal?.value + order?.productDiscounts?.value + order?.orderDiscounts?.value) | number: '.2-2'}}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3 px-2 mx-1 w600 mb-1 txt-critical">
        <div>Subscription Discount</div>
        <div>{{order?.productDiscounts?.value ? '-' : ''}}{{order?.productDiscounts?.formattedValue}}</div>
      </div>

      <!-- <div class="promo-summary d-flex justify-content-between mt-3 px-2 mx-1 w600 mb-1 align-items-center"
        *ngIf="order?.appliedVouchers !== undefined">
        <div>Promo Code</div>
        <div class="promo-card d-flex align-items-center mx-1">
          <span>{{order?.appliedVouchers[0].code}}</span>
          <a (click)="removePromo()" class="fa fa-times ps-2"></a>
        </div>
        <div class="promo-price d-flex justify-content-end">
          {{order?.orderDiscounts?.value ? '-' : ''}}{{order?.orderDiscounts?.formattedValue}}</div>
      </div> -->
      <div class="promo-summary d-flex justify-content-between mt-3 px-2 mx-1 w600 mb-1 align-items-start"
        *ngIf="isOrderCompletionPage === true || order?.appliedVouchers !== undefined">
        <div class="d-flex flex-wrap">
          <!-- Promotions discount info added   -->
          <div class="pe-2 mb-1" *ngIf="!isMyOrderPage">Promo Code</div>
          <div class="pe-2 mb-1" *ngIf="isMyOrderPage">Promotions Discount</div>
          <!-- END -->
          <!-- No promo_code in promotion info in order completion page   -->
          <div class="promo-card d-flex align-items-center me-2" *ngIf="order?.appliedVouchers !== undefined && !isOrderCompletionPage">
            <span>{{order?.appliedVouchers[0].code}}</span>
            <!--‘promo removing option 'X' ’ not shown if ordercompletionpage  -->
            <a (click)="removePromo()" class="fa fa-times ps-2"></a>
            <!--END-->
          </div>
          <!-- END -->
        </div>
        <div class="promo-price d-flex justify-content-end">
          {{order?.orderDiscounts?.value ? '-' : ''}}{{order?.orderDiscounts?.formattedValue}}
        </div>
      </div>
      <div class="d-flex justify-content-between mt-3 px-2 mx-1 w600 mb-1">
        <div>Shipping</div>
        <div>{{order?.deliveryCost?.formattedValue}}</div>
      </div>
      <div class="d-flex justify-content-between mt-3 px-2 mx-1 w600 mb-1"
        *ngIf="(order?.totalSurcharge?.value | number : 0) > 0">
        <div>Alaska/Hawaii Surcharge</div>
        <div>{{order?.totalSurcharge?.value | currency : fractionsize}}</div>
      </div>
      <div class="d-flex justify-content-between mt-3 px-2 mx-1 w600">
        <div>Estimated Tax</div>
        <div>{{order?.totalTax?.formattedValue}}</div>
      </div>
      <div class="d-flex justify-content-between mt-3 px-2 mx-1 w600" *ngIf="(order?.retailDeliveryFee?.value | number : 0) > 0">
        <div>State Delivery Fee**</div>
        <div>{{order?.retailDeliveryFee?.value | currency : fractionsize}}</div>
      </div>
     </div>
      <div class="d-flex justify-content-between order-total mt-3 mb-3 px-2 mx-1 w800">
        <div *ngIf= "order?.sapOrderTotalValue === undefined" content-type="template" placement="bottom" placement = "top" [autoClose]="false" [ngbTooltip]="estimatedTaxToolTip" data-container="body"> Estimated Order Total{{order?.sapOrderTotalValue ? '' : '*'}}</div>
        <div class="pe-2" *ngIf= "order?.sapOrderTotalValue !== undefined"> Order Total</div>
        <div>{{(order?.sapOrderTotalValue === undefined)? (order?.totalPrice?.formattedValue) : (order?.sapOrderTotalValue | currency : fractionsize)}}</div>
      </div>

      <div class="app-after-summary px-2 mx-1 text-center">
        <div class="pt-3" *ngIf="(order?.totalSurcharge?.value | number : 0) > 0">
          Free shipping excluding Alaska and Hawaii which will incur a surcharge of $1.00/item
        </div>
        <div *ngIf="(order?.totalSurcharge?.value | number : 0) > 0" class="pt-3">
          Please note that shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional
          business days to arrive.
        </div>
        <div class="pt-3" [ngClass]="{'pb-3':!isOrderCompletionPage}">
          Shipping rates and discounts are subject to change.
        </div>
        <div class="pt-1">
          *Save up to 35% on all Hill's products.
        </div>
        <div class="pt-3" *ngIf="(order?.retailDeliveryFee?.value | number : 0) > 0">
          **If your state has a retail delivery fee, this fee is non refundable.
        </div>
      </div>
      <div *ngIf="order?.subscriptionLongestDate" class="app-after-summary px-2 mx-1 pt-3 pb-2">
        <div class="text-center">
          By clicking <b>"Place Order"</b> below, you accept that
          <span *ngIf="order?.totalItems > 1 || order?.subscriptionLongestDate ==='UNLIMITED'">this automatic renewal
            will continue for the term selected unless cancelled</span>
          <span *ngIf="order?.totalItems == 1 && order?.subscriptionLongestDate !=='UNLIMITED'">this subscription will
            continue until {{order?.subscriptionLongestDate}} unless cancelled</span>
          on HillsToHome.com at least 2 days before the orders
          ships. Your card will be charged {{order?.totalPrice?.formattedValue}} today and the current selling prices
          at the time of future shipments.
        </div>
      </div>
      <div class="alert alert-danger small ms-3 me-3 mb-0" *ngIf="order?.isOutOFStockCartItems">
        One or more item in your cart is temporarily unavailable. Remove them from the cart, to proceed further.
      </div>
      <div class="mt-3 px-3" *ngIf="!isOrderCompletionPage">
        <button [disabled]="cartForm.invalid || order?.isOutOFStockCartItems" (click)="placeOrder()" class="btn btn-primary btn-lg w-100">
          PLACE ORDER
        </button>
      </div>

      <!-- <div class="px-3 pt-3">
          <button (click)="placeOrder()">
            PLACE ORDER
          </button>
        </div> -->
    </div>


    <!-- <div class="d-flex flex-column pe-3 ps-3 pt-5 pb-5 ms-3 me-3" *ngIf="order?.status === undefined">
          <div class="d-flex">
              <form [formGroup]="promoForm">
                <h5 class="align-self-center font-weight-bold text-white text-align-property">
                 Promo Code
                </h5>
                <div class="d-flex justify-content-between pt-3">
                  <input type="text" class="form-control marginApply" id="promoCode" name="promoCode" type="promoCode" class="form-control" placeholder="Promo Code" formControlName="promoCode"/>
                  <button class="btn btn-primary btn-lg font-weight-light w-100 marginApply" style="margin-top: 0px;margin-bottom: 0px;" (click)="applyPromo()" [disabled]="!promoForm.valid">Apply Promo</button>
                </div>
                <div  class="d-flex justify-content-between pt-3"  *ngIf="order?.promoResponseCode !== '200' && order?.promoMessage">
                  <div>{{order?.promoMessage}}</div>
                </div>
                <div class="d-flex justify-content-between pt-3" *ngIf="order?.appliedVouchers !== undefined">
                  <label class="form-control" style="background-color:transparent;color:white">Promo code "{{order?.appliedVouchers[0].code}}" is applied</label>
                  <button class="btn btn-lg font-weight-light w-100" (click)="removePromo()" style="color: white;text-decoration: underline;">Remove Promo</button>
                </div>
              </form>
          </div>
      </div> -->

    <!-- <div class="d-flex flex-column pe-3 ps-3 pb-5 pt-3 ms-3 me-3">
      <h5 class="align-self-center font-weight-bold text-white">
        Order Summary
      </h5>
      <div class="d-flex flex-column mt-3">
        <div class="order-total-value" *ngIf="order?.sapOrderTotalValue === undefined">
          <div class="d-flex justify-content-between">
            <div>Subtotal</div>
            <div>${{(order?.subTotal?.value + order?.productDiscounts?.value + order?.orderDiscounts?.value) | number: '.2-2'}}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Discount</div>
            <div>{{order?.productDiscounts?.value ? '-' : ''}}{{order?.productDiscounts?.formattedValue}}</div>
          </div>
          <div class="d-flex justify-content-between" *ngIf="order?.appliedVouchers !== undefined">
            <div>Promotions</div>
            <div>{{order?.orderDiscounts?.value ? '-' : ''}}{{order?.orderDiscounts?.formattedValue}}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Shipping</div>
            <div>{{order?.deliveryCost?.formattedValue}}</div>
          </div>
          <div class="d-flex justify-content-between" *ngIf="(order?.totalSurcharge?.value | number : 0) > 0">
            <div>Alaska/Hawaii Surcharge</div>
            <div>{{order?.totalSurcharge?.value | currency : fractionsize}}</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Estimated Tax</div>
            <div>{{order?.totalTax?.formattedValue}}</div>
          </div>

        </div>

        <div class="d-flex justify-content-between font-weight-bold mt-3">
          <div>Order Total</div>
          <div>{{(order?.sapOrderTotalValue === undefined)? (order?.totalPrice?.formattedValue) : (order?.sapOrderTotalValue | currency : fractionsize)}}</div>
        </div>
      </div>
    </div> -->
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-order-summary app-full-layout custom-font">
    <div class="d-flex card-promocode" *ngIf="order?.status === undefined">
      <form [formGroup]="promoForm" class="w-100">
        <div class="d-flex flex-column promo-field">
          <label class="w500" for="promoCode">Enter Promo Code</label>
          <div class="d-flex align-items-center">
            <input class="form-control" type="text" id="promoCode" name="promoCode" type="promoCode"
              placeholder="ILOVEPETS" formControlName="promoCode" />
            <button (click)="applyPromo()" [disabled]="!promoForm.valid">APPLY</button>
          </div>
        </div>
        <div class="d-flex justify-content-between" *ngIf="order?.promoResponseCode !== '200' && order?.promoMessage">
          <div class="pt-2 pb-0 w500 txt-critical promo-error">{{order?.promoMessage}}</div>
        </div>
        <div class="d-flex mt-3 applied-promo align-items-start" *ngIf="order?.appliedVouchers !== undefined">
          <label class="mb-0">Promo code "{{order?.appliedVouchers[0].code}}" is applied</label>
          <button class="btn btn-link" (click)="removePromo()">Remove Promo</button>
        </div>
        <!-- <div class="d-flex justify-content-between mt-3" *ngIf="order?.appliedVouchers !== undefined">
            <label class="form-control" style="background-color:transparent;color:white">Promo code "{{order?.appliedVouchers[0].code}}" is applied</label>
            <button class="btn font-weight-light w-100" (click)="removePromo()" style="color: white;text-decoration: underline;">Remove Promo</button>
          </div> -->
      </form>
    </div>

    <div class="d-flex flex-column">
      <h3 class="w800 order-summary-head pt-4 pb-3">
        Order Summary
      </h3>
      <div class="d-flex flex-column summary-card py-4">
        <div class="d-flex justify-content-between px-4 w600 mb-1">
          <div>Subtotal</div>
          <div>
            ${{(order?.subTotal?.value + order?.productDiscounts?.value + order?.orderDiscounts?.value) | number: '.2-2'}}
          </div>
        </div>
        <div class="d-flex justify-content-between mt-3 px-4 w600 mb-1 txt-critical">
          <div>Subscription Discount</div>
          <div>{{order?.productDiscounts?.value ? '-' : ''}}{{order?.productDiscounts?.formattedValue}}</div>
        </div>

        <div class="promo-summary d-flex justify-content-between mt-3 px-4 w600 mb-1 align-items-start"
          *ngIf="order?.appliedVouchers !== undefined">
          <!-- *ngIf="order?.appliedVouchers !== undefined" -->
          <div class="d-flex flex-wrap">
            <div class="pe-2 mb-1">Promo Code</div>
            <!-- No promo_code in promotion info in order completion page   -->
            <div class="promo-card d-flex align-items-center me-2" *ngIf="!isOrderCompletionPage">
              <span>{{order?.appliedVouchers[0].code}}</span>
              <!-- ‘promo removing option 'X' ’ not shown if ordercompletionpage  -->
              <a (click)="removePromo()" class="fa fa-times ps-2"></a>
              <!-- END -->
            </div>
            <!-- END -->
          </div>
          <div class="promo-price d-flex justify-content-end">
            {{order?.orderDiscounts?.value ? '-' : ''}}{{order?.orderDiscounts?.formattedValue}}
          </div>
        </div>

        <div class="d-flex justify-content-between mt-3 px-4 w600 mb-1">
          <div>Shipping</div>
          <div>{{order?.deliveryCost?.formattedValue}}</div>
        </div>
        <div class="d-flex justify-content-between mt-3 px-4 w600 mb-1"
          *ngIf="(order?.totalSurcharge?.value | number : 0) > 0">
          <div>Alaska/Hawaii Surcharge</div>
          <div>{{order?.totalSurcharge?.value | currency : fractionsize}}</div>
        </div>
        <div class="d-flex justify-content-between mt-3 px-4 w600">
          <div>Estimated Tax</div>
          <div>{{order?.totalTax?.formattedValue}}</div>
        </div>
        <div class="d-flex justify-content-between mt-3 px-4 w600" *ngIf="(order?.retailDeliveryFee?.value | number : 0) > 0">
          <div>State Delivery Fee**</div>
          <div>{{order?.retailDeliveryFee?.value | currency : fractionsize}}</div>
        </div>
        <div class="d-flex justify-content-between order-total mt-3 mb-3 px-4 w800">
          <div class="pe-2" placement = "bottom" [autoClose]="false" [ngbTooltip]="estimatedTaxToolTip" data-container="body">Estimated Order Total*</div>
          <div>{{order?.totalPrice?.formattedValue}}</div>
        </div>

        <div class="app-after-summary px-4 pt-3 text-center">
          <div *ngIf="(order?.totalSurcharge?.value | number : 0) > 0" class="pb-3">
            Free shipping excluding Alaska and Hawaii which will incur a surcharge of $1.00/item
          </div>
          <div *ngIf="(order?.totalSurcharge?.value | number : 0) > 0" class="pb-3">
            Please note that shipments to Alaska, Hawaii, and some remote areas in the continental US may take
            additional business days to arrive.
          </div>
          <div [ngClass]="{'pb-3':!isOrderCompletionPage}">
            Shipping rates and discounts are subject to change.
          </div>
          <div class="pt-1">
            *Save up to 35% on all Hill's products.
          </div>
          <div class="pt-1" *ngIf="(order?.retailDeliveryFee?.value | number : 0) > 0">
            **If your state has a retail delivery fee, this fee is non refundable.
          </div>
        </div>
        <div *ngIf="order?.subscriptionLongestDate" class="app-after-summary px-4 pt-3 pb-2">
          <div class="text-center">
            By clicking <b>"Place Order"</b> below, you accept that
            <span *ngIf="order?.totalItems > 1 || order?.subscriptionLongestDate ==='UNLIMITED'">this automatic renewal
              will continue for the term selected unless cancelled</span>
            <span *ngIf="order?.totalItems == 1 && order?.subscriptionLongestDate !=='UNLIMITED'">this subscription will
              continue until {{order?.subscriptionLongestDate}} unless cancelled</span>
            on HillsToHome.com at least 2 days before the orders
            ships. Your card will be charged {{order?.totalPrice?.formattedValue}} today and the current selling prices
            at the time of future shipments.
          </div>
        </div>
        <div class="alert alert-danger small ms-3 me-3 mb-0" *ngIf="order?.isOutOFStockCartItems">
          One or more item in your cart is temporarily unavailable. Remove them from the cart, to proceed further.
        </div>
        <div class="px-3 pt-3" *ngIf="!isOrderCompletionPage">
          <button (click)="placeOrder()" class="btn btn-primary btn-lg w-100"
            [disabled]="!profileAddresses?.addresses?.length || !profilePayments?.payments?.length || order?.isOutOFStockCartItems">
            PLACE ORDER
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #estimatedTaxToolTip>
  <div class="text-start"><span class="font-weight-light"> Order total may vary based on final tax calculation</span></div>
</ng-template>

<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <h5 class="app-title text-center m-4">
      Return Order
    </h5>
    <div>
      <form [formGroup]="returnOrderForm" *ngIf="returnOrderDetail?.returnOrder?.isReturnAvailable ;else returnNotAllowed">
        <h5 class="app-title text-center m-3">
          Select Product to Return
        </h5>
        <div class="container" formArrayName="return_orders_array">
          <div class="row " *ngFor="let item of returnOrderForm.controls.return_orders_array.controls;let i=index" [formGroupName]="i">
            <div class="col-sm-12 pe-3 ps-3 pt-4 pb-4 border-bottom">
              <div class="row checkbox_row_nowrap">
                <div fxflex="2" class="col-1">
                  <input type="checkbox" formControlName="return_product_checked" 
                  [value]="item.value?.return_product_checked" name="return-product-check-{{i}}" 
                  id="return-product-check-{{i}}" (ngModelChange)="checkboxValidator(item)">
                </div>
                <div fxflex="5" class="col-11">
                  <h6>{{ item.value?.product?.name }}</h6>
                </div>
              </div>
              <div class="border" [style.display]="item.value?.return_product_checked ? 'block' : 'none'">
                <div class="pe-3 ps-3 pt-3 pb-5 ms-3 me-3" >
                  <div class="d-flex">
                    <b class="w-50">Product Code:</b>
                    <span class="flex-grow-1">{{ item.value?.product?.code }}</span>
                  </div>
                  <div class="d-flex mt-2">
                    <b class="w-50">Item Price:</b>
                    <span class="flex-grow-1">
                      {{'$' + item.value?.basePrice?.value.toFixed(2) }}
                    </span>
                  </div>  
                  <div class="d-flex mt-2">
                    <b class="w-50">Available to Return:</b>
                    <span class="flex-grow-1">
                      {{ item.value?.quantity }}
                    </span>
                  </div>
                  <div class="d-flex mt-2">
                    <b class="w-50">Return Qty:</b>
                    <div class="form-group mt-1 mb-0">
                      <select id="returnQuantity-{{i}}" formControlName="expectedQuantity" name="returnQuantity-{{i}}" class="form-select custom-select" value="quantity">
                        <option *ngFor="let quantity of item.value?.quantityList" [value]="quantity">{{quantity}}</option>
                      </select>
                      <div *ngIf="item.controls.expectedQuantity.errors" class="invalid-feedbackx">
                        <div class="text-danger" *ngIf="item.controls.expectedQuantity.errors.required">Quantity is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-2 return_reason">
                    <b class="me-5">Choose a Reason:</b>
                  </div>
                  <div class="d-flex mt-2">
                    <div class="form-group col-sm-4 mt-1 mb-0">
                      <select id="returnReason-{{i}}" (change)="dropdownValidator(item)" formControlName="refundReason" name="returnReason-{{i}}" class="form-select custom-select refund-reason" value="objRefundReason.value">
                      <option value="">Select Refund Reason</option>
                      <option *ngFor="let objRefundReason of item.value?.refundReasonArray" [value]="objRefundReason.key">{{objRefundReason.value}}</option>
                      </select>
                    </div>
                  </div>
                  <div *ngIf="item.controls.refundReason.errors" class="invalid-feedbackx mt-1">
                    <div class="text-danger" *ngIf="item.controls.refundReason.errors.required">Refund reason is required</div>
                  </div>
                  <div class="d-flex mt-2">
                    <b class="w-80">Add a Comment:</b>
                  </div>
                  <div class="d-flex mt-2">
                    <span class="flex-grow-1">
                      <textarea formControlName="notes" class="form-control rounded-0 textarea_border" id="comments-{{i}}" rows="3" placeholder="Add a Comment"></textarea>
                    </span>
                  </div>
                  <div *ngIf="item.controls.notes.errors" class="invalid-feedbackx mt-1">
                    <div class="text-danger" *ngIf="item.controls.notes.errors.required">Comment is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-10" *ngIf="submit_button">
            <div class="col-sm-12">
              <div class="text-danger">Atleast one product must be selected.</div>
            </div>
          </div>
          <hr *ngIf="!returnOrderForm.valid">
            <div class="mt-5">
              <button type="submit" class="btn btn-primary w-100 mb-3" (click)="return_order_submit()" [disabled]="!returnOrderForm.valid">Submit</button>
            </div>
        </div>
      </form>
      <ng-template #returnNotAllowed>
        <div div *ngIf="returnOrderDetail?.returnOrder?.isReturnAvailable === false ;else showNoContent">
          <h5 class="text-center m-5">
          To process this return please contact customer support.<br>
          <div class="mt-2">
            at <a href="tel:+1-800-235-6877"
            class="align-items-center text-underline">
            1-800-235-6877</a> Or 
          <a class="text-underline" routerLink="/contact-us">Email Hill's to Home support team</a>
          </div>
          </h5>
        </div>
      </ng-template>
      <ng-template #showNoContent>
        <div>
        </div>
      </ng-template>
    </div> 
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <form [formGroup]="returnOrderForm" *ngIf="returnOrderDetail?.returnOrder?.isReturnAvailable ;else returnNotAllowed">
      <h5 class="app-title text-start m-3">
        Select Product to Return
      </h5>
      <div class="table-responsive">
        <table class="table table-hover" formArrayName="return_orders_array">
          <thead>
            <tr>
              <th scope="col text-uppercase"></th>
              <th scope="col text-uppercase">#</th>
              <th scope="col text-uppercase">Product Code</th>
              <th scope="col text-uppercase" class="min_table_th_width">Product Name</th>
              <th scope="col text-uppercase">Item Price</th>
              <th scope="col text-uppercase">Avail. To Return</th>
              <th scope="col text-uppercase">Return Qty</th>
              <!-- <th scope="col">Refund Amount</th> -->
              <th scope="col text-uppercase" class="min_table_th_width">Choose a Reason</th>
              <th scope="col text-uppercase" class="min_table_th_width">Add a Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of returnOrderForm.get('return_orders_array').controls;let i=index" [formGroupName]="i">
                  <th scope="row">
                    <label>
                      <input type="checkbox" formControlName="return_product_checked" [value]="item.value.return_product_checked" name="return-product-check-{{i}}" id="return-product-check-{{i}}" (ngModelChange)="checkboxValidator(item)">
                    </label>
                  </th>
                  <td>{{i+1}}</td>
                  <td>{{ item.value.product?.code }}</td>
                  <td>{{ item.value.product?.name }}</td>
                  <td>{{ '$' + item.value?.basePrice?.value.toFixed(2)}}</td>
                  <td>{{ item.value?.quantity }}</td>
                  <td class="form-groupx mt-1 mb-0">
                    <select id="returnQuantity-{{i}}" formControlName="expectedQuantity" value="quantity" class="form-select form-select-sm dropdown_border custom-select">
                      <option *ngFor="let quantity of item.value?.quantityList" [value]="quantity">{{quantity}}</option>
                    </select>
                    <div *ngIf="item.controls.expectedQuantity.errors" class="invalid-feedbackx">
                      <div class="text-danger" *ngIf="item.controls.expectedQuantity.errors.required">Quantity is required</div>
                    </div>
                  </td>
                  <td class="form-groupx">
                    <select id="returnReason-{{i}}" formControlName="refundReason" class="form-select form-select-sm dropdown_border custom-select refund-reason" (change)="dropdownValidator(item)">
                      <option value="">Select Refund Reason</option>
                      <option *ngFor="let objRefundReason of item.value?.refundReasonArray" [value]="objRefundReason.key">{{objRefundReason.value}}</option>
                    </select>
                    <div *ngIf="item.controls.refundReason.errors" class="invalid-feedbackx">
                      <div class="text-danger" *ngIf="item.controls.refundReason.errors.required">Refund reasons is required</div>
                    </div>
                  </td>
                  <td class="form-groupx">
                      <textarea formControlName="notes" class="form-control dropdown_border" id="comments-{{i}}" rows="3" placeholder="Add a Comment"></textarea>
                      <div *ngIf="item.controls.notes.errors" class="invalid-feedbackx">
                        <div class="text-danger" *ngIf="item.controls.notes.errors.required">Comment is required</div>
                      </div>
                  </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="row my-10" *ngIf="returnOrderForm.controls.return_orders_array.errors && returnOrderForm.controls.return_orders_array.errors.required"> -->
      <div class="row my-10" *ngIf="submit_button">
        <div class="col-lg-8 col-md-12">
          <div class="text-danger">Atleast one product must be selected.</div>
        </div>
      </div>
      <div class="row my-10">
        <div class="col-lg-8 col-md-12">
          <!-- <button class="btn btn-primary btn-md btn-css" [disabled]="!returnOrderForm.valid && !submit_button" (click)="return_order_submit()">submit</button> -->
          <button class="btn btn-primary btn-md btn-css" [disabled]="!returnOrderForm.valid && !submit_button" (click)="return_order_submit()">Submit</button>
        </div>
      </div>
  </form>
  <ng-template #returnNotAllowed>
    <div *ngIf="returnOrderDetail?.returnOrder?.isReturnAvailable === false ;else showNoContent">
      <h5 class="text-center m-5">
        To process this return please contact customer support.<br>
        <div class="mt-2">
        at <a href="tel:+1-800-235-6877"
        class="align-items-center text-underline">
       1-800-235-6877</a> Or 
       <a class="text-underline" routerLink="/contact-us">Email Hill's to Home support team</a>
      </div>
      </h5>
    </div>
  </ng-template>
  <ng-template #showNoContent>
    <div>
    </div>
  </ng-template>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      You have successfully initiated your return order request.
    </p>
    <p class="m-4 text-center">
      Please check your email for details.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
            (click)="closeSuccess()">
      Ok
    </button>
  </div>
</ng-template>
<div *ngIf="isMobile; then mobileLayout; else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg pb-4">

    <h4 *ngIf="showWellnessProducts" class="w800 main-title-mob mb-0 mx-4">
      <a class="pet-back" href="javascript:void(0)" (click)="goBack()">
        <i class="fa fa-arrow-left"></i>
      </a>
      {{pet?.petName}}<br><span class="mt-3 d-block">Science Diet products</span></h4>

    <!-- <div *ngIf="showWellnessProducts" class="mt-3 mb-1 custom-font mx-4">
      <p class="w500 m-0">Need Prescription Diet?<br>
        Contact your vet to get a nutrition plan
        created.</p>
    </div> -->
    <!-- Removed condition to enable search for Prescription product as well along with Wellness Product-->
    <div *ngIf="!changeProduct" class="col-12">
      <div class="wellness-search pl-20 pr-20" [ngClass]="{'mt-3' : showWellnessProducts}">
        <label for="search" style="display: none;">Search</label>
        <input type="text" id="search" placeholder="Search..." [value]="name" #search
          (keyup.enter)="_searchWellnessProductsByName($event.target.value)">
        <button class="search-btn mr-18" type="submit" aria-label="Search icon" (click)="_searchWellnessProductsByName(search.value)"><i
            class="fas fa-search"></i></button>
      </div>
    </div>

    <form [formGroup]="shopForm" class="row mob-sort-filter pt-4 pb-3" autocomplete="off" (ngSubmit)="search()">

      <div class="col-6 btn-sort pe-2">
        <a class="d-flex align-items-center justify-content-center text-underline" (click)="showSortPopup()"> <i
            class="fa fa-sort"></i>Sort </a>
      </div>
      <div class="col-6 btn-filter pl-2">
        <a class="d-flex align-items-center justify-content-center text-underline" (click)="showFilterPopup()"> <i
            class="fa fa-filter"></i>Filter<em
            *ngIf="filters?.appliedFilter?.length">({{filters?.appliedFilter?.length}})</em> </a> <!-- TODO  -->
      </div>
      <!-- <select id="sort" name="sort" class="form-control custom-select" formControlName="sort" (change)="search()">
          <option value="">Sort</option>
          <option *ngFor="let sort of searchedProducts?.sorts" [value]="sort.code">{{sort.code | appProductSort}}
          </option>
        </select> -->

      <div class="mobile-popup" [ngClass]="{ 'show-popup':showFilterPage}" *ngIf="filters && facets && nutritionPlan">
        <!-- ngClass to be added , [ngClass]="{ 'showFilter':showFilterPage}"-->
        <app-np-filters #npFilters [isMobile]="isMobile" [pending]="pending" [facets]="facets" [filters]="filters"
          (submitted)="filterProducts(0, $event)" (showFilterPopup)="showFilterPopup()" [nutritionPlan]="nutritionPlan">
        </app-np-filters>
      </div>
      <div class="mobile-popup p-3 custom-font" [ngClass]="{ 'show-popup': showSortPage }">
        <h3 class="w700 d-flex align-items-center">
          <span>Sort</span>
          <a (click)="showSortPopup()" class="fa fa-times"></a>
        </h3>
        <p *ngFor="let sortItem of searchedProducts?.sorts">
          <label [for]="sort.code" style="display: none">Sort</label>
          <input type="radio" name="sort" [id]="sortItem.code" [value]="sortItem.code" formControlName="sort"
            (change)="showSortPopup(); filterProducts()" />
          <label [for]="sortItem.code">{{
            sortItem.code | appProductSort
          }}</label>
        </p>
      </div>
    </form>

    <!-- Product listing for shop page -->
    <div *ngIf="!changeProduct" class="row mob-card-wrapper">
      <a href="javascript:void(0)" (click)="showProductDetail(clinicId,product.code,pet.petCode, product, product.msrpPrice, product.discountedPrice, i)"
        class="col-6 mob-card-container px-2 text-underline" *ngFor="let product of searchedProducts?.products; let i = index">
        <div class="product-item-card">
          <div class="d-flex flex-column align-items-center">
            <h5>{{ product.name }}</h5>
            <figure *ngIf="product?.images && product?.stock?.stockLevelStatus === 'inStock'">
              <img src="{{ product.images | appImg: 'lrg' }}" class="product-item-img" *ngIf="product.images" alt="{{product?.name || 'Product Name'}}" />
            </figure>
            <div class="text-center" *ngIf="!product.images">
              <i class="fas fa-image fa-9x"></i>
            </div>
            <!--Out of Stock Product Start-->
            <div class="productImageHolder" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'outOfStock'">
              <figure><img src="{{product.images | appImg:'lrg'}}" class="product-item-img" alt="{{product?.name || 'Product Name'}}"/></figure>
                <span class="small out-of-stock px-1 py-1 text-center">
                  Temporarily Unavailable
                </span>         
            </div>
            <div class="detail-sku mt-0" style="text-decoration: none;">
              SKU {{product?.code}}
            </div>
            <!--Out of Stock Product End-->
          </div>
          <!-- <div class="d-flex mt-2 rate-like align-items-center">
            <span class="stars pe-1">
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
              <i class="fa fa-star" aria-hidden="true"></i>
            </span>
            <small>(305)</small>
            <span class="like text-right">
              <i class="fa fa-heart"></i>
            </span>
          </div> -->

          <div class="d-flex mt-2 align-items-start flex-column">
            <div *ngIf="product.clinicPrice" class="old-price pe-2">
              ${{ product.clinicPrice | number: ".2-2" }}
              <!-- <i class="fa fa-info-circle"></i> -->
            </div>
            <div *ngIf="!product.clinicPrice" class="old-price pe-2">
              ${{ product.msrpPrice | number: ".2-2" }}
              <!-- <i class="fa fa-info-circle"></i> -->
            </div>
            <div class="price pe-2">
              <span>${{ product.discountedPrice | number: ".2-2" }}</span>
            </div>
            <!-- <span class="icon-info">
              <i class="fa fa-info-circle"></i>
            </span> -->
            <!-- <div *ngIf="!changeProduct">
                <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline"
                  (click)="$event.stopPropagation();addToCart(product)">
                  Add to Cart
                </a>
              </div> -->
            <!-- <div *ngIf="changeProduct">
                <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline select-btn"
                  (click)="$event.stopPropagation();selectProduct(product)">
                  Select
                </a>
              </div> -->
          </div>
        </div>
      </a>
    </div>
    <!-- End of Product listing for shop page -->

    <!-- Product listing for change product page -->
    <div *ngIf="changeProduct" class="row mob-card-wrapper">
      <a href="javascript:void(0)" (click)="showProductDetailForChangeProduct(clinicId,product.code,pet.petCode)" class="col-6 mob-card-container px-2"
        *ngFor="let product of searchedProducts?.products; let i = index">
        <div class="product-item-card">
          <div class="d-flex flex-column align-items-center">
            <h5>{{ product.name }}</h5>
            <figure *ngIf="product?.images && product?.stock?.stockLevelStatus === 'inStock'">
              <img src="{{ product.images | appImg: 'lrg' }}" class="product-item-img" *ngIf="product.images" alt="{{product?.name || 'Product Name'}}" />
            </figure>
            <div class="text-center" *ngIf="!product.images">
              <i class="fas fa-image fa-9x"></i>
            </div>
            <!--Out of Stock Product Start-->
            <div class="productImageHolder" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'outOfStock'">
              <figure><img src="{{product.images | appImg:'lrg'}}" class="product-item-img" alt="{{product?.name || 'Product Name'}}"/></figure>
                <span class="small out-of-stock px-1 py-1 text-center">
                  Temporarily Unavailable
                </span>         
            </div>
            <div class="detail-sku mt-0">
              SKU {{product?.code}}
            </div>
            <!--Out of Stock Product End-->
          </div>
          <!-- <div class="d-flex mt-2 rate-like align-items-center">
                  <span class="stars pe-1">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <small>(305)</small>
                  <span class="like text-right">
                    <i class="fa fa-heart"></i>
                  </span>
                </div> -->

          <div class="d-flex mt-2 align-items-start flex-column">
            <div *ngIf="product.clinicPrice" class="old-price pe-2">
              ${{ product.clinicPrice | number: ".2-2" }}
              <!-- <i class="fa fa-info-circle"></i> -->
            </div>
            <div *ngIf="!product.clinicPrice" class="old-price pe-2">
              ${{ product.msrpPrice | number: ".2-2" }}
              <!-- <i class="fa fa-info-circle"></i> -->
            </div>
            <div class="price pe-2">
              <span>${{ product.discountedPrice | number: ".2-2" }}</span>
            </div>
            <!-- <span class="icon-info">
                    <i class="fa fa-info-circle"></i>
                  </span> -->
            <!-- <div *ngIf="!changeProduct">
                      <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline"
                        (click)="$event.stopPropagation();addToCart(product)">
                        Add to Cart
                      </a>
                    </div> -->
            <!-- <div *ngIf="changeProduct">
                      <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline select-btn"
                        (click)="$event.stopPropagation();selectProduct(product)">
                        Select
                      </a>
                    </div> -->
          </div>
        </div>
      </a>
    </div>
    <!-- End of Product listing for change product page -->


    <div class="d-flex align-items-center justify-content-center mt-3 mb-4"
      *ngIf="searchedProducts?.products && !(searchedProducts.pagination.currentPage === (searchedProducts.pagination.totalPages - 1))">
      <button class="custom-font btn-white btn px-5" type="button" (click)="loadMoreProducts()">
        Load More
      </button>
    </div>
    <div *ngIf="!searchedProducts?.products"
      class="notfound-container d-flex align-items-center justify-content-center w500 custom-font h4 mt-4 mx-4">
      No Results Found
    </div>

    <!-- <div *ngIf="!changeProduct" class="custom-font active-promotion d-flex flex-column align-items-center mx-4">
      <p class="pt-3 pb-2">2 Active Promotions</p>
      <a class="btn px-4 py-3">View Now</a>
    </div> -->
    <a *ngIf="!showWellnessProducts && nutritionPlans?.length != 1 && !changeProduct" class="btn btn-multiline mx-4" (click)="_searchWellnessProducts()">View Science Diet Products</a>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg">
    <div class="container pb-5" [ngClass]="{'pt-5' :showWellnessProducts  }">

      <h3 *ngIf="showWellnessProducts" class="w800 pb-5">
        <a class="btn-back-arrow pe-1" href="javascript:void(0)" (click)="goBack()">
          <i class="fa fa-arrow-left"></i>
        </a>
        {{pet?.petName}}'s Science Diet products
      </h3>
      <div class="row" [ngClass]="changeProduct? 'pt-4' : 'mt-n4' ">
        <div class="col-sm-12 col-md-4 col-lg-3" *ngIf="filters && facets && nutritionPlan">
          <app-np-filters #npFilters [isMobile]="isMobile" [pending]="pending" [facets]="facets" [filters]="filters"
            [nutritionPlan]="nutritionPlan" (submitted)="filterProducts(0, $event)">
          </app-np-filters>

          <!-- <div *ngIf="!changeProduct" class="custom-font active-promotion d-flex align-items-center">
            <p>2 Active Promotions</p>
            <a class="btn py-2">View Now</a>
          </div> -->
          <a *ngIf="!showWellnessProducts && nutritionPlans?.length != 1 && !changeProduct" class="btn btn-multiline mb-4" (click)="_searchWellnessProducts()">View Science Diet Products</a>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-9" *ngIf="searchedProducts">
          <div class="row pt-3">
            <!-- Removed condition to enable search for Prescription product as well along with Wellness Product-->
            <div *ngIf="!changeProduct" class="col-6">
              <div class="wellness-search mt-1">
                <label for="search" style="display: none;">Search</label>
                <input type="text" id="search" placeholder="Search..." [value]="name" #search
                  (keyup.enter)="_searchWellnessProductsByName($event.target.value)">
                <button class="search-btn" type="submit" aria-label="Search icon" (click)="_searchWellnessProductsByName(search.value)"><i
                    class="fas fa-search"></i></button>
              </div>
            </div>

            <div [ngClass]="((showWellnessProducts || nutritionPlans?.length == 1) && !changeProduct) ? 'col-6' : 'col-12'">
              <form [formGroup]="shopForm" class="d-flex align-items-center justify-content-end custom-font pb-2"
                autocomplete="off" (ngSubmit)="search()">
                <!-- <span class="pe-2 item-count" *ngIf="searchedProducts"
                  >({{ searchedProducts.pagination.totalResults }}) items</span
                > -->
                <div class="sort-control">
                  <p (click)="toggleSortOptions();"
                    class="d-flex align-items-center justify-content-center custom-font p-2 mb-2 bg-white">
                    <input class="hidden"id="sort" name="sort" type="radio" readonly formControlName="sort" />
                    <i class="fa fa-sort pe-1"></i>
                    <label for="sort" class="w500 m-0 py-1" *ngIf="!sort">Sort</label>
                    <label class="w500 m-0 py-1" *ngIf="sort">{{sort | appProductSort}}</label>
                  </p>
                  <div class="sort-dd" *ngIf="showSortOptions">
                    <p *ngFor="let sortItem of searchedProducts?.sorts" class="mb-2">
                      <input type="radio" name="sort" [id]="sortItem.code" [value]="sortItem.code"
                        formControlName="sort" (change)="toggleSortOptions(); filterProducts()" />
                      <label class="w500 m-0 py-1" [for]="sortItem.code">{{ ( sortItem.code | appProductSort) || 'test-1' }}</label>
                    </p>
                  </div>
                </div>

              </form>
            </div>
          </div>

          <!-- Product listing for shop page -->
          <div *ngIf="!changeProduct" class="row">
            <a href="javascript:void(0)"  (click)="showProductDetail(clinicId,product.code,pet.petCode, product, product.msrpPrice, product.discountedPrice, i)" class="col-6 col-sm-6 col-md-6 col-lg-4 mt-2 mb-4"
              *ngFor="let product of searchedProducts?.products; let i = index">
              <div class="product-item-card">
                <div class="d-flex flex-column align-items-center" >
                  <!-- <span class="pb-2">Hill's Prescription</span> -->
                  <h5 [ngbTooltip]="product?.name">{{ product.name }}</h5>
                  <!-- <span class="pt-1">2.9 OZ, 24 Pack</span> -->
                  <figure *ngIf="product?.images && product?.stock?.stockLevelStatus === 'inStock'">
                    <img src="{{ product.images | appImg: 'lrg' }}" class="product-item-img" alt="{{product?.name || 'Product Name'}}"/>
                  </figure>
                  <!-- <img class="product-item-img"
                    src="https://www.hillspet.com/content/dam/pim/hills/en_us/pd/canned/pd-ad-canine-feline-canned-productShot_500.png.rendition.1920.1920.png" /> -->
                  <div class="text-center product-default-image" *ngIf="!product.images">
                    <i class="fas fa-image fa-10x"></i>
                  </div>
                  <!--Out of Stock Product Start-->
                  <div class="productImageHolder" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'outOfStock'">
                    <figure><img src="{{product.images | appImg:'lrg'}}" class="product-item-img" alt="{{product?.name || 'Product Name'}}"/></figure>
                      <span class="small out-of-stock px-1 py-1 text-center">
                        Temporarily Unavailable
                      </span>         
                  </div>
                  <div class="detail-sku mt-0">
                    SKU {{product?.code}}
                  </div>
                  <!--Out of Stock Product End-->
                  <!-- <div class="text-black-50 small mt-3">
                      SKU {{product.code}}
                    </div> -->
                </div>
                <!-- <div class="d-flex mt-2 rate-like align-items-center">
                  <span class="stars pe-1">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                  </span>
                  <small>(305)</small>
                  <span class="like text-right">
                    <i class="fa fa-heart"></i>
                  </span>
                </div> -->

                <div class="d-flex mt-2 align-items-center">
                  <div *ngIf="product.clinicPrice" class="old-price pe-2">
                    ${{ product.clinicPrice | number: ".2-2" }}
                    <!-- <i class="fa fa-info-circle pl-2"></i> -->
                  </div>
                  <div *ngIf="!product.clinicPrice" class="old-price pe-2">
                    ${{ product.msrpPrice | number: ".2-2" }}

                  </div>
                  <div class="price pl-0">
                    <span>${{ product.discountedPrice | number: ".2-2" }}
                      <!-- <i class="fa fa-info-circle pl-2"></i> -->
                    </span>
                  </div>


                  <!-- <span class="icon-info">
                    <i class="fa fa-info-circle"></i>
                  </span> -->
                  <!-- <div *ngIf="!changeProduct">
                      <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline"
                        (click)="$event.stopPropagation();addToCart(product)">
                        Add to Cart
                      </a>
                    </div> -->
                  <!-- <div *ngIf="changeProduct">
                    <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline select-btn"
                      (click)="$event.stopPropagation();selectProduct(product)">
                      Select
                    </a>
                  </div> -->
                </div>
              </div>
            </a>
          </div>
          <!-- End of Product listing for shop page -->

          <!-- Product listing for change product page -->
          <div *ngIf="changeProduct" class="row">
            <a href="javascript:void(0)" (click)="showProductDetailForChangeProduct(clinicId,product.code,pet.petCode)" class="col-6 col-sm-6 col-md-6 col-lg-4 mt-2 mb-4"
              *ngFor="let product of searchedProducts?.products; let i = index">
              <div class="product-item-card">
                <div class="d-flex flex-column align-items-center">
                  <!-- <span class="pb-2">Hill's Prescription</span> -->
                  <h5 [ngbTooltip]="product?.name">{{ product.name }}</h5>
                  <!-- <span class="pt-1">2.9 OZ, 24 Pack</span> -->
                  <figure *ngIf="product?.images && product?.stock?.stockLevelStatus === 'inStock'">
                    <img src="{{ product.images | appImg: 'lrg' }}" class="product-item-img" *ngIf="product.images" alt="{{product?.name}}" />
                  </figure>
                  <!-- <img class="product-item-img"
                                src="https://www.hillspet.com/content/dam/pim/hills/en_us/pd/canned/pd-ad-canine-feline-canned-productShot_500.png.rendition.1920.1920.png" /> -->
                  <div class="text-center product-default-image" *ngIf="!product.images">
                    <i class="fas fa-image fa-10x"></i>
                  </div>
                  <!--Out of Stock Product Start-->
                  <div class="productImageHolder" *ngIf="product?.images && product?.stock?.stockLevelStatus === 'outOfStock'">
                    <figure><img src="{{product.images | appImg:'lrg'}}" class="product-item-img" alt="{{product?.name || 'Product Name'}}"/></figure>
                      <span class="small out-of-stock px-1 py-1 text-center">
                        Temporarily Unavailable
                      </span>         
                  </div>
                  <div class="detail-sku mt-0">
                    SKU {{product?.code}}
                  </div>
                  <!--Out of Stock Product End-->
                  <!-- <div class="text-black-50 small mt-3">
                                  SKU {{product.code}}
                                </div> -->
                </div>
                <!-- <div class="d-flex mt-2 rate-like align-items-center">
                              <span class="stars pe-1">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                              </span>
                              <small>(305)</small>
                              <span class="like text-right">
                                <i class="fa fa-heart"></i>
                              </span>
                            </div> -->

                <div class="d-flex mt-2 align-items-center">
                  <div class="flex-grow-1">
                      <span *ngIf="product.clinicPrice" class="old-price pe-2">
                        ${{ product.clinicPrice | number: ".2-2" }}
                        <!-- <i class="fa fa-info-circle pl-2"></i> -->
                      </span>
                      <span *ngIf="!product.clinicPrice" class="old-price pe-2">
                        ${{ product.msrpPrice | number: ".2-2" }}

                      </span>
                      <span class="price pl-0">
                        <span>${{ product.discountedPrice | number: ".2-2" }}
                          <!-- <i class="fa fa-info-circle pl-2"></i> -->
                        </span>
                      </span>
                  </div>

                  <!-- <span class="icon-info">
                                <i class="fa fa-info-circle"></i>
                              </span> -->
                  <!-- <div *ngIf="!changeProduct">
                                  <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline"
                                    (click)="$event.stopPropagation();addToCart(product)">
                                    Add to Cart
                                  </a>
                                </div> -->
                  <div *ngIf="changeProduct">
                      <a href="javascript:void(0)" *ngIf="product?.stock?.stockLevelStatus === 'inStock'"  class="btn btn-secondary btn-sm app-outline select-btn" (click)="$event.stopPropagation();selectProduct(product)">
                          Select
                      </a>
                      <!--Out of Stock Product Start-->
                      <a href="javascript:void(0)" class="btn btn-secondary btn-sm app-outline select-btn disabled" *ngIf="product?.stock?.stockLevelStatus !== undefined && product?.stock?.stockLevelStatus === 'outOfStock'">
                          Select
                      </a>
                      <!--Out of Stock Product End-->
                  </div> 
                </div>
              </div>
            </a>
          </div>
          <!-- End of Product listing for change product page -->


          <div class="d-flex align-items-center justify-content-center mt-3" *ngIf="searchedProducts?.products &&
            !(searchedProducts.pagination.currentPage===(searchedProducts.pagination.totalPages - 1))">
            <button class="custom-font btn-white btn px-4" type="button" (click)="loadMoreProducts()">Load More</button>
          </div>

          <div *ngIf="!searchedProducts?.products"
            class="notfound-container w-100 d-flex align-items-center justify-content-center w500 custom-font h4 mt-4">
            No Results Found
          </div>

          <!-- <div class="mt-5 d-flex justify-content-center" *ngIf="searchedProducts">
             <ngb-pagination [collectionSize]="searchedProducts.pagination.totalResults"
                              [pageSize]="searchedProducts.pagination.pageSize"
                              [page]="searchedProducts.pagination.currentPage + 1"
                              [maxSize]="10"
                              [ellipses]="true"
                              [rotate]="true"
                              (pageChange)="search($event - 1)"
                              [directionLinks]="true">
              </ngb-pagination>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <div class="app-mobile-layout" *ngIf="isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{ product.images | appImg: 'lrg' }}" class="app-cart-img" *ngIf="product?.images" alt="{{product?.name || 'Product Image'}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="mt-4 h4 text-center">
        {{ product?.name }} has been added to your cart
      </p>
    </div>

    <div class="app-full-layout" *ngIf="!isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{ product.images | appImg: 'lrg' }}" class="app-cart-img" *ngIf="product?.images" alt="{{product?.name || 'Product Name'}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="mt-4 h4 text-center">
        {{ product?.name }} has been added to your cart
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary ml-2" (click)="dismissSuccess()">
      Continue Shopping
    </button>
    <button type="button" class="btn btn-primary ml-2" (click)="closeSuccess()">
      View Cart
    </button>
  </div>
</ng-template>

<ng-template #productChangedModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <div class="app-mobile-layout" *ngIf="isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{ product.images | appImg: 'lrg' }}" class="app-cart-img" *ngIf="product?.images" alt="{{product?.name || 'Product Name'}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="m-4 h4 text-center">
        Your subscription has been changed to {{ product?.name }}
      </p>
    </div>
    <div class="app-full-layout" *ngIf="!isMobile">
      <div class="d-flex flex-column align-items-center">
        <img src="{{ product.images | appImg: 'lrg' }}" class="app-cart-img" *ngIf="product?.images" alt="{{product?.name || 'Product Name'}}" />
        <div class="app-cart-img text-center" *ngIf="!product?.images">
          <i class="fas fa-image fa-10x"></i>
        </div>
      </div>
      <p class="m-4 h4 text-center">
        Your subscription has been changed to {{ product?.name }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary ps-5 pe-5 btn-lg ml-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
<div class="faq-content supportAcc">
  <!-- program-policy start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'program-policy'" >
  <div ngbAccordionItem="programPolicyPanel_0" [collapsed]="programPolicyPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('programPolicyPanel_0')">
        <div class="app-card-header" [class.selected]="'programPolicyPanel_0' === selectedPanelId">
          <i *ngIf="'programPolicyPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'programPolicyPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder">General Program Information</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Our Hill’s to Home Program can fulfill the needs of you and your furry friends by giving access to the best nutrition possible, free home delivery, auto-ship discounts, pet parent ordering site, order tracking, the convenience of home delivery, and your vet remains in the continual care of your beloved pet. Hill's Pet Nutrition has a 100% satisfaction guarantee on all our products. If for any reason you are not satisfied with any product, you may return the item(s) for a full refund. Your veterinarian will set you up with an account and the right Nutrition Plan for your pet.
          <br/><br/>
          <b>Ask your Vet how to sign up with Hill’s To Home today!</b>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="programPolicyPanel_1" [collapsed]="programPolicyPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('programPolicyPanel_1')">
        <div class="app-card-header" [class.selected]="'programPolicyPanel_1' === selectedPanelId">
          <i *ngIf="'programPolicyPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'programPolicyPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Sign Up for Hill’s to Home</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Thank you for your interest in Hill’s To Home! Participating veterinarian’s will help set up your account and provide a nutrition recommendation for you. Hill's to Home will send you an email with a link to complete your account setup to access HillsToHome.com. Shop from any Science Diet products according to your veterinarian’s recommendations. Should they recommend a Prescription Diet product for your pet, they will set you up with a Nutrition Plan specified for your pet’s needs. Ask your Vet how to sign up with Hill’s To Home today!
          <br/><br/>
          *Only at participating locations.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="programPolicyPanel_2" [collapsed]="programPolicyPanel_2 !== selectedPanelId" >
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('programPolicyPanel_2')">
        <div class="app-card-header" [class.selected]="'programPolicyPanel_2' === selectedPanelId">
          <i *ngIf="'programPolicyPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'programPolicyPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">General Shipping Information</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          The Hill’s to Home Program offers *free UPS shipping in 2-3 business days right to your home! Our Hill's to Home program allows you the flexibility to decide when and how often you would like to receive your deliveries keeping you in control. There is no fee for skipping or delaying an order so that your subscription works for you and your pet.
          <br/><br/>
          <b>Estimated Delivery Timing</b>
          <ul>
            <li>Standard Shipping: 2-3 business days</li>
            <li>Major Holidays: carriers often experience delays to estimated arrival times by an additional 1-2 business days during the weeks of and adjacent to major Holidays (Memorial Day, Independence Day, Labor Day, Thanksgiving, Christmas, New Years)</li>
            <li>Inclement Weather or Other Local/National Emergencies: carriers occasionally experience delays to estimated arrival times based on local or national events</li>
            <li>Exceptions: Shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional business days to arrive.</li>
          </ul>
          <br/>
          <b>Important Note:</b> Orders placed after 1:00 pm local time may be shipped the following business day and add one additional day to the estimated delivery timing.
          <br/><br/>
          <b>Shipping Fees</b>
          <ul>
            <li>Standard Shipping: <span class="text-underline">Free</span> (in the continental US)</li>
            <li>*Alaska & Hawaii: $1.00 Surcharge per item</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="programPolicyPanel_3" [collapsed]="programPolicyPanel_3 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('programPolicyPanel_3')">
        <div class="app-card-header" [class.selected]="'programPolicyPanel_3' === selectedPanelId">
          <i *ngIf="'programPolicyPanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'programPolicyPanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Promo Code Inquiry</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          We certainly understand the importance of each dollar and we try to offer a variety of promotions so we can reach the largest audience possible. Therefore we do limit the amount of times a single promotion may be used by one person or household so we can ensure more people receive the opportunity to participate in the promotion.
          <br/><br/>
          To get started, we currently are offering up to 35% off your first order. For future promotional opportunities, we will contact you by your registered email address to keep you informed. Don’t forget to visit your marketing preferences located under “My Account” and check the box stating: I'd like to receive communication from Hill's about products, offers, and programs.
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- program-policy end -->
<!-- registration-login start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'registration-login'">
  <div ngbAccordionItem="registrationPanel_0" [collapsed]="registrationPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('registrationPanel_0')">
        <div class="app-card-header" [class.selected]="'registrationPanel_0' === selectedPanelId">
          <i *ngIf="'registrationPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'registrationPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Registration Instructions</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Your veterinarian may choose from several registration options for you. They may register for you, provide you with a self registration link, or provide you with a QR code. Registration information includes an email address, first and last name, phone number, pet name(s) and species (cat/dog). Please reach out to your Vet Clinic if you have not received an email confirming a successful registration and you were not provided a self registration link or QR code to use as a self register option.
          <br/><br/>
          <b>Vet Assisted Registration</b>
          <br/>
          If your veterinarian has already registered for you, check your email for registration confirmation. The sender will be from hillstohome&#64;hillspet.com with the subject line “Hill’s to Home Registration Successful”. Click the link provided to create a password and manage your account. Once your vet has set you up with a nutrition plan, you can start shopping by placing a one-time order or setting up a subscription to take advantage of the autoship option and receive a discount!
          <br/><br/>
          <b>Self Registration Instructions</b>
          <br/><br/>
          <ol>
            <li>
              <b>Website URL Link</b>
              <br/>
              If your veterinarian has provided you with a self registration link, click on the link and fill out the form and submit your registration information. Check your email for registration confirmation. The sender will be from hillstohome&#64;hillspet.com with the subject line “Hill’s to Home Registration Successful”. Click the link provided to create a password and manage your account. Once your vet has set you up with a nutrition plan, you can start shopping by placing a one-time order or setting up a subscription to take advantage of the autoship option and receive a discount!
            </li>
            <br/>
            <li>
              <b>QR Code</b>
              <br/>
              If your veterinarian has provided you with a QR code, use a QR Code scanner on your phone. Fill out the form and submit your registration information. Check your email for registration confirmation. The sender will be from hillstohome&#64;hillspet.com with the subject line “Hill’s to Home Registration Successful”. Click the link provided to create a password and manage your account. Once your vet has set you up with a nutrition plan, you can start shopping by placing a one-time order or setting up a subscription to take advantage of the autoship option and receive a discount!
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="registrationPanel_1" [collapsed]="registrationPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('registrationPanel_1')">
        <div class="app-card-header" [class.selected]="'registrationPanel_1' === selectedPanelId">
          <i *ngIf="'registrationPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'registrationPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Registration Email Not Received/Lost</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Registration emails are sent once your veterinarian has successfully registered you with the Hill’s to Home program or you have self registered with a website URL link or QR Code provided by your veterinarian. Reach out to your veterinarian to start the registration process.
          <br/><br/>
          If you have already been registered by your veterinarian or you have self registered with a link or QR code, to receive a new registration link, please click here to <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> for additional assistance.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="registrationPanel_2" [collapsed]="registrationPanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('registrationPanel_2')">
        <div class="app-card-header" [class.selected]="'registrationPanel_2' === selectedPanelId">
          <i *ngIf="'registrationPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'registrationPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Forgot/Reset Password</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <ng-template>
          <span *ngIf="!isLoggedIn"><a href="javascript:void(0)" (click)="goToForgetPasswordPage()">Click here</a> to reset your password and enter your email address.
          <br/><br/></span>
          Check for an email from hillstohome&#64;hillspet.com with the subject line “Hill’s to Home Password Reset”. Click on the “Reset Password” link and follow the instructions. Please note the password character requirements.
          <br/><br/>
          <!-- Password reset instructions - link is missing (text change) -   -->
          <b>Important Note:</b> This link expires in 24 hours. <span *ngIf="!isLoggedIn">If the link has expired, click above to get a new link.</span>
          <br/><br/>
          *Use Google Chrome, Internet Explorer or Safari browsers on the Hill’s to Home website
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="registrationPanel_3" [collapsed]="registrationPanel_3 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('registrationPanel_3')">
        <div class="app-card-header" [class.selected]="'registrationPanel_3' === selectedPanelId">
          <i *ngIf="'registrationPanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'registrationPanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Password Error/Locked Out</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form to email us or call us at 1-800-235-6877 for additional assistance if you are unable to successfully reset your password or you experience another password error. We would be happy to unlock or assist you in resetting your password.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- registration-login end -->
<!-- my-account start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'my-account'">
  <div ngbAccordionItem="myAccountPanel_0" [collapsed]="myAccountPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('myAccountPanel_0')">
        <div class="app-card-header" [class.selected]="'myAccountPanel_0' === selectedPanelId">
          <i *ngIf="'myAccountPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'myAccountPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Update Registered Email Address</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <ng-template>
          <span *ngIf="!isLoggedIn"><a href="javascript:void(0)" (click)="goToForgetPasswordPage()" *ngIf="!isLoggedIn">Click here</a> to reset your password and enter your email address.
            <br/><br/>
          </span>
          Login and click “<a href="javascript:void(0)" (click)="goToMyAccountPage()">My Account</a>” at the top right corner of the web page.
          <ul>
            <li>Click “<a href="javascript:void(0)" (click)="goToMyAccountPage()">My Account</a>”.</li>
            <li>Locate “Email” under the “Profile” section and click the “Change” link.</li>
            <li>Enter your new email address twice to verify accuracy.</li>
            <li>Enter your current password for security purposes.</li>
            <li>Click “Save”.</li>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="myAccountPanel_1" [collapsed]="myAccountPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('myAccountPanel_1')">
        <div class="app-card-header" [class.selected]="'myAccountPanel_1' === selectedPanelId">
          <i *ngIf="'myAccountPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'myAccountPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Update, Delete, or Add Shipping Address</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Login and click “<a href="javascript:void(0)" (click)="goToMyAccountPage()">My Account</a>” on the top right corner of the web page.
          <br/>
          Locate the “Address” section.
          <br/><br/>
          <b>Edit Address</b>
          <ul>
            <li>Click on “Edit” next to the address that you want to update.</li>
            <li>Make any changes desired.</li>
            <li>Click “Save”.</li>
          </ul>
          <br/>
          <b>Delete Address</b>
          <ul>
            <li>Click on “Delete” next to the address that you want to delete.</li>
            <li>Click on “I’m Sure” to confirm your intentions.</li>
          </ul>
          <br/>
          <b>Add Additional Address</b>
          <ul>
            <li>Click on the “+Add Address” button.</li>
            <li>Fill in the address fields using a physical address. We are unable to deliver to PO Boxes.</li>
            <li>Click “Save”.</li>
            <li>If you have multiple address click on “Make Primary” for the desired primary address. This can be useful if you plan to ship to multiple addresses periodically (i.e. summer house). The delete address option may be a better choice if you have moved and will not be using that shipping address again.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="myAccountPanel_2" [collapsed]="myAccountPanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('myAccountPanel_2')">
        <div class="app-card-header" [class.selected]="'myAccountPanel_2' === selectedPanelId">
          <i *ngIf="'myAccountPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'myAccountPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Set or Change Primary Shipping Address</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          When you have multiple shipping addresses, set the Primary Shipping Address so that future subscriptions and new orders and default to that address. We recommend deleting addresses that will no longer be used, like after moving.
          <br/><br/>
          <ul>
            <li>Login and click your name on the top right corner of the web page. Click “<a href="javascript:void(0)" (click)="goToMyAccountPage()">My Account</a>”.</li>
            <li>Click on “Make Primary” for the desired primary address.</li>
          </ul>
          <br/>
          *This option is also available during the checkout process.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="myAccountPanel_3" [collapsed]="myAccountPanel_3 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('myAccountPanel_3')">
        <div class="app-card-header" [class.selected]="'myAccountPanel_3' === selectedPanelId">
          <i *ngIf="'myAccountPanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'myAccountPanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Add or Change Payment Method</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          You can make payments using your credit card. Currently, we only accept Visa, Mastercard, and American Express payment methods.
          <br/><br/>
          You may add or delete payment methods. Payment methods cannot be edited. Multiple payment methods can be saved, and a Primary Payment Method may be selected for your convenience. We recommend deleting payment methods that will no longer be used.
          <br/><br/>
          Login and click "My Account" and locate the "Payment" section.
          <br/><br/>
          <b>Delete Payment Method</b>
          <ul>
            <li>Click on "Delete" next to the payment method that you would like to delete.</li>
            <li>Click on "I'm Sure" to confirm your intentions.</li>
          </ul>
          <br/>
          <b>Add Additional Payment</b>
          <ul>
            <li>Click on the "+Add Payment" button.</li>
            <li>Fill in the credit card number, expiration date, and CVC fields.</li>
            <li>Click "Save"</li>
            <li>If you have multiple Payment Methods, click on "Make Primary" for the desired primary payment method.</li>
          </ul>
          <br/>
          *This option is also available during the checkout process.
          <br/><br/>
          You may also contact your Veterinary Office for assistance with adding payment information.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- my-account end -->
<!-- pet-profile start -->
<div ngbAccordion="petProfilePanel" #petProfilePanel="ngbAccordion" [closeOthers]="true" *ngIf="selectedFaq.code == 'pet-profile'" >
  <div ngbAccordionItem="petProfilePanel_0" [collapsed]="petProfilePanel_0 !== selectedPanelId" [ID]="'petProfilePanel_0'">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('petProfilePanel_0')">
        <div class="app-card-header" [class.selected]="'petProfilePanel_0' === selectedPanelId">
          <i *ngIf="'petProfilePanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'petProfilePanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Add Pet Profile</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <!-- Description of Shops and Pets is changed according to UAT UI and function design -   -->
        <div>
          The Pets tab allows you add a new pet or update the spelling of your pet’s name. You may also shop for your pet from here. Shop right away for any Science Diet products. Your veterinarian will set up a nutrition plan to give you access to any Prescription Diet products that they recommend for your pet.
          <br/><br/>
          Select <a href="javascript:void(0)" (click)="goToMyPetsPage()">Pets</a> tab.
          <br/><br/>
          <ul>
            <li>Select “Add Pet”.</li>
            <li>Select Dog or Cat.</li>
            <li>Enter your pet's name.</li>
            <li>Click “Save”.</li>
          </ul>
          <br/>
          <b>Please contact your veterinarian to confirm the right Nutrition Plan(s) are set up on your Hill’s To Home account if you do not see the product your veterinarian recommended.</b>
        </div>
        <!-- End -->
      </div>
    </div>
  </div>
  <div ngbAccordionItem="petProfilePanel_1" [collapsed]="petProfilePanel_1 !== selectedPanelId" [id]="'petProfilePanel_1'">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('petProfilePanel_1')">
        <div class="app-card-header" [class.selected]="'petProfilePanel_1' === selectedPanelId">
          <i *ngIf="'petProfilePanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'petProfilePanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Change/Update Pet’s Name</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <!-- Description of Shops and Pets is changed according to UAT UI and function design -   -->
        <div>
          The Pets tab allows you to update the spelling of your pet’s name.
          <br/><br/>
          Select <a href="javascript:void(0)" (click)="goToMyPetsPage()">Pets</a> tab.
          <br/><br/>
          <b>Select your Pet</b>
          <ul>
            <li>Update your pet's name.</li>
            <li>Click “Save”.</li>
          </ul>
          <br/>
          <b>Please contact your veterinarian to confirm the right Nutrition Plan(s) are set up on your Hill’s To Home account if you do not see the product your veterinarian recommended.</b>
        </div>
        <!-- End -->
      </div>
    </div>
  </div>
  <div ngbAccordionItem="petProfilePanel_2" [collapsed]="petProfilePanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('petProfilePanel_2')">
        <div class="app-card-header" [class.selected]="'petProfilePanel_2' === selectedPanelId">
          <i *ngIf="'petProfilePanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'petProfilePanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Shop Plan</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse >
      <div ngbAccordionBody class="p-3">
        <!-- Description of Shops and Pets is changed according to UAT UI and function design -   -->
        <div>
          Shop for your pet through the <a href="javascript:void(0)" (click)="goToShopPage()">Shop</a> menu. Shop for any Science Diet products according to your veterinarians recommendations. Your veterinarian will set up a specific nutrition plan to give you access to any Prescription Diet products that they recommend for your pet.
          <br/><br/>
          <b>Shop Science Diet Products:</b>
          <br/>
          These products are available to shop without a nutrition plan. Click on “View Science Diet Products” to shop for any of these products at any time.
          <ul>
            <li>Click on View Science Diet Products to start an order or subscription.</li>
            <li>Use the available filters options on the left window pane to narrow your search results by Brand, Product Line, and Product Form.</li>
            <li>Use the Sort option in the upper right hand corner of the product display window to sort alphabetically by product name or by price.</li>
            <li>Click Add to Cart on any products you would like to order.</li>
            <li>Select Continue Shopping to add multiple items or View Cart when you are ready to check out.</li>
          </ul>
          <br/>
          <b>Prescription Diet Products:</b>
          <br/>
          If your veterinarian has set up a nutrition plan to allow your pet to purchase a Prescription Diet Plan, it will be listed with the option to “Shop Plan”.
          <ul>
            <li>Click on Shop Plan to start an order or subscription.</li>
            <li>Click Add to Cart on any products you would like to order.</li>
            <li>Select Continue Shopping to add multiple items or View Cart when you are ready to check out.</li>
          </ul>
          <br/>
          <b>If the Prescription Diet products you expected to order are not set up as a Nutrition Plan or the status is not Active, contact your veterinarian.</b>
        </div>
        <!-- End -->
      </div>
    </div>
  </div>
  <div ngbAccordionItem="petProfilePanel_3" [collapsed]="petProfilePanel_3 !== selectedPanelId" [id]="'petProfilePanel_3'">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('petProfilePanel_3')">
        <div class="app-card-header" [class.selected]="'petProfilePanel_3' === selectedPanelId">
          <i *ngIf="'petProfilePanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'petProfilePanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Incorrect Prescription Diet Product Listed</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Nutrition Plans are recommended and approved by your veterinarian, allowing you the access to shop the product assortment based on your pets individual and special needs. If you believe the products in your Nutrition plan are different than discussed with your veterinarian, please reach out to your veterinarian or send us a <a href="javascript:void(0)" (click)="goToContactUsPage()">message</a>.
          <br/><br/>
          *Nutrition Plan(s) are not necessary to shop Science Diet products
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="petProfilePanel_4" [collapsed]="petProfilePanel_4 !== selectedPanelId" [id]="'petProfilePanel_4'">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('petProfilePanel_4')">
        <div class="app-card-header" [class.selected]="'petProfilePanel_4' === selectedPanelId">
          <i *ngIf="'petProfilePanel_4' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'petProfilePanel_4' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Missing Prescription Diet Nutrition Plan</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          All Prescription Diet Nutrition Plans are recommended and approved by your veterinarian, allowing you the access to shop the product assortment based on your pets individual and special needs. <b>If you believe your veterinarian has not setup the nutrition plan for a Prescription Diet product discussed, please contact your veterinarian to confirm the right Nutrition Plan(s) are set up on your Hill’s to Home account.</b>
          <br/><br/>
          *Nutrition Plan(s) are not necessary to shop Science Diet products
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="petProfilePanel_5" [collapsed]="petProfilePanel_5 !== selectedPanelId" [id]="'petProfilePanel_5'">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('petProfilePanel_5')">
        <div class="app-card-header" [class.selected]="'petProfilePanel_5' === selectedPanelId">
          <i *ngIf="'petProfilePanel_5' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'petProfilePanel_5' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Nutrition Plan: Ended or Expiring Soon</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Please contact your veterinarian if your Nutrition Plan status says “Ended” or if it is getting ready to expire soon. Your veterinarian will set up a new Nutrition Plan for you. Once your new Nutrition Plan is set up, select Shop Plan to place orders or set up a new subscription.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- pet-profile end -->
  <!-- order start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'order'">
  <div ngbAccordionItem="orderPanel_0" [collapsed]="orderPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_0')">
        <div class="app-card-header" [class.selected]="'orderPanel_0' === selectedPanelId">
          <i *ngIf="'orderPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">New Order/One Time Shipment</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Shop for your pet through the Shop menu and add your pets products to your cart. <a href="javascript:void(0)" (click)="goToShopPlanFAQPage()">Click here</a> for more details on how to Shop Products.
          Click on the Cart link or icon in the upper right corner of the webpage or click on View Cart after adding a product to the cart while shopping.
          <br/><br/>
          <b>Steps</b>
          <ul>
            <li>
              Review product(s) in cart & update quantities. Click “x” above product name to remove product.
              <br/>
              <b>Important Note:</b> Change the frequency from the default of “Every 4 Weeks” to “One time shipment” if you do not want the order to be set up for autoshipment as a subscription.
            </li>
            <li>Enter Promo Code if applicable</li>
            <li>Select Proceed to Checkout</li>
            <li>Enter or select Shipping Address</li>
            <li>
              Enter or select payment option (visa, mastercard, or American Express credit cards accepted)
              <br/>
              Select learn more about credit card holds if desired
            </li>
            <li>
              Select shipping time preference (Standard or future-dated)
              <br/>
              Future date first order by selecting the calendar icon next to “Delivery On”
            </li>
            <li>Place Order</li>
            <li>Order Confirmation number is displayed. Additionally, an order confirmation is emailed to you for your convenience.</li>
          </ul>
          <br/>
          *Orders received after 1pm local time may not be shipped until the following day and add an additional day to the delivery timing.
          <br/>
          **Expedited shipping is not available at this time due to an increased demand with our shipping partners.
          <br/><br/>
          <b>Helpful Tip:</b> Choose the convenience of auto-shipments and enjoy up to 35% off your first autoship order and 5% off all future autoshipments. Choose your autoship frequency between 2-12 weeks and remain in control with the ability to change the frequency, skip an order, adjust the next order date, or cancel anytime.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_1" [collapsed]="orderPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_1')">
        <div class="app-card-header" [class.selected]="'orderPanel_1' === selectedPanelId">
          <i *ngIf="'orderPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Cancel Order</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Orders in “Pending” status may be cancelled within 5 minutes of the order being submitted.
          <br/><br/>
          <ul>
            <li>Click the <a href="javascript:void(0)" (click)="goToOrdersPage()">Orders tab</a> at the top of webpage.</li>
            <li>Click the order number you wish to cancel.</li>
            <li>Click Cancel Order, then Confirm.</li>
          </ul>
          <br/>
          *Please note that "In Progress" orders cannot be cancelled online. Orders that are “In Progress” can be refused at delivery or returned for a refund. <a href="javascript:void(0)" (click)="goToReturnsFAQPage()">Click here</a> for information on returns.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_2" [collapsed]="orderPanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_2')">
        <div class="app-card-header" [class.selected]="'orderPanel_2' === selectedPanelId">
          <i *ngIf="'orderPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Skip Next Order</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Our Hill's to Home program allows you full control of when and how often you would like to receive your deliveries. Skip your next order by selecting the <a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Subscriptions</a> tab:
          <br/><br/>
          <ul>
            <li>Select Skip Next Order.</li>
            <li>Select I’m Sure to confirm.</li>
            <li>Select Ok.</li>
          </ul>
          <br/>
          Your next order will be skipped.
          <br/><br/>
          <b>Helpful Tip:</b> Make your subscription meet your unique needs! Consider changing the frequency of your subscription if you are receiving food too often. If you are ordering multiple quantities, you may also lower the quantity or even order a smaller size if available!
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_3" [collapsed]="orderPanel_3 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_3')">
        <div class="app-card-header" [class.selected]="'orderPanel_3' === selectedPanelId">
          <i *ngIf="'orderPanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Track Order</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          <ul>
            <li>Click “Orders” at the top of web page once logged into HillsToHome.com.</li>
            <li>Click on the order that you would like to track and the order details field will expand to display the UPS Tracking # with a clickable link.</li>
            <li>Click on the UPS tracking number to be directed to UPS.com with tracking details of your order.</li>
            <li>If there is no tracking number provided, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> email form or call us for additional details.</li>
          </ul>
          <br/>
          <b>Helpful Tip:</b> Email tracking notifications are emailed once the order has shipped, containing your UPS tracking number. You may also open your email and click on the link provided.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_4" [collapsed]="orderPanel_4 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_4')">
        <div class="app-card-header" [class.selected]="'orderPanel_4' === selectedPanelId">
          <i *ngIf="'orderPanel_4' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_4' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Order Delayed or Missing</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          <b>Estimated Delivery Timing</b>
          <ul>
            <li>Standard Shipping: 2-3 business days (Free)</li>
            <li>Holidays and Inclement Weather:  may impact estimated arrival times by an additional 1-2 business days</li>
            <li>Exceptions: Shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional business days to arrive</li>
          </ul>
          <b>Important Note:</b> Orders placed after 1:00 pm local time may be shipped the following business day and add one additional day to the estimated delivery timing.
          <br/><br/>
          <b>Order Tracking</b>
          <ul>
            <li>Click “<a href="javascript:void(0)" (click)="goToOrdersPage()">Orders</a>” at the top of web page once logged into HillsToHome.com.</li>
            <li>Click on the order that you would like to track and the order details field will expand to display the UPS Tracking # with a clickable link.</li>
            <li>Click on the UPS tracking number to be directed to UPS.com with tracking details of your order.</li>
            <li>If there is no tracking number provided, please fill out the contact us email form or call us for additional details.</li>
          </ul>
          <br/>
          <b>Helpful Tip:</b> Email tracking notifications are emailed once the order has shipped, containing your UPS tracking number. You may also open your email and click on the link provided.
          <br/><br/>
          <b>If you need additional assistance due to a delayed or missing order please <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a>.</b>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_5" [collapsed]="orderPanel_5 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_5')">
        <div class="app-card-header" [class.selected]="'orderPanel_5' === selectedPanelId">
          <i *ngIf="'orderPanel_5' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_5' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Change Next Scheduled Order Date</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Our Hill's to Home program allows you full control of when and how often you would like to receive your deliveries.
          <ul>
            <li>Select “<a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Subscription</a>”, located on the top of the Hill's to Home page once logged in.</li>
            <li>Select the subscription you would like to update and subscription details field will expand.</li>
            <li>Select “Edit Subscription”.</li>
            <li>Select the calendar icon in the “Next Delivery Date” field. Dates within 5 business days are not available to select, but selecting the first available date will initiate the order to be processed within 1 business day with the estimated delivery timing of 2-3 business days.</li>
            <li>Select “Save”.</li>
            <li>Change your next scheduled order date.</li>
          </ul>
          <br/>
          <a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Click here</a> to be taken directly to your Subscriptions page.
          <br/><br/>
          <b>Helpful Tip:</b> Make your subscription meet your unique needs! Consider changing the frequency of your subscription if you are receiving food too often. If you are ordering multiple quantities, you may also lower the quantity or even order a smaller size if available!
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_7" [collapsed]="orderPanel_7 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_7')">
        <div class="app-card-header" [class.selected]="'orderPanel_7' === selectedPanelId">
          <i *ngIf="'orderPanel_7' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_7' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Unexpected Order Inquiry</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          We are sorry that you received an order you were not expecting. Hill's Pet Nutrition has a 100% satisfaction guarantee on all our products and we would be happy to assist you in returning the order and fully refunding you.
          <br/><br/>
          <!-- <b>Return Process</b>
          <br/>
          Please <a href="javascript:void(0)" (click)="goToOrdersPage()">click here</a> to initiate a return request
          <br/><br/> -->
          <b>Preventing Future Unexpected Orders</b>
          <ol>
            <li>
              <b>Unintentional Autoship Selection</b>
              <br/>
              Most Hill’s To Home pet parents prefer the convenience of autoship along with the added discount. During the checkout process, there is a frequency field that allows you to select auto shipments between 2-12 weeks or to choose a one time shipment instead. This field is defaulted to the most popular delivery frequency selection of 4 weeks. This field must be changed to One time shipment or another frequency if desired.
              <br/><br/>
              Check the subscriptions tab to verify whether a subscription was inadvertently set up instead of a one time shipment.
              <a href="javascript:void(0)" (click)="goToCancelSubscriptionFAQPage()">Click here</a> for instructions on cancelling subscriptions.
              Verify that the subscription was successfully cancelled by checking that the subscription status says “Cancelled”.
              <br/><br/>
            </li>
            <li>
              <b>Order Frequency Error</b>
              <br/>
              Occasionally, the wrong frequency is inadvertently selected during the checkout process or the default of 4 weeks is not updated. Check the frequency column in the subscriptions tab to ensure that it is set to your preferences. If not, simply edit the subscription and update the frequency. Consider pushing the next order date on the subscription if you currently have more food than expected, to get your frequency back on track for your needs.
              <a href="javascript:void(0)" (click)="goToChangeSubscriptionFrequencyFAQPage()">Click here</a> for detailed instructions on updating the frequency of a subscription.
              <br/><br/>
              Helpful Tip: Consider choosing the convenience of auto-shipments to continue receiving your 5% off all future auto-shipments. Update your autoship frequency between 2-12 weeks to meet you and your pet’s needs while remaining in control of the timing of your subscriptions. You can also skip an order, adjust the next order date (earlier or later), or cancel anytime. As you find just the right frequency for you, the convenience of autoship makes purchasing your pet’s food hassle free.
              <br/><br/>
            </li>
            <li>
              <b>Duplicate Subscriptions</b>
              <br/>
              Check the subscriptions tab and review any subscriptions listed. The status column will indicate whether each subscription is Active or Cancelled. If there are multiple active subscriptions, cancel any duplicate or erroneous subscriptions by selecting the subscription that you want to cancel and click on Cancel Subscription button.
              <a href="javascript:void(0)" (click)="goToCancelSubscriptionFAQPage()">Click here</a> for more detailed instructions on cancelling subscriptions.
              Verify that the subscription was successfully cancelled by checking that the subscription status says “Cancelled”.
              <br/><br/>
            </li>
            <li>
              <b>Other</b>
              <br/>
              If you verified that you do not have an active subscription or a duplicate subscription, <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> so that we can further assist you.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="orderPanel_8" [collapsed]="orderPanel_8 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('orderPanel_8')">
        <div class="app-card-header" [class.selected]="'orderPanel_8' === selectedPanelId">
          <i *ngIf="'orderPanel_8' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'orderPanel_8' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Order vs Subscription</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Orders can be placed as a one time shipment or set up on a recurring frequency.
          <br/><br/>
          One time shipments are not recurring orders and are utilized when wanting to place your pet food order on an as needed basis. One time shipments do not receive the subscription discount offer.
          <br/><br/>
          Subscriptions allow you to set your account to automatically generate recurring orders to ship to you on a weekly frequency of your choosing within 2-12 weeks for convenience. To get started, we currently are offering up to 35% off your first order.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- order end -->
<!-- subscriptions start -->
<div ngbAccordion ="subscriptionsPanel" #subscriptionsPanel="ngbAccordion"[closeOthers]="true" *ngIf="selectedFaq.code == 'subscriptions'">
  <div ngbAccordionItem="subscriptionsPanel_0" [collapsed]="subscriptionsPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('subscriptionsPanel_0')">
        <div class="app-card-header" [class.selected]="'subscriptionsPanel_0' === selectedPanelId">
          <i *ngIf="'subscriptionsPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'subscriptionsPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">New Subscription/Auto-Shipments</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <b>Choose Auto-Shipments to Maximize Convenience and Discounts</b>
        <br/>
        Enjoy the convenience of autoshipments and take advantage of autoship order discounts. Take advantage of extra discounts just for using the autoship option. Customize the frequency of your deliveries between 2-12 weeks so to fit your pet’s needs. Maximum flexibility is available for you to change the frequency of your subscription, skip an order, or adjust the next order date anytime.
        <br/><br/>
        <b>Set Up A Subscription for Auto-Shipments</b>
        <br/>
        Shop for your pet through the Shop menu and add your pets products to your cart. 
        <a href="javascript:void(0)" (click)="goToShopPlanFAQPage()">Click here</a> for more details on how to Shop Products.
        Click on the Cart link or icon in the upper right corner of the webpage or click on View Cart after adding a product to the cart while shopping.
        <br/><br/>
        <b>Steps</b>
        <ul>
          <li>Review product(s) in cart & update quantities. Click “x” above product name to remove product.</li>
          <li>Select shipping frequency between 2-12 weeks. Note: Default frequency is “Every 4 Weeks” if no changes are made.</li>
          <li>Enter Promo Code if applicable.</li>
          <li>Select Proceed to Checkout.</li>
          <li>Enter or select Shipping Address.</li>
          <li>
            Enter or select payment option (visa, mastercard, or American Express credit cards accepted)
            <ul>
              <li>Select <a href="javascript:void(0)" (click)="goToCreditCardHoldsPage()">learn more about credit card holds</a> if desired.</li>
            </ul>
          </li>
          <li>
            Select shipping time preference (Standard, expedited, or future date).
            <ul>
              <li>Future date first order by selecting the calendar icon next to “Delivery On”.</li>
            </ul>
          </li>
          <li>
            Place Order.
          </li>
          <li>
            Order Confirmation number is displayed. Additionally, an order confirmation is emailed to you for your convenience.
          </li>
        </ul>
        <br/>
        *Orders received after 1pm local time may not be shipped until the following day and add an additional day to the delivery timing.
      </div>
    </div>
  </div>
  <div ngbAccordionItem="subscriptionsPanel_1" [collapsed]="subscriptionsPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('subscriptionsPanel_1')">
        <div class="app-card-header" [class.selected]="'subscriptionsPanel_1' === selectedPanelId">
          <i *ngIf="'subscriptionsPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'subscriptionsPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Cancel Subscription</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Our Hill's to Home program allows you full control of when and how often you would like to receive your deliveries. View your Active Subscriptions and open them one at a time to determine if you need to cancel any of them. Cancelling your subscription does not remove you from the Hill’s To Home program. You can start and stop a subscription at any time based on your pets needs, for example you may need to cancel a subscription if your veterinarian makes a new nutritional recommendation for your pet.
          <br/><br/>
          <ul>
            <li>Select “<a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Subscription</a>”, located on the top of the Hill's to Home page once logged in.</li>
            <li>Select the subscription you would like to update and subscription details field will expand.</li>
            <!-- Text change according to the current UI -   -->
            <li>Select "More Options". A menu will display to select Cancel Subscription.</li>
            <!-- End -->
            <li>Select Reason for Cancellation, then click “I’m Sure”.</li>
            <li>Select “Ok” to successful cancellation.</li>
          </ul>
          <br/>
          *Prescription Diet products require an active nutrition plan to place orders. Nutrition Plan(s) are not necessary to shop Science Diet products.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="subscriptionsPanel_2" [collapsed]="subscriptionsPanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('subscriptionsPanel_2')">
        <div class="app-card-header" [class.selected]="'subscriptionsPanel_2' === selectedPanelId">
          <i *ngIf="'subscriptionsPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'subscriptionsPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Change Subscription Frequency</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Our Hill's to Home program allows you full control of when and how often you would like to receive your deliveries. Make your subscription meet your unique needs. Consider changing the frequency of your subscription if you are receiving food too often. If you are ordering multiple quantities, you may also lower the quantity or even order a smaller size if available.
          <br/><br/>
          <ul>
            <li>Select “<a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Subscription</a>”, located on the top of the Hill's to Home page once logged in.</li>
            <li>Select the subscription you would like to update, and subscription details field will expand.</li>
            <li>Select “Edit Subscription”.</li>
            <li>Select the number of weeks between each autoship order ranging from 2-12 weeks, located under the “Frequency” field.</li>
            <li>Select the next scheduled delivery to ensure you are receiving the next autoship order when needed for your pet.</li>
            <li>Select “Save”.</li>
          </ul>
          <br/>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="subscriptionsPanel_3" [collapsed]="subscriptionsPanel_3 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('subscriptionsPanel_3')">
        <div class="app-card-header" [class.selected]="'subscriptionsPanel_3' === selectedPanelId">
          <i *ngIf="'subscriptionsPanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'subscriptionsPanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Add or Change Subscription Product & Quantities</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Our Hill's to Home program allows you full control of when and how often you would like to receive your deliveries.Make your subscription meet your unique needs! Consider changing the frequency of your subscription if you are receiving food too often. If you are ordering multiple quantities, you may also lower the quantity or even order a smaller size if available!
          <br/><br/>
          *Only products tied to the original nutrition plan may be added to a current subscription. If a new nutrition plan is created by your veterinarian, please cancel the current subscription and create new one.
          <br/><br/>
          <b>Change Subscription Product(s)</b>
          <ul>
            <li>Select “<a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Subscription</a>”, located on the top of the Hill's to Home page once logged in.</li>
            <li>Select the subscription you would like to update and subscription details field will expand.</li>
            <!-- Text changed according to the current UI -   -->
            <li>Select “More Options”.  A menu will display to select “Change Product”.</li>
            <!-- End -->
            <li>Use the available filters options on the left window pane to narrow your search results by Brand, Product Line, and Product Form.</li>
            <li>Use the Sort option in the upper right hand corner of the product display window to sort alphabetically by product name or by price.</li>
            <li>Click “Select” for the changed product.</li>
            <li>Click “Ok”</li>
          </ul>
          <br/>
          <b>Change Subscription Quantity</b>
          <ul>
            <li>Select “<a href="javascript:void(0)" (click)="goToSubscriptionsPage()">Subscription</a>”, located on the top of the Hill's to Home page once logged in.</li>
            <li>Select the subscription you would like to update and subscription details field will expand.</li>
            <li>Select “Edit Subscription”.</li>
            <li>Select a new quantity for future autoship orders, located under the “Quantity” field.</li>
            <li>Select “Save”.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="subscriptionsPanel_4" [collapsed]="subscriptionsPanel_4 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('subscriptionsPanel_4')">
        <div class="app-card-header" [class.selected]="'subscriptionsPanel_4' === selectedPanelId">
          <i *ngIf="'subscriptionsPanel_4' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'subscriptionsPanel_4' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Subscription End Dates</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Prescription Diet subscriptions created after 9/13/2021 will remain active as long as the Nutrition Plan your veterinarian created to authorize these products is still active (unless you choose to cancel your subscription).  When your veterinarian renews your pet's Nutrition Plan, your subscription will automatically renew as well - one less thing for you to worry about!
          <br/><br/>
          Legacy subscriptions created before 9/13/2021 will NOT automatically continue when your veterinarian renews your Nutrition Plan. If your subscription was created before 9/13/2021, you can cancel your existing subscriptions and create new ones that will automatically link to your Nutrition Plan end date.  Or, you can wait until your existing subscriptions end and then create new ones. <a href="javascript:void(0)" (click)="goToNewSubscriptionFAQPage()">Click here</a> for more information on how to create a new subscription.
          <br/>
          <br/>
          Note that Nutrition Plans are not necessary to shop Science Diet products but are required to place orders for Prescription Diet foods.
          <br/>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- subscriptions end -->
  <!-- returns-refunds start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'returns-refunds'">
  <div ngbAccordionItem="returnsRefundsPanel_0" [collapsed]="returnsRefundsPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('returnsRefundsPanel_0')">
        <div class="app-card-header" [class.selected]="'returnsRefundsPanel_0' === selectedPanelId">
          <i *ngIf="'returnsRefundsPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'returnsRefundsPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Return Requests & Labels</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <b>Policy</b>
        <br/>
        Hill's Pet Nutrition has a 100% satisfaction guarantee on all our products. If for any reason you are not satisfied with any product, you may return the item(s) for a full refund. Returns are processed back to the original method of payment within 10 business days once initiated.
        <br/><br/>
        *For product inquiries and comments, please visit <a href="https://www.hillspet.com/contact-us" target="_blank">https://www.hillspet.com/contact-us</a> or contact our Consumer Affairs Department and call toll-free from anywhere in North America at: 1-800-445-5777 from 8 a.m. to 5 p.m. CST, Monday through Friday.
        <br/><br/>
        <b>Steps</b>
        <br/>
        At Hill’s discretion, a refund may be issued without requiring a physical return. Labels will be emailed to you for returns requiring a physical return. Returns not requiring a physical return should be discarded.
        <br/><br/>
        Labels are sent to your registered email address. Please print the label and place it on the returning package(s) and drop off at any UPS facility.
        <br/><br/>
        Please use the return button on your orders page to initiate a request for refund. Follow the return prompts including the product, the quantities, and the reason for return. If you have any questions or need support, please use the <a href="javascript:void(0)" (click)="goToContactUsPage()">Contact Us</a> form.
        <br/><br/>
        Helpful Tip: Please note that the product must be received by the specified date within your email. Refunds are processed back to the original method of payment within 10 business days after returned product has been shipped back and received.
      </div>
    </div>
  </div>
  <div ngbAccordionItem="returnsRefundsPanel_1" [collapsed]="returnsRefundsPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('returnsRefundsPanel_1')">
        <div class="app-card-header" [class.selected]="'returnsRefundsPanel_1' === selectedPanelId">
          <i *ngIf="'returnsRefundsPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'returnsRefundsPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">100% Satisfaction Guarantee/Return Policy</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Hill's Pet Nutrition has a 100% satisfaction guarantee on all our products. If for any reason you are not satisfied with any product, you may return the item(s) for a full refund. Returns are processed back to the original method of payment within 10 business days once initiated.
          <br/><br/>
          <b>Helpful Tip:</b> For product inquiries and comments, please visit <a href="https://www.hillspet.com/contact-us" target="_blank">https://www.hillspet.com/contact-us</a> or contact our Consumer Affairs Department and call toll-free from anywhere in North America at: 1-800-445-5777 from 8 a.m. to 5 p.m. CST, Monday through Friday.
          <br/><br/>
          For additional assistance, questions, or comments, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="returnsRefundsPanel_2" [collapsed]="returnsRefundsPanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('returnsRefundsPanel_2')">
        <div class="app-card-header" [class.selected]="'returnsRefundsPanel_2' === selectedPanelId">
          <i *ngIf="'returnsRefundsPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'returnsRefundsPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Return/Refund Status Inquiry</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Returns are processed back to the original method of payment within 10 business days once initiated.
          <br/><br/>
          If you have not received a refund 10 business days after your original request, please <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> for additional assistance.
          <br/><br/>
          <b>Refund Check</b>
          <br/>
          Refund Checks can take up to 3-4 weeks and generally are mailed using USPS.
          <br/><br/>
          <b>Helpful Tip:</b> Funds cannot be returned to a new or inactive credit/ debit card or when changes to payment method have been made (e.g. new card, removed old card, expired). Please <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> for additional assistance with your refund.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- returns-refunds end -->
  <!-- billing start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'billing'">
  <div ngbAccordionItem="billingPanel_0" [collapsed]="billingPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_0')">
        <div class="app-card-header" [class.selected]="'billingPanel_0' === selectedPanelId">
          <i *ngIf="'billingPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Authorization Hold Inquiry</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <b>What is an authorization Hold (Preauthorization)?</b>
        <br/>
        Credit & Debit Card (Authorization) Holds is the practice within the banking industry of verifying that funds are available in a customer account before an electronic transaction is initiated. <b>This is a hold of funds and not a charge or transaction.</b> The charge is separate from the authorization hold. This process reduces the available balance on the card until either the merchant clears the transaction or the sales transaction is complete and the authorization hold falls off. It is possible for both a hold and charge to appear on the card simultaneously.
        <br/><br/>
        <b>Authorization Hold Timing</b>
        <br/>
        In the case of debit cards, authorization holds can fall off the account, thus rendering the balance available again, anywhere from one to eight business days after the transaction date, depending on the bank's policy. In the case of credit cards, holds may last as long as thirty days, depending on the issuing bank. Transactions may be withdrawn but in most cases will not show up as a deposit on your statement, but will instead be directly added back to your available balance automatically due to it only being a “temporary charge”. The merchant charge/transaction may occur prior to the release of the authorization hold causing the timing of the hold and the charge to overlap until the authorization hold falls off.
        <br/><br/>
        If you have any questions about your bank's hold policy, please call the number on the back of your credit card.
        <br/><br/>
        <b>Helpful Tip:</b> An estimated sales tax amount is shown at checkout. This is estimated using the highest rate for your shipping address state, and could potentially reflect an inaccurate tax. When your order ships and your credit card is charged, the estimated tax is adjusted to the accurate tax based on your specific shipping address. The invoice included with your shipment will reflect this accurate sales tax.
        <br/><br/>
        For additional assistance, questions, or comments, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_1" [collapsed]="billingPanel_1 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_1')">
        <div class="app-card-header" [class.selected]="'billingPanel_1' === selectedPanelId">
          <i *ngIf="'billingPanel_1' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_1' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Multiple Authorization Holds and/or Charges</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Each order submitted results in an authorization hold on the credit or debit card as well as a charge. The authorization hold is temporary and will eventually fall off. The merchant charge/transaction may occur prior to the release of the authorization hold causing the timing of the hold and the charge to overlap until the authorization hold falls off. It is possible for both a hold and charge to appear on the card simultaneously.
          <br/><br/>
          <b>What is an authorization hold (Preauthorization)?</b>
          <br/>
          A Credit & Debit Card (Authorization) Hold is the practice within the banking industry of verifying that funds are available in a customer account before an electronic transaction is initiated. <b>This is a hold of funds and not a charge or transaction.</b> The charge is separate from the authorization hold. This process reduces the available balance on the card until either the merchant clears the transaction or the sales transaction is complete and the authorization hold falls off.
          <br/><br/>
          <b>Authorization Hold Timing</b>
          <br/>
          In the case of debit cards, authorization holds can fall off the account, thus rendering the balance available again, anywhere from one to eight business days after the transaction date, depending on the bank's policy. In the case of credit cards, holds may last as long as thirty days, depending on the issuing bank. Transactions may be withdrawn but in most cases will not show up as a deposit on your statement, but will instead be directly added back to your available balance automatically due to it only being a “temporary charge”.
          <br/><br/>
          If you have any questions about your bank's hold policy, please call the number on the back of your credit card.
          <br/><br/>
          <b>Helpful Tip:</b> An estimated sales tax amount is shown at checkout. This is estimated using the highest rate for your shipping address state, and could potentially reflect an inaccurate tax. When your order ships and your credit card is charged, the estimated tax is adjusted to the accurate tax based on your specific shipping address. The invoice included with your shipment will reflect this accurate sales tax.
          <br/><br/>
          For additional assistance, questions, or comments, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_2" [collapsed]="billingPanel_2 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_2')">
        <div class="app-card-header" [class.selected]="'billingPanel_2' === selectedPanelId">
          <i *ngIf="'billingPanel_2' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_2' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">State Retail Delivery Fee</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          If your state has a retail delivery fee, that fee will be listed on your invoice as a separate line item. Hill's to Home is required by law to collect this fee and pass it along to your state.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_3" [collapsed]="billingPanel_3 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_3')">
        <div class="app-card-header" [class.selected]="'billingPanel_3' === selectedPanelId">
          <i *ngIf="'billingPanel_3' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_3' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Estimated Tax & Final Charges</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          An estimated sales tax amount is shown at checkout. This is estimated using the highest rate for your shipping address state, and could potentially reflect an inaccurate tax. When your order ships and your credit card is charged, the estimated tax is adjusted to the accurate tax based on your specific shipping address.
          <br/><br/>
          The packing slip included with your shipment will reflect this accurate sales tax. The merchant charge/transaction may occur prior to the release of the authorization hold causing the timing of the hold and the charge to overlap until the authorization hold falls off. It is possible for both a hold and charge to appear on the card simultaneously with slightly different dollar values, due to the estimated tax adjustment once the order has invoiced.
          <br/><br/>
          The sale of food is actually between the Veterinarian and pet parent; Hill's to Home facilitates and fulfills the order. Because the subscription discount the vet offers the pet parents is fully reimbursed to the vet by Hill's, it is actually considered a manufacturer's coupon/discount in sales tax. In most states, a manufacturer's coupon does not reduce the sales tax in the same way that a regular coupon/discount would. Thus, the sales tax that is charged on the sale between the vet and the pet parent is charged off the pre-discount price.
          <br/><br/>
          Special tax considerations are also accounted for Colorado, New York, South Carolina and Wisconsin.
          <br/><br/>
          For additional assistance, questions, or comments, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_4" [collapsed]="billingPanel_4 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_4')">
        <div class="app-card-header" [class.selected]="'billingPanel_4' === selectedPanelId">
          <i *ngIf="'billingPanel_4' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_4' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Tax Dispute</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          When your order is first placed, an estimated sales tax amount is shown at checkout. This is estimated using the highest rate for your shipping address state, and could potentially reflect an inaccurate tax. When your order ships and your credit card is charged, the estimated tax is adjusted to the accurate tax based on your specific shipping address. The packing slip included with your shipment will reflect this accurate sales tax.
          <br/><br/>
          If you believe the tax amount charged was incorrect, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_5" [collapsed]="billingPanel_5 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_5')">
        <div class="app-card-header" [class.selected]="'billingPanel_5' === selectedPanelId">
          <i *ngIf="'billingPanel_5' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_5' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Rebate Inquiry & Status Check</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          All Hill’s to Home participants are notified via email when they qualify for a rebate.
          <br/><br/>
          Don’t forget to visit your marketing preferences located under “ My Account” and check the box stating: I'd like to receive communication from Hill's about products, offers and programs.
          <br/><br/>
          Please allow 6-8 weeks to process your rebate. If you still have not received your rebate after 8 weeks or you have additional questions, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_6" [collapsed]="billingPanel_6 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_6')">
        <div class="app-card-header" [class.selected]="'billingPanel_6' === selectedPanelId">
          <i *ngIf="'billingPanel_6' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_6' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Promotions & Discounts Inquiry or Dispute</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Please review your order confirmation page or email for details showing all pricing savings and discounts. The packing slip included with your shipment will also reflect these details.
          <br/><br/>
          <b>Subscription Discount Savings</b>
          <br/>
          Enjoy the convenience of autoshipments and take advantage of autoship order discounts. Take advantage of extra discounts just for using the autoship option.
          <br/><br/>
          If you believe the discount or promotion was not applied correctly or if you have additional questions, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_7" [collapsed]="billingPanel_7 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_7')">
        <div class="app-card-header" [class.selected]="'billingPanel_7' === selectedPanelId">
          <i *ngIf="'billingPanel_7' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_7' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Pricing Inquiry or Dispute</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          Please review your order confirmation email for more pricing details. You may also view your order invoice on HillsToHome.com by following the steps below:
          <br/><br/>
          <ul>
            <li>Click “Orders” at the top of web page once logged into HillsToHome.com.</li>
            <li>Click on the order number, followed by the "Invoice" button.</li>
            <li>Hill's to Home is a platform developed for Veterinarians in order to provide better patient care with a convenient eCommerce option for you, the pet parent. The retail price may vary from market to market, including Hill's to Home. Retailers, like your veterinarian, are free to sell Hill's® products at the price of their choosing as Hill’s cannot set prices. Please contact your veterinarian for questions regarding their pricing.</li>
          </ul>
          <br/>
          If you believe the pricing was not charged correctly, or if you have additional questions, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
          <br/><br/>
          <b>Helpful Tip: Invoice may not be available for viewing until the order has fully processed.</b>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_8" [collapsed]="billingPanel_8 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_8')">
        <div class="app-card-header" [class.selected]="'billingPanel_8' === selectedPanelId">
          <i *ngIf="'billingPanel_8' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_8' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Invoice Request</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          To print a copy of your invoice complete with your pet's name for your personal records or for insurance submission purposes, please visit <a href="https://hillstohome.com" target="_blank"> hillstohome.com</a>
          <ul class="mt-2">
            <li>Once logged in, click the Orders tab at the top of the webpage.</li>
            <li>Select the order number.</li>
            <li>Select "Invoice"</li>
            <li>Select option to print or save the document as PDF.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div ngbAccordionItem="billingPanel_9" [collapsed]="billingPanel_9 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('billingPanel_9')">
        <div class="app-card-header" [class.selected]="'billingPanel_9' === selectedPanelId">
          <i *ngIf="'billingPanel_9' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'billingPanel_9' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Shipping Fee Inquiry & Dispute</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          <b>Estimated Delivery Timing</b>
          <ul>
            <li>Standard Shipping: 2-3 business days</li>
            <li>Major Holidays: carriers often experience delays to estimated arrival times by an additional 1-2 business days during the weeks of and adjacent to major Holidays (Memorial Day, Independence Day, Labor Day, Thanksgiving, Christmas, New Years)</li>
          </ul>
          <br/>
          <b>Shipping Fees</b>
          <ul>
            <li>Standard Shipping: <span class="text-underline">Free</span> (in the continental US)</li>
            <li>*Alaska & Hawaii: $1.00 Surcharge per item</li>
          </ul>
          <br/>
          <b>Important Exceptions</b>
          <ul>
            <li>Orders placed after 1:00 pm local time may be shipped the following business day and add one additional day to the estimated delivery timing.</li>
            <li>Shipments to Alaska, Hawaii, and some remote areas in the continental US may take additional business days to arrive</li>
            <li>Alaska & Hawaii Surcharge: $1.00 per item</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- billing end -->
<!-- feedback start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'feedback'">
  <div ngbAccordionItem="feedbackPanel_0" [collapsed]="feedbackPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('feedbackPanel_0')">
        <div class="app-card-header" [class.selected]="'feedbackPanel_0' === selectedPanelId">
          <i *ngIf="'feedbackPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'feedbackPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Share My Feedback</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <div>
          We appreciate all your feedback as we are always looking to offer additional and innovative ways to provide the best care humanly possible for every pet and you, our valued customer.
          <br/><br/>
          <a href="javascript:void(0)" (click)="goToContactUsPage()">Click here</a> to email us your suggestions, compliments or concerns.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- feedback end -->
<!-- technical start -->
<div ngbAccordion [closeOthers]="true" *ngIf="selectedFaq.code == 'technical'">
  <div ngbAccordionItem="technicalPanel_0" [collapsed]="technicalPanel_0 !== selectedPanelId">
    <h2 ngbAccordionHeader>
      <button ngbAccordionButton (click)="toggleAccordionPanel('technicalPanel_0')">
        <div class="app-card-header" [class.selected]="'technicalPanel_0' === selectedPanelId">
          <i *ngIf="'technicalPanel_0' !== selectedPanelId" class="fa fa-plus"></i>
          <i *ngIf="'technicalPanel_0' === selectedPanelId" class="fa fa-minus"></i>
          <span class="ms-3 faq-question-text fw-bolder font-weight-bold">Technical Assistance</span>
        </div>
      </button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody class="p-3">
        <ul>
          <li>Access www.HillsToHome.com using one of the preferred web browser, Google Chrome, Microsoft Edge, & Safari.</li>
          <li>Ensure CapsLock is turned off while attempting to log into the site.</li>
          <li>Clear Cookies or Cache to help improve possible technical difficulties.</li>
          <li>Try using an alternative web browser recommended above.</li>
        </ul>
        <br/>
        For additional assistance, questions, or comments, please fill out the <a href="javascript:void(0)" (click)="goToContactUsPage()">contact us</a> form.
      </div>
    </div>
  </div>
</div>
<!-- technical end -->
<!-- What's New start -->
<div *ngIf="selectedFaq.code == 'whats-new' && whatsNewContent">
  <div class="d-flex flex-column py-3 pe-3" *ngFor="let content of whatsNewContent">
    <div *ngIf="content?.title">
      <h5>{{content.title}}</h5>
    </div>
    <div *ngIf="content?.text">
      <div [innerHTML]="content?.text"></div>
    </div>
  </div>
</div>
<!-- What's New end -->
</div>

<ng-template #creditCardHolds let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-header">
    <div class="flex-grow-1 text-center">
      <h4 class="modal-title">Credit Card Holds</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissSuccess()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body text-center">
<!--    <i class="mt-4 far fa-check-circle fa-6x text-white"></i>-->
    <p class="m-2 text-center">
      Please be aware that your bank may place an authorization hold on your account for an amount including estimated tax.
      This hold may last up to 7 days based on the practices of your bank.
      When your Hill's to Home order ships, you will see a charge on your account for the either the same amount as the hold or an adjusted amount due to the final tax calculation.
      In some cases, your bank may not immediately release the authorization hold even after the actual charge is processed, but the hold should be released within a 7 day window.
      If you have any questions about your bank's hold policy, please call the number on the back of your credit card.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary ps-5 pe-5 btn-lg ms-2"
            (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>
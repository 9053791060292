<div *ngIf="isMobile; then mobileLayout; else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout light-grey-bg px-2 custom-font">

    <div class="pt-3 px-1">
      <!-- <h4 class="text-center fw-normal pt-3 pb-0 mb-0">{{totalItems | entryCount}} in your cart</h4> -->
      <div class="shopping-cart-head">
        <h4 class="pt-3 mb-1">Shopping Cart</h4>
        <span>Review and place your order below.</span>
      </div>

      <div class="d-flex cart-listing pb-2 px-2" *ngFor="let entry of entryModel; let i = index">
        <div class="deleteItem">
          <a class="p-3 fa fa-pencil-alt" (click)="openCardInfoModal(i)" *ngIf="entry.product?.stock?.stockLevelStatus === 'inStock'"></a>
          <a class="p-3 fa fa-pencil-alt disabled-color" *ngIf="entry.product?.stock?.stockLevelStatus === 'outOfStock'"></a>
          <span class="ms-1">|</span>
          <a href="javascript: void(0)" (click)="confirmDeleteCartItem(entry, i)" title="Delete" class="fa fa-trash-alt p-3">
          </a>
        </div>
        <div class="d-flex flex-column align-items-center">


          <div class="pet-name d-flex align-items-center flex-column pb-2 w-100 justify-content-center">
            <!-- <img src="assets/images/cat.svg" /> -->
            <h5 class="pt-2">{{entry.pet}}</h5>
          </div>
          <div class="cart-list-img d-flex align-items-center p-1 mt-1">
            <img src="{{entry.product.images | appImg:'lrg'}}" *ngIf="entry.product.images && entry.product?.stock?.stockLevelStatus === 'inStock'" alt="{{entry.product.name}}" />
            <div class="app-cart-img text-center" *ngIf="!entry.product.images">
              <i class="fas fa-image fa-10x"></i>
            </div>
          </div>
          <!--Out of Stock Product Start-->
          <div class="productImageHolder" *ngIf="entry.product?.images && entry.product?.stock?.stockLevelStatus === 'outOfStock'">
            <div id="imgContainer">
              <img src="{{entry.product.images | appImg:'lrg'}}" class="app-cart-img" alt="{{entry.product.name}}"/>
            </div>
              <span class="small out-of-stock px-1 py-1 text-center">
                Temporarily Unavailable
              </span>         
          </div>
          <!--Out of Stock Product End-->
          <div class="small mt-3">
            SKU {{entry.product.code}}
        </div>

        </div>
        <div class="flex-grow-1 cart-item-margin ms-3">
          <div class="cart-product-head mt-1">
            <!-- <span>Hill's Prescription</span> -->
            <h4>{{entry.product.name}}</h4>
            <!-- <span>2.9 OZ, 24 Pack</span> -->
          </div>
          <div class="d-flex mt-3 mb-3 PrevOrderWarning"  *ngIf="entry.duplicateOrdersFlag">This product was previously ordered on {{entry.duplicateOrdersCreationDate  | date: 'MM/dd/yyyy'}}, please adjust your cart accordingly if needed.</div>
               
          <div class="d-flex mt-2">

            <div *ngIf="!entry?.frequency?.isOneTime" class="d-flex align-items-center cart-product-price">
              <span class="text-line-through mb-1">{{
                entry.basePrice.formattedValue
              }}</span>
              <b>${{
                  entry.totalPrice.value / entry.quantity | number: ".2-2"
                }}</b>
            </div>

            <div *ngIf="entry?.frequency?.isOneTime" class="d-flex align-items-center cart-product-price">
              <span>{{entry.basePrice.formattedValue}}</span>
            </div>
          </div>
          <div class="product-qty d-flex align-items-center pt-2 pb-2">
            <div class="qty-field d-flex" *ngIf="entry.product?.stock?.stockLevelStatus === 'inStock'">
              <button type="button" (click)="onQuantityRemoved(entry.quantity, entry)">
                <strong>-</strong>
              </button>
              <!-- class="d-flex align-items-center justify-content-center" -->
              <label [for]="'quantity'+entry.product.code" class="sr-only">Quantity</label>
              <input type="text" [id]="'quantity'+entry.product.code" [value]="entry.quantity" readonly />
              <button type="button" (click)="onQuantityAdded(entry.quantity, entry)">
                +
              </button>
            </div>
            <!--Out of Stock Product Start-->
            <div class="qty-field d-flex" *ngIf="entry.product?.stock?.stockLevelStatus === 'outOfStock'">
              <button type="button" class="disabled-color disabled-border">
                <strong>-</strong>
              </button>
              <label [for]="'quantity'+entry.product.code" class="sr-only">Quantity</label>
              <input type="text" [id]="'quantity'+entry.product.code" [value]="entry.quantity" class="disabled-color" readonly/>
              <button type="button" class="disabled-color disabled-border">
                +
              </button>
            </div>
            <!--Out of Stock Product End-->
            <div class="col-price d-flex flex-column align-items-end flex-grow-1">
              <span>{{ entry.totalPrice.formattedValue }}</span>
            </div>
          </div>

          <div class="cart-info-readonly mb-2">
            <div class="fw-bold">Subscription</div>
            <div>{{entry.frequency.selected | tolabel :
              LabelTypes.Frequency}}</div>
              <div *ngIf = "entry.subscriptionUnit.endDate && entry.subscriptionUnit.deliveryFrequency != DeliveryFrequencies?.OneTime">Subscription ends on {{entry.subscriptionUnit.endDate | date: 'MM/dd/yyyy'}}</div>
            <!-- <div class="d-flex align-items-center flex-column pe-2">
              <div class="form-group mt-1 mb-1 w-100" *ngIf="entry.isEditable">
                <label for="frequency" class="small">Frequency</label>
                <select disabled id="frequency" name="frequency" class="form-control custom-select"
                  [value]="entry.frequency.selected" (change)="onFrequencyChanged($event.target.value, entry)">
                  <option *ngFor="let frequency of entry.frequency.available | toDeliveryFrequency"
                    [value]="frequency.code">
                    {{frequency.name}}
                  </option>
                </select>
              </div>
              <div class="mt-1 mb-1 w-100" *ngIf="!entry.isEditable">
                <div id="frequency_label" name="frequency" class="">{{entry.frequency.selected | tolabel :
                                                    LabelTypes.Frequency}}</div>
              </div>
               <div class="fw-bold ms-2 w-100 d-flex justify-content-center txt-blue"
                *ngIf="entry.duration.isValid && entry.isEditable">
                for
              </div>
              <div class="form-group mt-1 mb-0 w-100" *ngIf="entry.isEditable && entry.duration.isValid">
                <label for="duration" class="small">Length</label>
                <select disabled id="duration" name="duration" class="form-control custom-select"
                  [value]="entry.duration.selected" (change)="onDurationChanged($event.target.value, entry)">
                  <option *ngFor="let duration of entry.duration.available | toDeliveryDuration"
                    [value]="duration.code">
                    {{duration.name}}</option>
                </select>
              </div>
            </div> -->
          </div>
        </div>

        <div class="modal popup-overlay" [ngClass]="i == selectedIndex ? 'visible':'hidden'">
          <cart-subs-form [entry]="entry"  class="frequencyDropdown" (submitted)="onSubscriptionChange($event)"
            (closeCartInfoModal)="closeCartInfoModal()"></cart-subs-form>
        </div>
      </div>
      <div *ngIf="cart?.appliedProductPromotions" class="pt-4 text-center w500 mobile-promo-off">
        <b>{{ cart?.potentialOrderPromotions[0]?.description | promotionDescription:'percentage'}}</b>
        {{ cart?.potentialOrderPromotions[0]?.description | promotionDescription:'desc'}}
      </div>

      <div *ngIf="(cart?.retailDeliveryFee?.value | number : 0) > 0" class="pt-4 text-center w500 mobile-promo-off">
        **If your state has a retail delivery fee, this fee is non refundable.
      </div>

      <div class="pt-3 text-center mobile-subtotal w500" placement="top" [autoClose]="false" [ngbTooltip]="estimatedTaxToolTip" content-type="template" data-container="body">
        Estimated Order Total ({{ cart?.deliveryItemsQuantity }} items)*:
        <b>{{ cart?.totalPrice?.formattedValue }}</b>
      </div>
      <div class="alert alert-danger small mt-3" *ngIf="isOutOFStockCartItems">
        One or more item in your cart is temporarily unavailable. Remove them from the cart, to proceed further.
      </div>
      <div class="mobile-checkout mt-3">
        <button routerLink="/place-order" class="btn btn-lg py-3" [disabled]="isOutOFStockCartItems" (click)="beginCheckout()">
          PROCEED TO CHECKOUT
        </button>
      </div>
      <div class="mt-3">
        <app-autoship [product]="cart" [isCart]="true"></app-autoship>
      </div>
      <p class="text-center py-4 mb-0 w500">
        You can modify or cancel your subscription at any time.
      </p>
    </div>

  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg custom-font">

    <div class="container pt-4 pb-5">
      <div class="shopping-cart-head">
        <h4 class="pt-1">Shopping Cart</h4>
        <span>Review and place your order below.</span>
      </div>
      <div class="row">
        <div class="flex-grow-1 col-md-8 col-sm-12">
          <div class="d-flex cart-listing" *ngFor="let entry of entryModel; let i = index">
            <div class="cart-add-edit d-flex align-items-center justify-content-end flex-grow-1">
              <button class="btn-edit p-0 py-1 me-1" type="button" (click)="openCardInfoModal(i)" [disabled]="entry.product?.stock?.stockLevelStatus === 'outOfStock'" aria-label="Edit item">
                <i class="fa fa-pencil-alt" [ngClass]="{'disabled-color': entry.product?.stock?.stockLevelStatus === 'outOfStock'}"></i>
              </button>
              <span class="mx-2">|</span>
              <button class="btn-del p-0 py-1 ms-1" type="button" (click)="confirmDeleteCartItem(entry,i)" aria-label="Delete item">
                <i class="fa fa-trash-alt"></i>
              </button>
            </div>
            <div class="d-flex justify-content-top flex-column cart-list-img align-items-center px-2">
              <div class="pet-name d-flex align-items-center pt-2 pb-3">
                <!-- <img src="assets/images/cat.svg" /> -->
                <h5>{{entry.pet}}</h5>
              </div>
              <img src="{{ entry.product.images | appImg: 'lrg' }}" *ngIf="entry.product.images && entry.product?.stock?.stockLevelStatus === 'inStock'" alt="{{entry.product.name}}" />
              <div class="app-cart-img text-center" *ngIf="!entry.product.images">
                <i class="fas fa-image fa-10x"></i>
              </div>
              <!--Out of Stock Product Start-->
              <div class="productImageHolder" *ngIf="entry.product?.images && entry.product?.stock?.stockLevelStatus === 'outOfStock'">
                <div id="imgContainer">
                  <img src="{{entry.product.images | appImg:'lrg'}}" class="app-cart-img" alt="{{entry.product.name}}"/>
                </div>
                  <span class="small out-of-stock px-1 py-1 text-center">
                    Temporarily Unavailable
                  </span>         
              </div>
              <!--Out of Stock Product End-->
              <div class="small mt-3">
                  SKU {{entry.product.code}}
              </div>
            </div>

            <div class="flex-grow-1 cart-item-margin ms-3 me-2">
              <div class="cart-product-head mt-1">
                <!-- <span>Hill's Prescription</span> -->
                <h4>{{entry.product.name}}</h4>

                <!-- <span>2.9 OZ, 24 Pack</span> -->
              </div>
              <div class="d-flex mt-3 mb-3 PrevOrderWarning"  *ngIf="entry.duplicateOrdersFlag">This product was previously ordered on {{entry.duplicateOrdersCreationDate  | date: 'MM/dd/yyyy'}}, please adjust your cart accordingly if needed.</div>
              <div class="d-flex mt-3">
                <div class="d-flex align-items-center cart-product-price">
                  <div class="d-flex align-items-center" *ngIf="!entry.frequency.isOneTime">
                    <span class="text-line-through mb-1">{{entry.basePrice.formattedValue}}</span>
                    <b>${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</b>
                  </div>
                  <div *ngIf="entry.frequency.isOneTime">
                    <span class="pl-10">{{entry.basePrice.formattedValue}}</span>
                  </div>
                  <!-- <div>
                      <div class="fw-bold">Price</div>
                      <div *ngIf="!entry.frequency.isOneTime">
                        <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                        <span class="app-title"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                      </div>
                      <div *ngIf="entry.frequency.isOneTime">
                        {{entry.basePrice.formattedValue}}
                      </div>
                    </div> -->
                  <!-- <div class="ms-3">
                      <div class="fw-bold">Subtotal</div>
                      <div>{{entry.totalPrice.formattedValue}}</div>
                    </div> -->
                </div>

              </div>
              <!-- <div class="mt-1 mb-0" *ngIf="!entry.isEditable">
                  <span class="fw-bold">Quantity: </span>
                  <span>{{entry.quantity}}</span>
                </div> -->
              <div class="product-qty d-flex align-items-center pt-3">
                <div class="qty-field d-flex" *ngIf="entry.product?.stock?.stockLevelStatus === 'inStock'">
                  <button type="button" (click)="onQuantityRemoved(entry.quantity, entry)"><strong>-</strong></button>
                  <!-- class="d-flex align-items-center justify-content-center" -->
                  <label [for]="'quantity'+entry.product.code" class="sr-only">Quantity</label>
                  <input type="text" [id]="'quantity'+entry.product.code" [value]="entry.quantity" readonly />
                  <button type="button" (click)="onQuantityAdded(entry.quantity, entry)">+</button>
                </div>
                <!--Out of Stock Product Start-->
                <div class="qty-field d-flex" *ngIf="entry.product?.stock?.stockLevelStatus === 'outOfStock'">
                  <button type="button" class="disabled-color disabled-border"><strong>-</strong></button>
                  <!-- class="d-flex align-items-center justify-content-center" -->
                  <label [for]="'quantity'+entry.product.code" class="sr-only">Quantity</label>
                  <input type="text" [id]="'quantity'+entry.product.code" [value]="entry.quantity" class="disabled-color" readonly/>
                  <button type="button" class="disabled-color disabled-border">+</button>
                </div>
                <!--Out of Stock Product End-->
                <div class="col-price ps-4">
                  <span>{{entry.totalPrice.formattedValue}}</span>
                </div>
              </div>


              <div class="mt-3 cart-info-readonly pb-1">
                <div class="fw-bold">Subscription</div>
                <div>{{entry.frequency.selected | tolabel :
                  LabelTypes.Frequency}}</div>
                  <div *ngIf = "entry.subscriptionUnit.endDate && entry.subscriptionUnit.deliveryFrequency != DeliveryFrequencies?.OneTime">Subscription ends on {{entry.subscriptionUnit.endDate | date: 'MM/dd/yyyy'}}</div>
                <!-- <div class="d-flex align-items-center">
                  <div class="form-group mt-1 mb-1" *ngIf="entry.isEditable">
                    <label for="frequency" class="small">Frequency</label>
                    <select disabled id="frequency" name="frequency" class="form-control custom-select"
                      [value]="entry.frequency.selected" (change)="onFrequencyChanged($event.target.value, entry)">
                      <option *ngFor="let frequency of entry.frequency.available | toDeliveryFrequency"
                        [value]="frequency.code">
                        {{frequency.name}}
                      </option>
                    </select>
                  </div>
                  <div class="mt-1 mb-1" *ngIf="!entry.isEditable">
                    <div id="frequency_label" name="frequency" class="">{{entry.frequency.selected | tolabel :
                                                      LabelTypes.Frequency}}</div>
                  </div>
                  <div class="fw-bold ms-2 txt-blue" *ngIf="entry.duration.isValid && entry.isEditable">
                    for
                  </div>
                  <div class="form-group mt-1 mb-0 ms-2" *ngIf="entry.isEditable && entry.duration.isValid">
                    <label for="duration" class="small">Length</label>
                    <select disabled id="duration" name="duration" class="form-control custom-select"
                      [value]="entry.duration.selected" (change)="onDurationChanged($event.target.value, entry)">
                      <option *ngFor="let duration of entry.duration.available | toDeliveryDuration"
                        [value]="duration.code">
                        {{duration.name}}</option>
                    </select>
                  </div>
                </div> -->
              </div>


              <div *ngIf="!entry?.frequency?.isOneTime" class="d-flex promo-off mt-2">
                <em>
                  <b>{{cart?.potentialOrderPromotions[0]?.description | promotionDescription:'percentage'}}</b>
                  {{cart?.potentialOrderPromotions[0]?.description | promotionDescription:'desc'}}

                </em>
              </div>


            </div>



            <div class="modal popup-overlay" [ngClass]="i == selectedIndex ? 'visible':'hidden'">
              <cart-subs-form [entry]="entry" class="frequencyDropdown" (submitted)="onSubscriptionChange($event)"
                (closeCartInfoModal)="closeCartInfoModal()"></cart-subs-form>
            </div>
          </div>
          <app-place-order-page> </app-place-order-page>
        </div>
        <div class="col-md-4 col-sm-12 pt-3" *ngIf="profileAddresses && profilePayments">
          <app-order-summary [isMobile]="isMobile" [order]="cart" [profileAddresses]="profileAddresses"
            [profilePayments]="profilePayments">
          </app-order-summary>
          <div class="mt-3">
            <app-autoship [product]="cart" [isCart]="true"></app-autoship>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- </div> -->
</ng-template>

<ng-template #successModal let-closeSuccess="close" let-dismissSuccess="dismiss">
  <div class="modal-body text-center">
    <i class="mt-4 far fa-check-circle fa-6x txt-success"></i>
    <p class="m-4 h4 text-center">
      Your cart is empty
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary  ps-5 pe-5 btn-lg ms-2" (click)="closeSuccess()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #confirmDeleteCartItemModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">

    <button (click)="dismissConfirm()" aria-label="Close" class="close" type="button">
      <i class="fa fa-times"></i>
    </button>
    <p class="m-4 h4 text-center">
      Are you sure you want to delete this cart item?
    </p>
  </div>
  <div class="modal-footer">

    <button (click)="closeConfirm()" class="btn btn-primary ps-5 pe-5 btn-lg ms-2" type="button">
      I'm Sure
    </button>
    <button (click)="dismissConfirm()" class="btn btn-secondary ps-5 pe-5 btn-lg" type="button">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #estimatedTaxToolTip>
  <div class="text-start"><span class="font-weight-light"> Order total may vary based on final tax calculation</span></div>
</ng-template>
<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout">
    <h5 class="app-title text-center m-4">
      My Orders
    </h5>
    <form class="d-flex justify-content-around p-3" [formGroup]="searchForm" (ngSubmit)="search()">
      <div class="form-group me-2"
           [class.is-invalid]="searchForm.controls['startDate'].dirty && searchForm.controls['startDate'].invalid">
        <label for="startDate" class="small">From</label>
        <div class="input-group">
          <input id="startDate"
                 type="date"
                 class="form-control pe-0"
                 formControlName="startDate"
                 max="{{todayDate}}">
          <div class="border-none input-group-append d-flex align-items-center m-2">
            <i class="fas app-icon"></i>
          </div>
        </div>
      </div>
      <div class="form-group ms-2"
           [class.is-invalid]="searchForm.controls['endDate'].dirty && searchForm.controls['endDate'].invalid">
        <label for="endDate" class="small">To</label>
        <div class="input-group">
          <input id="endDate"
                 type="date"
                 class="form-control pe-0"
                 formControlName="endDate"
                 max="{{todayDate}}">
          <div class="input-group-append d-flex align-items-center m-2">
            <i class="fas app-icon"></i>
          </div>
        </div>
      </div>
    </form>
    <div class="p-3">
      <table class="table">
        <thead>
        <tr>
          <th>Order Number</th>
          <th>Date Placed</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr class="border-sty" *ngFor="let order of orders" (click)="select(order)">
          <td class="text-underline">{{order.code}}</td>
          <td>{{order.placed | date: 'MM/dd/yyyy'}}</td>
          <td class="app-status">{{order.status | appOrderStatus}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout">
    <div class="container pt-5 pb-5 orders-list">
      <div class="d-flex align-items-center">
        <h4 class="fw-normal m-0">Orders</h4>
        <div class="d-flex flex-grow-1 justify-content-end">
          <form class="d-flex" [formGroup]="searchForm" (ngSubmit)="search()">
            <div class="form-group" [class.is-invalid]="searchForm.controls['startDate'].dirty && searchForm.controls['startDate'].invalid">
              <label for="startDate" class="small">From</label>
              <div class="input-group">
                <input id="startDate" type="date" class="form-control pe-0" formControlName="startDate" max="{{todayDate}}">
                <div class="input-group-append d-flex align-items-center m-2">
                  <i class="fas app-icon"></i>
                </div>
              </div>
            </div>
            <div class="form-group ms-2" [class.is-invalid]="searchForm.controls['endDate'].dirty && searchForm.controls['endDate'].invalid">
              <label for="endDate" class="small">To</label>
              <div class="input-group">
                <input id="endDate" type="date" class="form-control pe-0" formControlName="endDate" max="{{todayDate}}">
                <div class="input-group-append d-flex align-items-center m-2">
                  <i class="fas app-icon"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex p-4">
        <b class="text-uppercase w-20">Order Number</b>
        <b class="text-uppercase w-15">Order Date</b>
        <b class="text-uppercase w-30">Shipping Address</b>
        <b class="text-uppercase w-10">Status</b>
        <b class="pad-1 text-uppercase w-15">Estimated Delivery</b>
        <b class="text-uppercase w-10 text-right pe-3">Total</b>
      </div>
      <div class="card">
        <div class="card-body ordersAcc">
          <div ngbAccordion [closeOthers]="true">
            <div *ngFor="let order of orders; let i = index" ngbAccordionItem [collapsed]="!isPanelOpen(i)" >
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton (click)="togglePanel(i)">
                  <div class="w-100 d-flex p-4 small app-card-header" [class.selected]="isPanelOpen(i)">
                    <div class="w-20 d-flex">
                      <i class="fas fa-angle-right" [class.fa-rotate-90]="isPanelOpen(i)"></i>
                      <span class="on-styl margin-on text-underline ms-3">{{order.code}}</span>
                    </div>
                    <div class="margin-od w-15 accordion-item-text">
                      {{order.placed | date: 'MM/dd/yyyy'}}
                    </div>
                    <div class="margin-sadd w-30 order-address-details accordion-item-text">
                      {{order.deliveryAddress.line1}} {{order.deliveryAddress.line2}} {{order.deliveryAddress.town}},
                      {{order.deliveryAddress.region.isocode | appRegion}} {{order.deliveryAddress.postalCode}}
                    </div>
                    <div class="margin-status w-10 accordion-item-text">
                      {{order.status | appOrderStatus}}
                    </div>
                    <div class="w-15 accordion-item-text">
                      <span class="margin-estD flex-grow-1" *ngIf="order.status.toUpperCase() !== 'CANCELLED'">
                        {{order?.v2cOrderEstimateDate | date: 'MM/dd/yyyy'}}
                      </span>
                      <span class="flex-grow-1" *ngIf="order.status.toUpperCase() === 'CANCELLED'">-</span>
                    </div>
                    <div class="w-10 text-right pe-3 accordion-item-text">
                      <span *ngIf="order?.sapOrderTotalValue === undefined" [ngbTooltip]="estimatedTaxToolTip" placement="bottom" [autoClose]="false" data-container="body">
                        {{order?.total?.formattedValue + "*"}}
                      </span>
                      <span class="margin-total" *ngIf="order?.sapOrderTotalValue !== undefined">
                        {{order?.sapOrderTotalValue | currency : fractionsize}}
                      </span>
                    </div>
                  </div>
                </button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <div class="p-4 small">
                    <div class="d-flex">
                      <div class="fw-bold text-uppercase w-10"></div>
                      <div class="fw-bold text-uppercase w-40">Item</div>
                      <div class="fw-bold text-uppercase w-10">Pet</div>
                      <div class="fw-bold text-uppercase w-10 text-right">Item price</div>
                      <div class="fw-bold text-uppercase w-20 text-center">Quantity</div>
                      <div class="fw-bold text-uppercase w-10 text-right pe-3">Price</div>
                    </div>
                    <hr class="mt-3"/>
                    <div *ngFor="let entry of orderDetail?.entries; let j=index;">
                      <ng-container *ngIf="entry?.product">
                        <div class="d-flex pt-3">
                          <div class="w-10">
                            <div class="d-flex flex-column align-items-center">
                              <img *ngIf="entry?.product?.images" src="{{entry?.product?.images | appImg}}" class="app-cart-img" alt="{{entry?.product?.name}}"/>
                              <div *ngIf="!entry?.product?.images" class="app-cart-img text-center">
                                <i class="fas fa-image fa-4x"></i>
                              </div>
                              <div class="text-black-50 small mt-3">
                                SKU {{entry?.product?.code}}
                              </div>
                            </div>
                          </div>
                          <div class="w-40">
                            {{entry?.product?.name}}
                            <div class="text-danger">
                              <span class="fw-bold" *ngIf="entry?.status == 'RETURN_INITIATED'">Return Initiated</span>
                              <span class="fw-bold" *ngIf="entry?.status == 'RETURN_DENIED'">Return Denied</span>
                              <span class="fw-bold" *ngIf="entry?.status == 'RETURN_COMPLETED'">Return Completed</span>
                            </div>
                          </div>
                          <div class="w-10">
                            {{entry?.pet}}
                          </div>
                          <div class="w-10 text-right" *ngIf="entry?.subscriptionUnit && entry?.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                            <span class="text-line-through">{{entry?.basePrice?.formattedValue}}</span>
                            <span class="app-title"> ${{(entry?.totalPrice?.value / entry?.quantity | number: '.2-2')}}</span>
                          </div>
                          <div class="w-10 text-right" *ngIf="!entry?.subscriptionUnit || entry?.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                            {{entry?.basePrice?.formattedValue}}
                          </div>
                          <div class="w-20 text-center">
                            {{entry?.quantity}}
                          </div>
                          <div class="w-10 text-right pe-3">{{entry?.totalPrice?.formattedValue}}</div>
                        </div>
                      </ng-container>
                    </div>
                    <hr class="mt-3"/>
                    <div class="d-flex pt-3">
                      <div class="w-40">
                        <div *ngIf="orderDetail && (orderDetail.status === OrderStatuses.Shipped || orderDetail.status === OrderStatuses.PartialShipped)" class="p-3">
                          <button class="btn btn-primary btn-sm mt-3 me-15" (click)="downloadInvoice(orderDetail)">Invoice</button>
                          <button *ngIf="order.status !== OrderStatuses.PartialShipped" class="btn btn-primary btn-sm mt-3 ms-3 hiddenx" (click)="returnOrder(orderDetail)">Return</button>
                        </div>
                        <div *ngIf="orderDetail && orderDetail.status === OrderStatuses.Pending" class="p-3">
                          <div class="font-weight-light">Canceling orders can only be done when order is in <b>Pending Status</b></div>
                          <button class="btn btn-primary btn-sm mt-3" (click)="confirm(orderDetail)">Cancel Order</button>
                        </div>
                        <div *ngIf="orderDetail && orderDetail.status === OrderStatuses.InProgress" class="p-3">
                          <div class="font-weight-light">*Please note that "In Progress" orders cannot be cancelled online. If you need to cancel an "In Progress" order, please email hillstohome&#64;hillspet.com or call us at 1-800-235-6877</div>
                        </div>
                        <div *ngIf="orderDetail && orderDetail.status === OrderStatuses.PartialReturn" class="p-3">
                          <div class="font-weight-light">*If you would like to return another item from this order please contact our customer support team at 1-800-235-6877.</div>
                        </div>
                        <div *ngIf="orderDetail && orderDetail.consignments && orderDetail.consignments.length && orderDetail.consignments[0].trackingID"
                        class="ps-6 pe-6 pt-3 pb-3">
                        <div class="order-shipped">
                          Your order is <b>Shipped</b>
                        </div>
                        <div>
                          {{orderDetail?.consignments[0].carrier}} # {{orderDetail?.consignments[0].trackingID}}
                        </div>
                        <a class="btn btn-primary btn-sm mt-3"
                            [href]="orderDetail?.consignments[0].carrierTrackingURL"
                            target="_blank"
                            (click)="$event.stopPropagation()">
                          Track Package
                        </a>
                      </div>
                      </div>
                      <div class="w-35"></div>
                      <div class="w-25">
                        <div class="d-flex flex-column">
                          <div class="order-total-value" *ngIf="orderDetail?.sapOrderTotalValue === undefined">
                            <div class="d-flex">
                              <div class="w-50 ps-3">Subtotal</div>
                              <div class="w-50 text-right pe-3">${{(orderDetail?.subTotal?.value + orderDetail?.totalDiscounts?.value) | number: '.2-2'}}</div>
                            </div>
                            <div class="d-flex mt-1">
                              <div class="w-50 ps-3">Subscription Discount</div>
                              <div class="w-50 text-right pe-3">{{orderDetail?.productDiscounts?.value ? '-' : ''}}{{orderDetail?.productDiscounts?.formattedValue}}</div>
                            </div>
                            <div class="d-flex mt-1" *ngIf="orderDetail?.orderDiscounts?.value !== undefined">
                              <div class="w-50 ps-3">Promotions Discount</div>
                              <div class="w-50 text-right pe-3">{{orderDetail?.orderDiscounts?.value ? '-' : ''}}{{orderDetail?.orderDiscounts?.formattedValue}}</div>
                            </div>
                            <div class="d-flex mt-1">
                              <div class="w-50 ps-3">Shipping</div>
                              <div class="w-50 text-right pe-3">{{orderDetail?.deliveryCost?.formattedValue}}</div>
                            </div>
                            <div class="d-flex mt-1" *ngIf="(orderDetail?.totalSurcharge?.value | number : 0) > 0">
                              <div class="w-50 ps-3">Alaska/Hawaii Surcharge</div>
                              <div class="w-50 text-right pe-3">{{orderDetail?.totalSurcharge?.value | currency : fractionsize}}</div>
                            </div>
                            <div class="d-flex mt-1">
                              <div class="w-50 ps-3">Estimated Tax</div>
                              <div class="w-50 text-right pe-3">{{orderDetail?.totalTax?.formattedValue}}</div>
                            </div>
                            <div class="d-flex mt-1" *ngIf="(orderDetail?.retailDeliveryFee?.value | number : 0) > 0">
                              <div class="w-50 ps-3">State Delivery Fee**</div>
                              <div class="w-50 text-right pe-3">{{orderDetail?.retailDeliveryFee?.value | currency : fractionsize}}</div>
                            </div>
                          </div>
                          <div class="d-flex mt-4 fw-bold">
                            <div class="w-50 ps-3" id="taxtooltip" *ngIf="orderDetail?.sapOrderTotalValue === undefined" [ngbTooltip]="estimatedTaxToolTip" placement="top" [autoClose]="false" data-container="body">Estimated Order Total*</div>
                            <div class="w-50 ps-3" *ngIf="orderDetail?.sapOrderTotalValue !== undefined">Order Total</div>
                            <div class="w-50 text-right pe-3">{{ orderDetail?.sapOrderTotalValue === undefined ? orderDetail?.totalPrice?.formattedValue : orderDetail?.sapOrderTotalValue | currency : fractionsize }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <p class="m-4 h4 text-center">
      Are you sure you want to cancel this order?
    </p>
  </div>
  <!-- Cancel confirmation modal button alignment -   -->
  <div class="modal-footer">
    <button type="button"
            class="btn btn-secondary ps-3 pe-3 btn-lg"
            (click)="dismissConfirm()">
      Don't Cancel
    </button>
    <button type="button"
            class="btn btn-primary ps-3 pe-3 btn-lg ms-2"
            (click)="closeConfirm()">
      Cancel Order
    </button>
  </div>
  <!-- END -->
</ng-template>
<ng-template #returnModal let-closeConfirm="close" let-dismissConfirm="dismiss">
  <div class="modal-body">
    <h3 class="m-4 h3">
      Are you sure you want to return this order?
    </h3>
  </div>
  <div class="modal-footer" style="justify-content: center !important;">
    <button type="button"
            class="btn btn-secondary ps-5 pe-5"
            (click)="dismissConfirm()">
      No, Cancel
    </button>
    <button type="button"
            class="btn btn-primary ps-5 pe-5 ms-2"
            (click)="closeConfirm()">
      Yes, Return Order
    </button>
  </div>
</ng-template>

<ng-template #estimatedTaxToolTip>
  <div class="text-start"><span class="font-weight-light"> Order total may vary based on final tax calculation</span></div>
</ng-template>



import { Component, Input, OnInit } from '@angular/core';
import {
  Cart,
  OrderDetail,
  ProfileAddresses
} from '../../../api';
import * as fromOrders from '../../reducers';
import * as OrdersActions from '../../actions/orders.actions';
import { select, Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ProfilePayments } from 'src/app/profile/models/ProfilePayments';
import { TriggerSprigEvent } from 'src/app/auth/actions/auth.actions';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  promoForm: UntypedFormGroup;

  @Input() isMobile: boolean | false;

  @Input() order: Cart | OrderDetail | null;

  @Input() isOrderCompletionPage: boolean | false;

  // Promotions discount info added -  
  @Input() isMyOrderPage: boolean | false;
  // END

  @Input() cartForm: UntypedFormGroup | null;

  @Input() profileAddresses: ProfileAddresses | null;

  @Input() profilePayments: ProfilePayments | null;

  constructor(private store: Store<fromOrders.State>, private fb: UntypedFormBuilder) {
    this.promoForm = this.fb.group({
      promoCode: ["", Validators.required]
    });
  }

  ngOnInit() {
    // console.log("order summary " + JSON.stringify(this.order))
  }
  applyPromo() {
    console.log("promoCode " + this.promoForm.controls["promoCode"].value);
    localStorage.setItem("promoCode", this.promoForm.controls["promoCode"].value);
    this.store.dispatch(new OrdersActions.updateAppliedPromo());
  }
  removePromo() {
    this.promoForm.controls["promoCode"].setValue("");
    this.store.dispatch(new OrdersActions.removeAppliedPromo());
  }

  placeOrder() {
    this.store.dispatch(new OrdersActions.PlaceOrder());
    const isOttoCustomer = sessionStorage.getItem('ottoCustomer') === 'true';
    const ottoStatus = sessionStorage.getItem('ottoStatus') !== 'pending';
    if (isOttoCustomer && ottoStatus) {
      this.triggerEvent();
    }
  }

  triggerEvent(): void {
    this.store.dispatch(new TriggerSprigEvent({ eventIdentifier: "Otto Pilot User" }));
   }

}

<div *ngIf="isMobile; then mobileLayout else fullLayout"></div>

<ng-template #mobileLayout>
  <div class="app-mobile-layout custom-font pb-4 px-2">
    <div class="container px-1 pb-1">
      <div class="pt-4">
        <h5 class="text-center">
          Your order has been placed
        </h5>
        <h5 class="text-center w500 mt-2 ms-3 me-3">
          Order <span class="fw-bold sharp-blue">#{{placedOrder?.code}}</span>
        </h5>
      </div>
      <div class="pt-3">
        <div class="shopping-cart-head">
          <h4>
            <!-- <i class="fas fa-tag app-icon"></i> -->
            Products Ordered
          </h4>
        </div>
        <div class="cart-listing w500 pt-3" *ngFor="let entry of placedOrder?.entries; let i=index"
          [class.border-bottom]="i!==(placedOrder?.entries.length - 1)">
          <div class="d-flex">
            <div class="d-flex flex-column cart-list-img align-items-center justify-content-top me-3">
              <div class="pet-name d-flex align-items-center pt-2 pb-3">
                <h5 class="pt-1">{{entry.pet}}</h5>
              </div>
              <img src="{{entry.product.images | appImg}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
              <div class="app-cart-img text-center" *ngIf="!entry.product.images">
                <i class="fas fa-image fa-4x"></i>
              </div>
              <div class="w500 mt-2 small">
                SKU {{entry.product.code}}
              </div>
            </div>
            <div class="cart-product-head mt-1">
              <!-- <span>Hill's Prescription</!-->
              <h4>{{entry.product.name}}</h4>
              <!-- <span>2.9 OZ, 24 Pack</span> -->
            </div>
          </div>
          <div class="flex-grow-1 d-flex flex-column ms-2 confirm-order">
            <!-- <div class="mt-2">
              <span class="fw-bold">Pet: </span>
              <span>{{entry.pet}}</span>
            </div> -->
            <div class="mt-2">
              <span class="fw-bold">Subscription: </span>
              <span>{{entry.subscriptionUnit?.deliveryFrequency | tolabel : LabelTypes.Frequency}}</span>
              <div *ngIf="entry.subscriptionUnit.endDate && entry.subscriptionUnit.deliveryFrequency != DeliveryFrequencies?.OneTime">Subscription ends on {{entry.subscriptionUnit.endDate | date: 'MM/dd/yyyy'}}</div>
            </div>
            <div class="mt-2">
              <span class="fw-bold">Est. Delivery: </span>
              <span>{{placedOrder?.estimatedDeliveryDate}}</span>
            </div>
            <div class="mt-2">
              <span class="fw-bold">Qty: </span>
              <span>{{entry.quantity}}</span>
            </div>
            <div class="mt-2"
              *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
              <span class="fw-bold">Price: </span>
              <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
              <span class="product-price w600"> ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
            </div>
            <div class="mt-2"
              *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
              <span class="fw-bold">Price: </span>
              <span>{{entry.basePrice.formattedValue}}</span>
            </div>
            <div class="mt-2">
              <span class="fw-bold">Subtotal: </span>
              <span class="sub-total w600">{{entry.totalPrice.formattedValue}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3 mt-3">
        <div class="shopping-cart-head">
          <h4>
            <!-- <i class="fas fa-truck app-icon"></i> -->
            Shipping Address
          </h4>
        </div>
        <div *ngIf="placedOrder?.deliveryAddress"
          class="d-flex white-card-bordered w500 py-3 px-4 align-items-center mt-3">
          <div class="d-flex flex-column flex-grow-1">
            <span>
              {{placedOrder.user?.name}}
            </span>
            <span>
              {{placedOrder.deliveryAddress.line1}}
              {{placedOrder.deliveryAddress.line2}}
            </span>
            <span>
              {{placedOrder.deliveryAddress.town}},
              {{placedOrder.deliveryAddress.region.name}}
              {{placedOrder.deliveryAddress.postalCode}}
            </span>
          </div>
          <div class="me-3">
            {{placedOrder.deliveryAddress.defaultAddress ? '(Primary)' : ''}}
          </div>
        </div>
      </div>
      <div class="pt-3 mt-3">
        <div class="shopping-cart-head">
          <h4>
            <!-- <i class="fas fa-dollar-sign app-icon"></i> -->
            Payment
          </h4>
        </div>
        <div *ngIf="placedOrder?.paymentInfo" class="d-flex white-card-bordered w500 py-3 px-4 align-items-center mt-3">
          <div>
            <!-- <i [class]="placedOrder.paymentInfo.cardType.code | cardTypeToImage: 'app-icon app-credit-card'"></i> -->
            <img width="38" [src]="placedOrder.paymentInfo.cardType.code | cardTypeToImage" alt="{{placedOrder.paymentInfo.cardType.code}}" />
          </div>
          <div class="ps-2 d-flex flex-column flex-grow-1">
            <div>
              <span class="text-capitalize">{{placedOrder.paymentInfo.cardType.code}}</span>
              {{ ' Ending in - ' + placedOrder.paymentInfo.cardNumber}}
            </div>
          </div>
          <div class="me-3">
            {{placedOrder.paymentInfo.defaultPaymentInfo ? '(Primary)' : ''}}
          </div>
        </div>
      </div>

      <!-- Order Summary Template-->
      <div class="shopping-cart-head mt-3 pt-3">
        <h4 _ngcontent-c21="">Order Summary</h4>
      </div>
      <app-order-summary class="mt-2" [isMobile]="isMobile" [order]="placedOrder" [isOrderCompletionPage]="true">
      </app-order-summary>


      <div class="mt-1">
        <a [routerLink]="['/orders', placedOrder.code]" class="btn btn-blue btn-lg py-3 w-100">
          Manage Order
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullLayout>
  <div class="app-full-layout light-grey-bg custom-font">
    <div class="container pt-5 pb-5 px-4">
      <div class="bg-white p-4">
        <h2 class="fw-bold text-center m-0">
          Your order <span class="app-cart-id fw-bold sharp-blue">#{{placedOrder?.code}}</span> has been
          received
        </h2>
      </div>

      <div class="d-flex mt-3 row">
        <div class="flex-grow-1 col-md-8 col-sm-12 pt-4">
          <!-- <div class="p-3"> -->
          <div class="shopping-cart-head">
            <h4 class="w800">
              <!-- <i class="fas fa-tag app-icon"></i> -->
              Products Ordered
            </h4>
          </div>
          <div class="cart-listing pt-3" *ngFor="let entry of placedOrder?.entries; let i=index">
            <div class="d-flex">
              <div class="d-flex flex-column cart-list-img align-items-center justify-content-top px-2">
                <div class="pet-name d-flex align-items-center pt-2 pb-3">
                  <h5 class="pt-1">{{entry.pet}}</h5>
                </div>
                <img src="{{entry.product.images | appImg:'lrg'}}" class="app-cart-img" *ngIf="entry.product.images" alt="{{entry.product.name}}" />
                <div class="app-cart-img text-center" *ngIf="!entry.product.images">
                  <i class="fas fa-image fa-4x"></i>
                </div>
                <div class="small mt-3 w500">
                  SKU {{entry.product.code}}
                </div>
              </div>
              <div class="flex-grow-1 d-flex flex-column ms-3">
                <div class="cart-product-head mt-1">
                  <!-- <span>Hill's Prescription</!-->
                  <h4>{{entry.product.name}}</h4>
                  <!-- <span>2.9 OZ, 24 Pack</span> -->
                </div>

                <div class="d-flex justify-content-between mt-3 w500 confirm-order">
                  <!-- <div>
                    <div class="fw-bold">Pet</div>
                    <div>{{entry.pet}}Cat</div>
                  </div> -->
                  <div>
                    <div class="fw-bold">Qty</div>
                    <div>{{entry.quantity}}</div>
                  </div>
                  <div>
                    <div class="fw-bold">Price</div>
                    <div
                      *ngIf="entry.subscriptionUnit && entry.subscriptionUnit.deliveryFrequency !== DeliveryFrequencies.OneTime">
                      <span class="text-line-through">{{entry.basePrice.formattedValue}}</span>
                      <span class="product-price w600">
                        ${{(entry.totalPrice.value / entry.quantity | number: '.2-2')}}</span>
                    </div>
                    <div
                      *ngIf="!entry.subscriptionUnit || entry.subscriptionUnit.deliveryFrequency === DeliveryFrequencies.OneTime">
                      {{entry.basePrice.formattedValue}}
                    </div>
                  </div>
                  <div>
                    <div class="fw-bold">Subtotal</div>
                    <div class="sub-total w600">{{entry.totalPrice.formattedValue}}</div>
                  </div>
                </div>
                <div class="mt-3 w500 cart-info-readonly">
                  <div class="fw-bold">Subscription</div>
                  <div>{{entry.subscriptionUnit?.deliveryFrequency | tolabel : LabelTypes.Frequency}}</div>
                  <div *ngIf="entry.subscriptionUnit.endDate && entry.subscriptionUnit.deliveryFrequency != DeliveryFrequencies?.OneTime">Subscription ends on {{entry.subscriptionUnit.endDate | date: 'MM/dd/yyyy'}}</div>
                </div>
                <div class="mt-3 w500 cart-info-readonly">
                  <div class="fw-bold">Estimated Delivery</div>
                  <div>{{placedOrder?.estimatedDeliveryDate}}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
          <div class="pt-3 mt-3">
            <div class="shopping-cart-head">
              <h4>
                <!-- <i class="fas fa-truck app-icon"></i> -->
                Shipping Address
              </h4>
            </div>
            <div *ngIf="placedOrder?.deliveryAddress"
              class="d-flex white-card-bordered w500 py-3 px-4 align-items-center mt-3">
              <div class="d-flex flex-column flex-grow-1">
                <span>
                  {{placedOrder.user?.name}}
                </span>
                <span>
                  {{placedOrder.deliveryAddress.line1}}
                  {{placedOrder.deliveryAddress.line2}}
                </span>
                <span>
                  {{placedOrder.deliveryAddress.town}},
                  {{placedOrder.deliveryAddress.region.name}}
                  {{placedOrder.deliveryAddress.postalCode}}
                </span>
              </div>
              <div class="me-3">
                {{placedOrder.deliveryAddress.defaultAddress ? '(Primary)' : ''}}
              </div>
            </div>
          </div>
          <div class="pt-3 mt-3">
            <div class="shopping-cart-head">
              <h4>
                <!-- <i class="fas fa-dollar-sign app-icon"></i> -->
                Payment
              </h4>
            </div>
            <div *ngIf="placedOrder?.paymentInfo"
              class="d-flex white-card-bordered w500 py-3 px-4 align-items-center mt-3">
              <div class="d-flex d-flex align-items-center flex-grow-1">
                <div>
                  <!-- <i [class]="placedOrder.paymentInfo.cardType.code | cardTypeToImage: 'app-icon app-credit-card fa-2x'"></i> -->
                  <img width="38" [src]="placedOrder.paymentInfo.cardType.code | cardTypeToImage" alt="{{placedOrder.paymentInfo.cardType.code}}" />
                </div>
                <div class="ps-2 d-flex flex-column flex-grow-1">
                  <div>
                    <span class="text-capitalize">{{placedOrder.paymentInfo.cardType.code}}</span>
                    {{placedOrder.paymentInfo.cardNumber}}
                  </div>
                  <!-- <div>
                    Expires
                    {{placedOrder.paymentInfo.expiryMonth}} /
                    {{placedOrder.paymentInfo.expiryYear}}
                  </div> -->
                </div>
              </div>
              <div class="me-3">
                {{placedOrder.paymentInfo.defaultPaymentInfo ? '(Primary)' : ''}}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-12">
          <app-order-summary [isMobile]="isMobile" [order]="placedOrder" [isOrderCompletionPage]="true">
          </app-order-summary>
          <div class="white-card-bordered pt-2 pb-2 mt-4">
            <div class="text-center w500 pt-2 pb-0 px-4">
              If you need to make any changes or cancel, click below to manage order.
            </div>
            <div class="py-3 px-4">
              <a [routerLink]="['/orders']" [queryParams]="{id: placedOrder.code}" class="btn btn-blue w-100">
                View and Manage Order
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
